import { useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

import { List } from '../components/common/list/List';
import {CallIcon, ListItemAction, ListItemProps} from '../components/common/list/ListItem';
import { Spinner } from '../components/common/Spinner';
import {AppRoute, DEFAULT_ERROR_MESSAGE, EmptyPlaceholder, SearchPlaceholder} from '../constants';
import {
  clearNotification,
  fetchAllAsync as fetchAllInvitationsAsync,
  selectAllCallInvitations,
  selectError,
  selectFetchAllStatus as selectFetchAllInvitationsStatus
} from '../models/call-invitations/callInvitationsSlice';
import { CallInvitation } from '../models/call-invitations/types';
import { AsyncThunkStatus } from '../models/constants';
import { useAppDispatch, useAppSelector } from '../models/hooks';
import type { DecoratedUser } from '../models/users/types';
import {
  fetchAllAsync as fetchAllUsersAsync,
  selectAllUsers,
  selectFetchAllStatus as selectFetchAllUsersStatus,
  selectWhoami
} from '../models/users/usersSlice';

function textForParticipants(recipients: string[], participants?: string[]) {

  const totalPeers = recipients.length + (participants?.length ?? 0);
  return totalPeers > 1 ? `(+ ${totalPeers - 1} others)` : '(1 to 1)';
}

function textForTimestamp(timestamp: number) {
  return new Date(timestamp).toLocaleString();
}

function buildViewModel(invitation: CallInvitation, usersMap: Map<string, DecoratedUser>): ListItemProps {

  const sender = usersMap.get(invitation.sender);
  if (!sender) {
    throw new Error(`Could not identify sender: ${invitation.sender}`);
  }

  return {
    id: invitation.roomId,
    avatar: sender.avatar,
    title: sender.name,
    subtitle: textForParticipants(invitation.recipients, invitation.participants),
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: 'Received',
    secondColumnSubtitle: textForTimestamp(invitation.timestamp),
    action: ListItemAction.JOIN,
    roomId: invitation.roomId,
  };
}

function buildViewModels(allInvitations: CallInvitation[], allUsers: DecoratedUser[]): ListItemProps[] {

  const usersMap = new Map<string, DecoratedUser>();
  allUsers.forEach((user) => usersMap.set(user.id, user));

  return allInvitations.map((invitation) => buildViewModel(invitation, usersMap));
}

function CallInvitationsPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const allUsers = useAppSelector(selectAllUsers);
  const allInvitations = useAppSelector(selectAllCallInvitations);

  const fetchAllInvitationsStatus = useAppSelector(selectFetchAllInvitationsStatus);
  const fetchAllUsersStatus = useAppSelector(selectFetchAllUsersStatus);

  const whoami = useAppSelector(selectWhoami);
  const error = useAppSelector(selectError);

  useEffect(() => {

    dispatch(clearNotification());

    (async () => {

      if (!whoami) {
        return;
      }

      if (fetchAllInvitationsStatus === AsyncThunkStatus.IDLE || fetchAllUsersStatus === AsyncThunkStatus.IDLE) {

        const accessToken = await getAccessTokenSilently();
        await Promise.all([
          dispatch(fetchAllUsersAsync(accessToken)).unwrap(),
          dispatch(fetchAllInvitationsAsync()).unwrap()
        ]);
      }

    })().catch(console.error);

  }, [dispatch, fetchAllInvitationsStatus, fetchAllUsersStatus, getAccessTokenSilently, whoami]);

  const hasPendingFetch = !![AsyncThunkStatus.IDLE, AsyncThunkStatus.STARTED]
    .filter((status) => fetchAllInvitationsStatus === status || fetchAllUsersStatus === status)
    .length;

  const hasEmptyModelList = !allInvitations.length || !allUsers.length;

  if (hasPendingFetch && hasEmptyModelList) {
    return <Spinner />;
  }
  
  if (fetchAllInvitationsStatus === AsyncThunkStatus.REJECTED || fetchAllUsersStatus === AsyncThunkStatus.REJECTED) {
    return <p>{ error?.message || DEFAULT_ERROR_MESSAGE }</p>;
  }

  return (
    <List
      datasource={{ flatItems: buildViewModels(allInvitations, allUsers) }}
      searchable={true}
      stickySearchBar={true}
      searchPlaceholder={SearchPlaceholder.CALL_INVITATIONS}
      emptyPlaceholder={EmptyPlaceholder.CALL_INVITATIONS}
      onItemAction={(item) => {
        if (item.roomId) {
          navigate(AppRoute.CALL + "/" + item.roomId);
        }
      }}
    />
  );
}

export { CallInvitationsPage };

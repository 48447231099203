import { ReactElement, useRef, useState } from 'react';
import {
  Box, Dropdown, Flex, Text
} from '@100mslive/react-ui';

import { useDropdownSelection } from '../../../hooks/useDropdownSelection';
import { DialogDropdownTrigger } from '../../common/DropdownTrigger';

type DeviceSelectorProps = {
  title: string;
  devices: MediaDeviceInfo[];
  selection?: string;
  onChange: (selection: string) => void;
  icon: ReactElement;
  children?: ReactElement[];
};

const DeviceSelector = ({
  title,
  devices,
  selection,
  onChange,
  icon,
  children,
}: DeviceSelectorProps) => {

  const [open, setOpen] = useState(false);
  const selectionBg = useDropdownSelection();
  const ref = useRef<Element>(null);

  return (
    <Box css={{ mb: '$10' }}>

      <Text css={{ mb: '$4' }}>{title}</Text>

      <Flex
        align='center'
        css={{
          gap: '$4',
          '@md': {
            flexDirection: children ? 'column' : 'row',
            alignItems: children ? 'start' : 'center',
          },
        }}
      >
        <Box
          css={{
            position: 'relative',
            flex: '1 1 0',
            w: '100%',
            minWidth: 0,
            '@md': {
              mb: children ? '$8' : 0,
            },
          }}
        >
          <Dropdown.Root open={open} onOpenChange={setOpen}>

            <DialogDropdownTrigger
              ref={ref}
              css={{
                ...(children
                  ? {
                      flex: '1 1 0',
                      minWidth: 0,
                    }
                  : {}),
              }}
              icon={icon}
              title={
                devices.find(({ deviceId }) => deviceId === selection)?.label || 'Select device from list'
              }
              open={open}
            />

            <Dropdown.Portal>
              <Dropdown.Content
                align='start'
                sideOffset={8}
                css={{ w: ref.current?.clientWidth, zIndex: 1000 }}
              >
                {devices.map(device => {
                  return (
                    <Dropdown.Item
                      key={device.label}
                      onSelect={() => onChange(device.deviceId)}
                      css={{
                        px: '$9',
                        bg:
                          device.deviceId === selection
                            ? selectionBg
                            : undefined,
                      }}
                    >
                      {device.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Content>
            </Dropdown.Portal>

          </Dropdown.Root>
        </Box>
        {children}
      </Flex>

    </Box>
  );
};

export { DeviceSelector };

import type { HMSPeer } from '@100mslive/react-sdk';
import { Flex } from '@100mslive/react-ui';

import { VideoList } from './VideoList';

type GridSidePaneViewProps = {
  peers: HMSPeer[];
};

// Side pane shows smaller tiles
const GridSidePaneView = ({ peers }: GridSidePaneViewProps) => {

  return (
    <Flex
      direction='column'
      css={{
        flex: '0 0 20%',
        mx: '$8',
        '@lg': {
          flex: '0 0 25%',
        },
        '@md': {
          flex: '1 1 0',
        },
      }}
    >
      <Flex css={{ flex: '1 1 0' }} align='end'>
        {
          peers?.length > 0 && (
            <VideoList peers={peers} maxColCount={2} />
          )
        }
      </Flex>
    </Flex>
  );
};

export { GridSidePaneView };

import { Box } from '@100mslive/react-ui';

import { useSidepaneState } from '../app-data/useSidepane';
import { ParticipantList } from '../components/participants/ParticipantList';
import { HMS_SIDE_PANE_OPTIONS } from '../constants';

type SidePaneProps = {
	css?: Record<string, any>;
};

const SidePane = ({
	css = {}
}: SidePaneProps) => {

  const sidepane = useSidepaneState();

  let ViewComponent: React.FC | undefined;
  if (sidepane === HMS_SIDE_PANE_OPTIONS.PARTICIPANTS) {
    ViewComponent = ParticipantList;
  }

  if (!ViewComponent) {
    return null;
  }

  return (
    <Box
      css={{
        position: 'absolute',
        w: '$100',
        h: '100%',
        p: '$10',
        bg: '$surfaceDefault',
        r: '$1',
        top: 0,
        right: '$10',
        zIndex: 10,
        boxShadow: '$md',
        ...css,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        '@lg': {
          w: '100%',
          right: 0,
          position: 'fixed',
          bottom: 0,
          height: 'unset',
          ...(css['@lg'] || {}),
        },
      }}
    >
      <ViewComponent />
    </Box>
  );
};

export { SidePane };

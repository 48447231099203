import { ENDPOINT, RestApiError, Role } from '@almer/almer-beam-api';

import { REST_API_BASE_URL } from '../../constants';
import { buildRestApiHeaders } from '../../utils';

async function createRoomToken(accessToken: string, roomId: string): Promise<string> {

    const url = new URL(`${ENDPOINT.ROOMS}/token`, REST_API_BASE_URL);
    const response = await fetch(url, {
        method: 'POST',
        headers: buildRestApiHeaders({ token: accessToken }),
        body: JSON.stringify({
            roomId,
            role: Role.ALMER_BROWSER
        })
    });

    if (!response.ok) {

        const error = await response.json() as RestApiError;
        throw new Error('Could not create room token', { cause: error });
    }
    const room = await response.json() as string;

    return room;
}

export { createRoomToken };

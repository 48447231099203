import { NavBar } from '../components/navigation-bar/NavBar';
import { AppRoute, GROUP_CALL_TITLE, NAV_BAR_ROUTES } from '../constants';

function CallbackPage() {
  return (
    <div>
      <NavBar
        navBarRoutes={NAV_BAR_ROUTES}
        groupCallButtonTitle={GROUP_CALL_TITLE}
        dropDownMenu={{
          items: []
        }}
        hasNotification={false}
        notificationRoute={AppRoute.CALL_INVITATIONS}
      />
      <div />
    </div>
  );
}

export { CallbackPage };

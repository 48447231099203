import { useCallback, useEffect } from 'react';
import {
  selectIsConnectedToRoom,
  useCustomEvent,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk';

const REFRESH_MESSAGE = 'refresh';
const METADATA_MESSAGE_TYPE = 'metadata';

/**
 * Refresh(re-populate) session metadata on receiving refresh broadcast message of type metadata
 */
const useRefreshSessionMetadata = () => {

    const hmsActions = useHMSActions();
    const isConnected = useHMSStore(selectIsConnectedToRoom);

    useEffect(() => {
        if (isConnected) {
            hmsActions.populateSessionMetadata().catch(console.error);
        }
    }, [hmsActions, isConnected]);

  const onEvent = useCallback((message: string) => {
        if (message === REFRESH_MESSAGE) {
            hmsActions.populateSessionMetadata().catch(console.error);
        }
    }, [hmsActions]);

    useCustomEvent({
        type: METADATA_MESSAGE_TYPE,
        json: false,
        onEvent,
    });
};

export { METADATA_MESSAGE_TYPE, REFRESH_MESSAGE, useRefreshSessionMetadata };

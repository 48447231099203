import type { HMSAudioTrack, HMSScreenVideoTrack, HMSVideoTrack } from '@100mslive/react-sdk';

const PEER_NAME_PLACEHOLDER = 'peerName';
const labelMap = new Map([
  [[true, 'screen'].toString(), 'Your Screen'],
  [[true, 'regular'].toString(), `You (${PEER_NAME_PLACEHOLDER})`],
  [[false, 'screen'].toString(), `${PEER_NAME_PLACEHOLDER}'s Screen`],
  [[false, 'regular'].toString(), PEER_NAME_PLACEHOLDER],
  [[true, undefined].toString(), `You (${PEER_NAME_PLACEHOLDER})`],
  [[false, undefined].toString(), `${PEER_NAME_PLACEHOLDER}`],
]);

type VideoTileLabelProps = {
  peerName?: string;
  isLocal: boolean;
  videoTrack?: HMSVideoTrack | HMSScreenVideoTrack | null;
  audioTrack?: HMSAudioTrack;
};

const VideoTileLabel = ({
  peerName, isLocal, videoTrack, audioTrack
}: VideoTileLabelProps) => {

  const isPeerPresent = peerName !== undefined;
  if (!isPeerPresent || !videoTrack) {

    // for peers with only audio track
    return isPeerPresent
      ? labelMap.get([isLocal, undefined].toString())!.replace(PEER_NAME_PLACEHOLDER, peerName)
      : '';
  }

  const isLocallyMuted = audioTrack?.volume === 0;

  // Map [isLocal, videoSource] to the label to be displayed.
  let label = labelMap.get([isLocal, videoTrack.source].toString());
  if (label) {
    label = label.replace(PEER_NAME_PLACEHOLDER, peerName);
  } else {
    label = `${peerName} ${videoTrack.source}`;
  }
  label = `${label}${videoTrack.degraded ? '(Degraded)' : ''}`;

  return `${label}${isLocallyMuted ? ' (Muted for you)' : ''}`;
};

export { VideoTileLabel };

import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {Dialog, Transition} from "@headlessui/react";

import {AppRoute, EmptyPlaceholder, SearchPlaceholder} from "../../constants";
import {AsyncThunkStatus} from "../../models/constants";
import {useAppDispatch, useAppSelector} from "../../models/hooks";
import {createRoomAsync, selectCreateRoomStatus} from "../../models/rooms/roomsSlice";
import {Status} from "../../models/users/constants";
import {DecoratedUser} from "../../models/users/types";
import {selectAllUsers,} from "../../models/users/usersSlice";
import {List, ListItemGroups} from "../common/list/List";
import {ListItemAction, ListItemProps} from "../common/list/ListItem";
import {textForStatus} from "../constants";


enum Section {
    SELECTED = 'Selected',
    TEAM = 'Team'
}

function buildViewModel(user: DecoratedUser, action: ListItemAction): ListItemProps {

    return {
        id: user.id,
        avatar: user.avatar,
        title: user.name,
        secondColumnTitle: 'Status',
        secondColumnSubtitle: textForStatus(user.status),
        action: action
    };
}

function buildViewModels(users: DecoratedUser[], selectedUsers: DecoratedUser[]): ListItemGroups {

    const viewModels: ListItemGroups = {
        [Section.SELECTED]: [],
        [Section.TEAM]: []
    };

    users
        .filter(user => !selectedUsers.includes(user))
        .forEach((user) =>
            viewModels[Section.TEAM].push(buildViewModel(user, ListItemAction.ADD)));

    selectedUsers.forEach((user) =>
        viewModels[Section.SELECTED].push(buildViewModel(user, ListItemAction.REMOVE)));

    return viewModels;
}

function GroupCallModal({show, close}:{show: boolean, close: () => void}) {

    const allUsers = useAppSelector(selectAllUsers);
    const [selectedUsers, setSelectedUsers] = useState<DecoratedUser[]>([]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const createRoomStatus = useAppSelector(selectCreateRoomStatus);

    function handleItemAction(item: ListItemProps) {
        const user = allUsers.find(it => it.id === item.id);
        if (!user) return;
        if (item.action === ListItemAction.ADD) {
            setSelectedUsers([...selectedUsers, user]);
        } else if (item.action === ListItemAction.REMOVE) {
            setSelectedUsers(selectedUsers.filter(it => it != user));
        }
    }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Dialog.Panel className='min-w-[75%] relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                            <Dialog.Title className='text-xl font-semibold text-gray-900 ml-4'> Create a new group call </Dialog.Title>

                            <div className='overflow-scroll h-[75vh]'>
                                <List
                                    datasource={{
                                        groupedItems: buildViewModels(allUsers, selectedUsers)
                                    }}
                                    stickyHeaders={true}
                                    stickySearchBar={true}
                                    searchable={true}
                                    searchPlaceholder={SearchPlaceholder.TEAM}
                                    emptyPlaceholder={EmptyPlaceholder.TEAM}
                                    onItemAction={handleItemAction}
                                />
                            </div>

                            <div className='flex justify-between'>
                                <button
                                    type='button'
                                    className='mt-3 -mb-3 flex h-12 w-24 rounded-md items-center justify-center bg-gray-300'
                                    onClick={close}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    className='mt-3 -mb-3 flex h-12 w-24 rounded-md items-center justify-center bg-green-500'
                                    disabled={selectedUsers.length == 0}
                                    onClick={() => {
                                        (async () => {
                                            // clicking multiple times doesn't break the room anymore
                                            if (createRoomStatus !== AsyncThunkStatus.IDLE) return;
                                            const accessToken = await getAccessTokenSilently();
                                            await dispatch(createRoomAsync({
                                                accessToken,
                                                participants: selectedUsers.map(it => it.id)
                                            })).unwrap().then(res => {
                                                navigate(AppRoute.CALL + "/" + res[0].id);
                                            });

                                            close();
                                        })().catch(console.error);
                                    }}
                                >
                                    Start call
                                </button>
                            </div>
                        </Dialog.Panel>
                    </div>

                </div>

            </Dialog>
        </Transition.Root>
    );
}

export {GroupCallModal};
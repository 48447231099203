import { useLocation, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { NavBarItemTitle } from '../../constants';

import { NavBarItem } from './NavBarItem';
import { NavBarLeftContainerProps } from './types';

function NavBarLeftContainer({
  isOpen, navBarRoutes
}: NavBarLeftContainerProps) {

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='flex'>

      <div className='-ml-2 mr-2 flex items-center md:hidden'>
        <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
          <span className='sr-only'>Open main menu</span>
          {
            isOpen
              ? <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
              : <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
          }
        </Disclosure.Button>
      </div>

      <div className='flex flex-shrink-0 items-center'>
        <img
          className='block h-8 w-auto lg:hidden'
          src='/almer-logo-large.png'
          alt='Almer'
        />
        <img
          className='hidden h-8 w-auto lg:block'
          src='/almer-logo-large.png'
          alt='Almer'
        />
      </div>

      <div className='hidden md:ml-6 md:flex md:space-x-8'>
        {
          (Object.keys(navBarRoutes) as NavBarItemTitle[]).map((title: NavBarItemTitle) => (
            <NavBarItem
              key={title}
              isSelected={navBarRoutes[title] === location.pathname}
              title={title}
              onClick={() => navigate(navBarRoutes[title])}
            />
          ))
        }
      </div>

    </div>
  );
}

export { NavBarLeftContainer };

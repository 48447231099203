import { selectAppDataByPath, useHMSStore } from '@100mslive/react-sdk';

import { HMS_APP_DATA } from '../constants';
import { AppLayout } from '../types';

const useAppLayoutByKey = <K extends keyof AppLayout>(appLayoutKey: K): AppLayout[K] => {

    const appLayoutSetting = useHMSStore(
        selectAppDataByPath(HMS_APP_DATA.appLayout, appLayoutKey)
    ) as unknown as AppLayout[K];

    return appLayoutSetting;
};

export { useAppLayoutByKey };

import { BrbIcon, HandIcon } from '@100mslive/react-icons';
import { selectIsConnectedToRoom, useHMSStore } from '@100mslive/react-sdk';
import { Flex, Tooltip } from '@100mslive/react-ui';

import { useMetadata } from '../../hooks/useMetadata';
import { IconButton } from '../common/IconButton';

type MetaActionsProps = {
  isMobile?: boolean;
  compact?: boolean;
};

const MetaActions = ({
  isMobile = false, compact = false
}: MetaActionsProps) => {

  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const { isHandRaised, isBRBOn, toggleHandRaise, toggleBRB } = useMetadata();

  if (!isConnected) {
    return null;
  }

  return (
    <Flex align='center' css={{ gap: compact ? '$4' : '$8' }}>

      <Tooltip title={`${!isHandRaised ? 'Raise' : 'Unraise'} hand`}>
        <IconButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={toggleHandRaise}
          active={!isHandRaised}
          data-testid={`${
            isMobile ? 'raise_hand_btn_mobile' : 'raise_hand_btn'
          }`}
        >
          <HandIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={`${isBRBOn ? `I'm back` : `I'll be right back`}`}>
        <IconButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={toggleBRB}
          active={!isBRBOn}
          data-testid='brb_btn'
        >
          <BrbIcon />
        </IconButton>
      </Tooltip>

    </Flex>
  );
};

export { MetaActions };

import { Fragment } from 'react';
import { useMedia } from 'react-use';
import type { HMSPeer } from '@100mslive/react-sdk';
import { Box, config as cssConfig } from '@100mslive/react-ui';

import { FirstPersonDisplay } from './FirstPersonDisplay';
import { VideoList } from './VideoList';

const MAX_TILES_FOR_MOBILE = 4;

type GridCenterViewProps = {
  peers: HMSPeer[];
  maxTileCount: number;
};

// The center of the screen shows bigger tiles
const GridCenterView = ({
  peers, maxTileCount
}: GridCenterViewProps) => {

  const mediaQueryLg = cssConfig.media.md;
  const limitMaxTiles = useMedia(mediaQueryLg);

  return (
    <Fragment>
      <Box
        css={{
          flex: '1 1 0',
          height: '100%',
          mx: '$8',
          '@md': { flex: '2 1 0' },
        }}
      >
        {
          peers?.length > 0
            ? (
                <VideoList
                  peers={peers}
                  maxTileCount={limitMaxTiles ? MAX_TILES_FOR_MOBILE : maxTileCount}
                />
              )
            : <FirstPersonDisplay />
        }
      </Box>
    </Fragment>
  );
};

export { GridCenterView };

import { useMeasure } from 'react-use';
import { FixedSizeList } from 'react-window';
import { Box } from '@100mslive/react-ui';

import { VirtualizedParticipantsProps } from './types';
import { VirtualizedParticipantListItem } from './VirtualizedParticipantListItem';

function itemKey(index: number, data: VirtualizedParticipantsProps): string {
  return data.participants[index].id;
}

const VirtualizedParticipantList = ({
  participants,
  isConnected,
}: VirtualizedParticipantsProps) => {

  const [ref, { width, height }] = useMeasure();

  return (
    <Box
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      css={{
        flex: '1 1 0',
        mr: '-$10',
      }}
    >
      <FixedSizeList
        itemSize={68}
        itemData={{ participants, isConnected }}
        itemKey={itemKey}
        itemCount={participants.length}
        width={width}
        height={height}
      >
        { VirtualizedParticipantListItem }
      </FixedSizeList>
    </Box>
  );
};

export { VirtualizedParticipantList };

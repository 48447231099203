enum Device {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE',
    GLASSES = 'GLASSES'
}

enum Status {
    AVAILABLE = 'AVAILABLE',
    IN_CALL = 'IN_CALL',
    OFFLINE = 'OFFLINE'
}

type PresenceInfo = {
    device: Device;
    status: Status;
}

export { Device, Status };
export type { PresenceInfo };
import { styled, Text, textEllipsis } from '@100mslive/react-ui';

import { ConnectionIndicator } from './ConnectionIndicator';

type TileConnectionProps = {
  name: string;
  peerId: string;
  hideLabel: boolean;
  width: number | string;
};

const TileConnection = ({
  name,
  peerId,
  hideLabel,
  width
}: TileConnectionProps) => {

  return (
    <Wrapper>
      {
        !hideLabel
          ? (
              <Text
                css={{
                  c: '$textHighEmp',
                  ...textEllipsis(typeof width === 'number' ? width - 60 : width),
                }}
                variant='xs'
              >
                {name}
              </Text>
            )
          : null
      }
      <ConnectionIndicator isTile peerId={peerId} />
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: '$2',
  left: '$2',
  backgroundColor: '$backgroundDark',
  borderRadius: '$1',
  zIndex: 1,
  '& p,span': {
    p: '$2 $3',
  },
});

export { TileConnection };

import { MinusIcon } from '@heroicons/react/24/solid';

type RemoveButtonProps = {
    onClick: () => void;
};

function RemoveButton({ onClick }: RemoveButtonProps) {

    return (
        <button
            className='text-red-500 hover:text-red-700 active:text-red-900'
            onClick={() => onClick()}
        >
            <MinusIcon className='h-6 w-6' aria-hidden='true' />
        </button>
    );
}

export { RemoveButton };
export type { RemoveButtonProps };

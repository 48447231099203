import React from "react";

class DesktopIcon extends React.Component {
    render() {
        return (
            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10.5V9H1.25V0H13.75V9H15V10.5H0ZM5 9H10V7.5H5V9Z" fill="#969696"/>
            </svg>
        );
    }
}

export { DesktopIcon };
import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {LogoutOptions, useAuth0} from '@auth0/auth0-react';

import './services/realtime/index';

import {PageLayout} from "./components/PageLayout";
import {AsyncThunkStatus} from "./models/constants";
import { useAppDispatch, useAppSelector } from './models/hooks';
import type { AppDispatch } from "./models/store";
import {
  cleanupIdentity,
  fetchAllAsync as fetchAllUsersAsync,
  selectFetchAllStatus,
  selectWhoami,
  setupIdentity
} from './models/users/usersSlice';
import { CallInvitationsPage } from './pages/call-invitations-page';
import { CallLeavePage } from './pages/call-leave-page';
import { CallPage } from './pages/call-page';
import {CallbackPage} from "./pages/callback-page";
import { NotFoundPage } from './pages/not-found-page';
import { ProfilePage } from './pages/profile-page';
import { RecentCallsPage } from './pages/recent-calls-page';
import { TeamPage } from './pages/team-page';
import { AblyService } from "./services/AblyService";
import {RealtimeService} from "./services/realtime";
import {
  AppRoute
} from './constants';

function handleLogout(dispatch: AppDispatch, logout: (options?: LogoutOptions) => void) {

  RealtimeService.sharedInstance.cleanup();
  dispatch(cleanupIdentity);

  logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}

function App() {

  const dispatch = useAppDispatch();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const whoami = useAppSelector(selectWhoami);

  const fetchAllStatus = useAppSelector(selectFetchAllStatus);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {

        if (!whoami) {
          return;
        }

        if (fetchAllStatus === AsyncThunkStatus.IDLE) {

          const accessToken = await getAccessTokenSilently();
          await dispatch(fetchAllUsersAsync(accessToken)).unwrap();
        }

      })().catch(console.error);
    }
  }, [dispatch, fetchAllStatus, getAccessTokenSilently, whoami, isAuthenticated]);

  useEffect(() => {

    if (user && !whoami) {
      dispatch(setupIdentity(user));
    }

    if (!whoami) {
      return;
    }

    RealtimeService.initialize(new AblyService(whoami.id, whoami.orgId, whoami.name, whoami.email));
  }, [dispatch, user, whoami]);

   return (
    <div className='flex flex-col h-screen'>

      <Routes>

        <Route element={<PageLayout handleLogout={handleLogout}/>}>
          <Route
              index
              element={<TeamPage />}
          />
          <Route
              path={AppRoute.RECENT_CALLS}
              element={<RecentCallsPage />}
          />
          <Route
              path={AppRoute.CALL_INVITATIONS}
              element={<CallInvitationsPage />}
          />
          <Route
              path={AppRoute.USER_PROFILE}
              element={<ProfilePage />}
          />
        </Route>

        <Route element={<PageLayout unguarded={true}/>}>
          <Route
              path={AppRoute.AUTH_CALLBACK}
              element={<CallbackPage />}
          />
          <Route
              path={AppRoute.CATCH_ALL}
              element={<NotFoundPage />}
          />
        </Route>

        <Route
          path={AppRoute.CALL}
          element={<PageLayout onCallRoute={true} />} >
          <Route
              index
              element={<NotFoundPage />}
          />
          <Route
            path=':roomId'
            element={<CallPage />}
          />
          <Route
              path='leave'
              element={<CallLeavePage />}
          />
        </Route>

      </Routes>
    </div>
  );
}

export { App };

enum HMS_APP_DATA {
    uiSettings = 'uiSettings',
    sidePane = 'sidePane',
    appLayout = 'appLayout',
    pinnedTrackId = 'pinnedTrackId',
    pinnedUserId = 'pinnedUserId'
}

enum HMS_UI_SETTINGS {
    isAudioOnly = 'isAudioOnly',
    maxTileCount = 'maxTileCount',
    uiViewMode = 'uiViewMode',
    showStatsOnTiles = 'showStatsOnTiles',
    mirrorLocalVideo = 'mirrorLocalVideo',
}

enum HMS_SIDE_PANE_OPTIONS {
    PARTICIPANTS = 'Participants',
}

const HMS_UI_MODE_ACTIVE_SPEAKER = 'activespeaker';
const HMS_UI_MODE_GRID = 'grid';

export {
    HMS_APP_DATA,
    HMS_SIDE_PANE_OPTIONS,
    HMS_UI_MODE_ACTIVE_SPEAKER,
    HMS_UI_MODE_GRID,
    HMS_UI_SETTINGS
};

type ListSectionHeaderProps = {
  title: string;
  sticky: boolean;
};

function getTailwindCss(sticky: boolean) {
  return `${sticky ? 'sticky': ''} top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500`;
}

function ListSectionHeader({ title, sticky }: ListSectionHeaderProps) {
  return (
    <div className={getTailwindCss(sticky)}>
      <h3>{title}</h3>
    </div>
  );
}

export { ListSectionHeader };
export type { ListSectionHeaderProps };

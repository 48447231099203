import { css } from '@100mslive/react-ui';

const settingOverflow = css({
    flex: "1 1 0",
    pr: "$12",
    mr: "-$12",
    overflowY: "auto",
});

const settingContent = css({
    display: "flex",
    flexDirection: "column",
    "&[hidden]": {
        display: "none",
    },
});

export { settingContent, settingOverflow };

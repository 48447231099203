import { Disclosure } from '@headlessui/react';

import { MenuItemProps } from './types';

function MenuItemDisclosure({ title, onClick }: MenuItemProps) {

  return (
    <Disclosure.Button
      as='a'
      href='#'
      className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6'
      onClick={onClick}
    >
      {title}
    </Disclosure.Button>
  );
}

export { MenuItemDisclosure };

import {
  PhoneArrowDownLeftIcon, PhoneArrowUpRightIcon, PhoneXMarkIcon
} from '@heroicons/react/24/outline';
import {PhoneIcon} from "@heroicons/react/24/solid";

import { AddButton } from "../button/AddButton";
import { CallButton } from '../button/CallButton';
import { InfoButton } from '../button/InfoButton';
import { JoinButton } from '../button/JoinButton';
import {RemoveButton} from "../button/RemoveButton";
import {ArcIcon} from "../icons/ArcIcon";
import {DesktopIcon} from "../icons/DesktopIcon";

enum RoleIcon {
  DESKTOP_DEVICE = 'DESKTOP_DEVICE',
  MOBILE_DEVICE = 'MOBILE_DEVICE',
  GLASSES_DEVICE = 'GLASSES_DEVICE'
}

enum CallIcon {
  OUTGOING_CALL = 'OUTGOING_CALL',
  INCOMING_CALL = 'INCOMING_CALL',
  REJECTED_CALL = 'REJECTED_CALL'
}

enum ListItemAction {
  NONE = 'NONE',
  CALL = 'CALL',
  INFO = 'INFO',
  JOIN = 'JOIN',
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}

type ListItemProps = {
  id: string;
  avatar: string;
  title: string;
  subtitle?: string;
  subtitleIcon?: CallIcon | RoleIcon;
  secondColumnTitle?: string;
  secondColumnSubtitle?: string;
  secondColumnSubtitleIcon?: CallIcon | RoleIcon;
  selected?: boolean;
  action?: ListItemAction;
  onAction?: () => void;
  roomId?: string;
};

function getButton(action: ListItemAction, onAction?: () => void): JSX.Element | undefined {

  switch (action) {

    case ListItemAction.NONE:
      return undefined;

    case ListItemAction.CALL:
      return (
        <CallButton onClick={() => {
          if (onAction) {
            onAction();
          }
        }} />
      );

    case ListItemAction.INFO:
      return (
        <InfoButton onClick={() => {
          if (onAction) {
            onAction();
          }
        }} />
      );

    case ListItemAction.JOIN:
      return (
        <JoinButton onClick={() => {
          if (onAction) {
            onAction();
          }
        }} />
      );

    case ListItemAction.ADD:
      return (
          <AddButton onClick={() => {
            if (onAction) {
              onAction();
            }
          }} />
      );

    case ListItemAction.REMOVE:
      return (
          <RemoveButton onClick={() => {
            if (onAction) {
              onAction();
            }
          }} />
      );
  }
}

function getIcon(icon: CallIcon | RoleIcon) {

  const tailwindCss = 'mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400';

  switch (icon) {
    case RoleIcon.DESKTOP_DEVICE:
      return <DesktopIcon />;

    case RoleIcon.GLASSES_DEVICE:
      return <ArcIcon />;

    case RoleIcon.MOBILE_DEVICE:
      return <PhoneIcon />; // TODO: Change?

    case CallIcon.INCOMING_CALL:
      return <PhoneArrowDownLeftIcon className={tailwindCss} aria-hidden='true' />;

    case CallIcon.OUTGOING_CALL:
      return <PhoneArrowUpRightIcon className={tailwindCss} aria-hidden='true' />;

    case CallIcon.REJECTED_CALL:
      return <PhoneXMarkIcon className={tailwindCss} aria-hidden='true' />;
  }
}

function ListItem({
  id,
  avatar,
  title,
  subtitle,
  subtitleIcon,
  secondColumnTitle,
  secondColumnSubtitle,
  secondColumnSubtitleIcon,
  selected = false,
  action = ListItemAction.NONE,
  onAction
}: ListItemProps) {

  return (
    <li>
      <div className='flex items-center px-4 py-4 sm:px-6'>
        <div className='flex min-w-0 flex-1 items-center'>
          <div className='flex-shrink-0'>
            <img className='h-12 w-12 rounded-full' src={avatar} alt='' />
          </div>
          <div className='min-w-0 items-center flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
            <div>
              <p className='truncate text-sm font-medium text-indigo-600'>{title}</p>
              {
                <p className='mt-2 flex items-center text-sm text-gray-500'>
                  { subtitleIcon ? getIcon(subtitleIcon) : <></> }
                  { subtitle ? <span className='truncate pl-1'>{subtitle}</span> : <></> }
                </p>
              }
            </div>
            <div className='hidden md:block'>
              <div>
                <p className='text-sm text-gray-900'>
                  {secondColumnTitle}
                </p>
                <p className='mt-2 flex items-center text-sm text-gray-500'>
                  { secondColumnSubtitleIcon ? getIcon(secondColumnSubtitleIcon) : <></>}
                  { secondColumnSubtitle ? <span className='truncate'>{secondColumnSubtitle}</span> : <></>}
                </p>
              </div>
            </div>
          </div>
        </div>
        {getButton(action, onAction)}
      </div>
    </li>
  );
}

export { CallIcon, ListItem, ListItemAction, RoleIcon };
export type { ListItemProps };

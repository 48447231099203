import { flexCenter, styled } from '@100mslive/react-ui';

import { PreviewJoin } from './PreviewJoin';
import type { PreviewProps } from './types';

const Container = styled('div', {
  width: '100%',
  ...flexCenter,
  flexDirection: 'column',
});

const PreviewContainer = ({
  token,
  onJoin,
  skipPreview,
  initialName,
  asRole,
}: PreviewProps) => {

  return (
    <Container>
      <PreviewJoin
        initialName={initialName}
        skipPreview={skipPreview}
        onJoin={onJoin}
        token={token}
        asRole={asRole}
      />
    </Container>
  );
};

export { PreviewContainer };

import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import { SettingsIcon } from '@100mslive/react-icons';
import { useAVToggle, usePreviewJoin } from '@100mslive/react-sdk';
import {
  Flex,
  flexCenter,
  styled,
  Text,
} from '@100mslive/react-ui';

import {
  defaultPreviewPreference,
  UserPreferencesKeys,
  useUserPreferences,
} from '../../hooks/useUserPreferences';
import { AudioVideoToggle } from '../common/AudioVideoToggle';
import { IconButton } from '../common/IconButton';
import { SettingsModal } from '../settings/SettingsModal';

import { PreviewName } from './PreviewName';
import { PreviewTile } from './PreviewTile';
import { PreviewProps } from './types';

const PreviewJoin = ({
  token,
  onJoin,
  skipPreview,
  initialName,
  asRole
}: PreviewProps) => {

  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [name, setName] = useState(initialName || previewPreference!.name);

  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);

  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    // initEndpoint: 'https://adrianbr.app.100ms.live/init',
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference!.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference!.isVideoMuted,
      speakerAutoSelectionBlacklist: ['Yeti Stereo Microphone'],
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === 'preview') {
        setPreviewError(true);
      }
    },
    asRole,
  });

  const savePreferenceAndJoin = useCallback(() => {

    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });

    join()
      .then(() => onJoin && onJoin())
      .catch(console.error);

  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);

  useEffect(() => {

    if (token) {

      if (skipPreview) {
        savePreferenceAndJoin();
      } else {
        preview().catch(console.error);
      }
    }

  }, [token, preview, savePreferenceAndJoin, skipPreview]);

  return (
    <Container>
      <Text variant='h4' css={{ wordBreak: 'break-word', textAlign: 'center' }}>
        Get Started
      </Text>
      <Text
        css={{ c: '$textMedEmp', my: '$6', textAlign: 'center' }}
        variant='body1'
      >
        Setup your audio and video before joining
      </Text>
      <Flex
        align='center'
        justify='center'
        css={{
          '@sm': { width: '100%' },
          flexDirection: 'column',
        }}
      >
        <PreviewTile name={name} error={previewError} />
        <PreviewControls />
        <PreviewName
          name={name}
          onChange={setName}
          enableJoin={enableJoin}
          onJoin={savePreferenceAndJoin}
        />
      </Flex>
    </Container>
  );
};

const Container = styled('div', {
  width: '100%',
  ...flexCenter,
  flexDirection: 'column',
  px: '$10',
});

const PreviewControls = () => {
  return (
    <Flex
      justify='between'
      css={{
        width: '100%',
        mt: '$8',
      }}
    >
      <Flex css={{ gap: '$4' }}>
        <AudioVideoToggle />
      </Flex>
      <PreviewSettings />
    </Flex>
  );
};

// eslint-disable-next-line react/display-name
const PreviewSettings = memo(() => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <IconButton
        data-testid='preview_setting_btn'
        onClick={() => setOpen(value => !value)}
      >
        <SettingsIcon />
      </IconButton>
      {open && <SettingsModal open={open} onOpenChange={setOpen} />}
    </Fragment>
  );
});

export { PreviewJoin };

import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import {LogoutOptions, useAuth0} from "@auth0/auth0-react";

import {AppRoute, DropDownItemTitle, GROUP_CALL_TITLE, NAV_BAR_ROUTES} from "../constants";
import {useAppDispatch, useAppSelector} from "../models/hooks";
import {AppDispatch} from "../models/store";
import {selectWhoami} from "../models/users/usersSlice";
import {RealtimeService} from "../services/realtime";

import {AuthenticationGuard} from "./auth/AuthenticationGuard";
import {CallNotification} from "./calls/CallNotification";
import {GroupCallModal} from "./calls/GroupCallModal";
import {Spinner} from "./common/Spinner";
import {NavBar} from "./navigation-bar/NavBar";


function PageLayout(
    {
        handleLogout,
        onCallRoute,
        unguarded
    }:{
        handleLogout?: (dispatch: AppDispatch, logout: (options?: LogoutOptions) => void) => void,
        onCallRoute?: boolean,
        unguarded?: boolean
    }) {
    const whoami = useAppSelector(selectWhoami);
    const [showGroupCallModal, setShowGroupCallModal] = useState(false);

    const { isAuthenticated, logout } = useAuth0();
    const dispatch = useAppDispatch();

    if (onCallRoute && !RealtimeService.isInitialized()) {
        return <Spinner />
    } else return (
        <>
            {handleLogout && <NavBar
                navBarRoutes={NAV_BAR_ROUTES}
                groupCallButtonTitle={GROUP_CALL_TITLE}
                onGroupCallButtonAction={() => setShowGroupCallModal(true)}
                dropDownMenu={{
                    avatar: whoami?.avatar,
                    title: whoami?.name,
                    subtitle: whoami?.email,
                    items: [
                        { title: DropDownItemTitle.LOGOUT, onClick: () => handleLogout(dispatch, logout) }
                    ]
                }}
                notificationRoute={AppRoute.CALL_INVITATIONS}
            />}

            {!unguarded && <AuthenticationGuard component={Outlet} />}
            {unguarded && <Outlet />}

            {isAuthenticated && <GroupCallModal show={showGroupCallModal} close={() => setShowGroupCallModal(false)} />}
            <CallNotification acceptRoute={AppRoute.CALL} onCallRoute={onCallRoute}/>
        </>
    );
}

export {PageLayout};
import { ENDPOINT, RestApiError,Room } from '@almer/almer-beam-api';

import { REST_API_BASE_URL } from '../../constants';
import { buildRestApiHeaders } from '../../utils';

async function createRoom(token: string): Promise<Room> {

    const url = new URL(ENDPOINT.ROOMS, REST_API_BASE_URL);
    const response = await fetch(url, {
        method: 'POST',
        headers: buildRestApiHeaders({ token }),
        body: JSON.stringify({})
    });

    if (!response.ok) {

        const error = await response.json() as RestApiError;
        throw new Error('Could not create room', { cause: error });
    }
    const room = await response.json() as Room;

    return room;
}

export { createRoom };

import React, {Fragment} from 'react';
import {Transition} from "@headlessui/react";

const NoNotificationsToast = ({show}:{show: boolean}) => {
    return (
        <div
            aria-live='assertive'
            className='z-30 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'
        >
            <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                <Transition
                    show={show}
                    as={Fragment}
                    enter='transform ease-out duration-300 transition'
                    enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                    enterTo='translate-y-8 opacity-100 sm:translate-x-0'
                    leave='transition ease-in duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
                        <div className='p-4'>
                            <div className='flex items-start'>
                                    There are currently no new notifications!
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default NoNotificationsToast;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrevious } from 'react-use';
import {
    HMSRoomState,
    selectIsConnectedToRoom,
    selectRoomState, useHMSActions,
    useHMSStore,
} from '@100mslive/react-sdk';
import { Box, Flex } from '@100mslive/react-ui';

import { AppRoute } from '../../constants';
import { ConferenceMainView } from '../layouts/ConferenceMainView';

import { FullPageProgress } from './common/FullPageProgress';
import { ConferencingFooter } from './footer/ConferencingFooter';
import { ConferencingHeader } from './header/ConferencingHeader';

const ConferenceScreen = ({onReconnect}:{onReconnect: () => void}) => {

  const navigate = useNavigate();

  const roomState = useHMSStore(selectRoomState);
  const prevState = usePrevious(roomState);
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();

  useEffect(() => {
    if (isConnectedToRoom) {
      hmsActions.sessionStore.observe(['pinnedTrackId', 'pinnedUserId']).catch(console.error);
    }
  }, [hmsActions, isConnectedToRoom]);

  useEffect(() => {

    if (
      !prevState
      && !(roomState === HMSRoomState.Connecting
        || roomState === HMSRoomState.Reconnecting
        || isConnectedToRoom
      )
    ) {
        onReconnect()
    }

    if (roomState === HMSRoomState.Disconnected) {
        navigate(AppRoute.CALL_LEAVE)
    }

  }, [isConnectedToRoom, prevState, roomState, navigate]);

  if (!isConnectedToRoom) {
    return <FullPageProgress />;
  }

  return (
    <Flex css={{ size: '100%' }} direction='column'>

      <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid='header'>
        <ConferencingHeader />
      </Box>

      <Box
        css={{
          w: '100%',
          flex: '1 1 0',
          minHeight: 0,
        }}
        data-testid='conferencing'
      >
        <ConferenceMainView />
      </Box>

      <Box css={{ flexShrink: 0, minHeight: '$24' }} data-testid='footer'>
        <ConferencingFooter />
      </Box>
    </Flex>
  );
};

export { ConferenceScreen };

import { MouseEvent, ReactElement } from 'react';
import { CheckIcon } from '@100mslive/react-icons';
import type { useParticipantsParams } from '@100mslive/react-sdk';
import { Dropdown, Flex, Text } from '@100mslive/react-ui';

type ParticipantFilterItemProps = {
  selected: boolean;
  title: string;
  onSelection: (selection?: useParticipantsParams) => void;
  value?: useParticipantsParams;
  icon?: ReactElement;
};

const ParticipantFilterItem = ({
  selected, title, onSelection, value, icon
}: ParticipantFilterItemProps) => {

  return (
    <Dropdown.Item
      onClick={(event: MouseEvent<HTMLDivElement>) => {

        event.preventDefault();
        onSelection(value);
      }}
    >

      <Flex
        align='center'
        css={{ flex: '1 1 0' }}
      >
        { icon && <Text>{icon}</Text> }
        <Text css={{ ml: '$4' }}>{title}</Text>
      </Flex>

      { selected && (
        <Text>
          <CheckIcon />
        </Text>
      ) }

    </Dropdown.Item>
  );
};

export { ParticipantFilterItem };

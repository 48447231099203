import { Fragment, useState } from 'react';
import { SettingsIcon, VerticalMenuIcon } from '@100mslive/react-icons';
import {
  Box,
  Dropdown,
  Text,
  Tooltip,
} from '@100mslive/react-ui';

import { IconButton } from '../common/IconButton';

import { SettingsModal } from './SettingsModal';

const MoreSettings = () => {

  const [open, setOpen] = useState(false);
  const [showDeviceSettings, setShowDeviceSettings] = useState(false);

  return (
    <Fragment>
      <Dropdown.Root open={open} onOpenChange={setOpen}>
        <Dropdown.Trigger asChild data-testid='more_settings_btn'>
          <IconButton>
            <Tooltip title='More options'>
              <Box>
                <VerticalMenuIcon />
              </Box>
            </Tooltip>
          </IconButton>
        </Dropdown.Trigger>

        <Dropdown.Content
          sideOffset={5}
          align='center'
          css={{ maxHeight: '$96', '@md': { w: '$64' } }}
        >

          <Dropdown.ItemSeparator />
          <Dropdown.Item
            onClick={() => setShowDeviceSettings(true)}
            data-testid='device_settings_btn'
          >
            <SettingsIcon />
            <Text variant='sm' css={{ ml: '$4' }}>
              Settings
            </Text>
          </Dropdown.Item>

        </Dropdown.Content>
      </Dropdown.Root>

      {showDeviceSettings && (
        <SettingsModal open onOpenChange={setShowDeviceSettings} />
      )}
    </Fragment>
  );
};

export { MoreSettings };

import {useNavigate} from "react-router-dom";
import { VolumeOneIcon } from '@100mslive/react-icons';
import {
  selectDominantSpeaker,
  selectIsConnectedToRoom,
  useHMSStore,
} from '@100mslive/react-sdk';
import {
  Flex,
  styled,
  Text,
  textEllipsis,
  useTheme,
} from '@100mslive/react-ui';

import {AppRoute} from "../../../constants";

const SpeakerTag = () => {

  const dominantSpeaker = useHMSStore(selectDominantSpeaker);

  return (
    dominantSpeaker?.name
      ? (
        <Flex
          align='center'
          justify='center'
          css={{ flex: '1 1 0', color: '$textPrimary', '@md': { display: 'none' } }}
        >
          <VolumeOneIcon />
          <Text
            variant='md'
            css={{ ...textEllipsis(200), ml: '$2' }}
            title={dominantSpeaker.name}
          >
            {dominantSpeaker.name}
          </Text>
        </Flex>
      )
      : <></>
  );
};

const LogoImg = styled('img', {
  maxHeight: '$14',
  p: '$2',
  w: 'auto',
  '@md': {
    maxHeight: '$12',
  },
});

const Logo = () => {

  const navigate = useNavigate();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const { themeType } = useTheme();

  return (
    <LogoImg
      src={
        themeType === 'dark'
          ? '/almer-logo-large-light.png'
          : '/almer-logo-large.png'
      }
      alt='Brand Logo'
      width={132}
      height={40}
      onClick={() => {
        if (!isConnected) navigate(AppRoute.ROOT);
      }}
    />
  );
};

export { Logo, SpeakerTag };

import { useCallback, useState } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  HandRaiseIcon,
  PeopleIcon,
} from '@100mslive/react-icons';
import type { useParticipantsParams } from '@100mslive/react-sdk';
import {
    Box, Dropdown, Flex, Text, textEllipsis
} from '@100mslive/react-ui';
import {Role} from "@almer/almer-beam-api";

import { ParticipantFilterItem } from './ParticipantFilterItem';

type ParticipantFilterProps = {
  selection?: useParticipantsParams;
  onSelection: (selection?: useParticipantsParams) => void;
  isConnected: boolean;
  roles: string[];
};

const ParticipantFilter = ({
  selection,
  onSelection,
  isConnected,
  roles,
}: ParticipantFilterProps) => {

  const [open, setOpen] = useState(false);

  const selectionValue = selection?.role || (selection?.metadata?.isHandRaised ? 'Raised Hand' : '');

  const onItemClick = useCallback((value?: useParticipantsParams) => {

    onSelection(value);
    setOpen(false);

  }, [onSelection]);

  const getRoleTag = (role: string) => {
      if (role === Role.ALMER_GLASSES) {
          return "Almer Arc";
      } else if (role === Role.ALMER_BROWSER) {
          return "Browser";
      } else return "";
    }

  if (!isConnected) {
    return null;
  }

  return (
    <Dropdown.Root
      open={open}
      onOpenChange={value => setOpen(value)}
    >

      <Dropdown.Trigger
        asChild
        data-testid='participant_list_filter'
        css={{
          border: '1px solid $textDisabled',
          r: '$0',
          p: '$2 $4',
        }}
        tabIndex={0}
      >
        <Flex align='center'>

          <Text variant='sm' css={{ ...textEllipsis(80) }}>
            { selectionValue || 'Everyone' }
          </Text>

          <Box css={{ ml: '$2', color: '$textDisabled' }}>
            { open ? <ChevronUpIcon /> : <ChevronDownIcon /> }
          </Box>

        </Flex>
      </Dropdown.Trigger>

      <Dropdown.Content
        align='start'
        sideOffset={8}
        css={{
          height: 'auto',
          maxHeight: '$96',
          boxShadow: '$md',
          w: '$48',
        }}
      >

        <ParticipantFilterItem
          selected={!selection}
          title='Everyone'
          onSelection={onItemClick}
          icon={<PeopleIcon />}
        />

        <ParticipantFilterItem
          selected={ (selection?.metadata ?? {} as Record<string, any>)['isHandRaised'] as boolean }
          title='Raised Hand'
          onSelection={onItemClick}
          icon={<HandRaiseIcon />}
          value={{
            metadata: {
              isHandRaised: true
            },
            role: '',
            search: ''
          }}
        />

        <Dropdown.ItemSeparator />

        {
          roles.map((role) => (
            <ParticipantFilterItem
              key={role}
              selected={selectionValue === role}
              title={getRoleTag(role)}
              value={{
                metadata: {
                  isHandRaised: false
                },
                role,
                search: ''
              }}
              onSelection={onItemClick}
            />
          ))
        }
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export { ParticipantFilter };

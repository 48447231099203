import { CSSProperties, memo } from 'react';

import { Participant } from './Participant';
import { VirtualizedParticipantsProps } from './types';

type VirtualizedParticipantListItem = {
  style: CSSProperties;
  index: number;
  data: VirtualizedParticipantsProps;
};

const VirtualizedParticipantListItem = memo(
  function VirtualizedParticipantListItem({
    style, index, data
  }: VirtualizedParticipantListItem) {

    return (
      <div
        style={style}
        key={data.participants[index].id}
      >
        <Participant
          peer={data.participants[index]}
          isConnected={data.isConnected}
        />
      </div>
    );
  }
);

export { VirtualizedParticipantListItem };

import { Fragment, useCallback } from 'react';
import type { HMSPeer } from '@100mslive/react-sdk';

import { LargeTilePeerView } from './LargeTilePeerView';
import { SmallTilePeersView } from './SmallTilePeersView';

type SidePaneProps = {
  isPresenterInSmallTiles: boolean;
  peerScreenSharing: HMSPeer;
  smallTilePeers: HMSPeer[];
  showSidebarInBottom: boolean;
};

// Sidepane will show the camera stream of the main peer who is screensharing
// and both camera + screen(if applicable) of others
const SidePane = ({
  isPresenterInSmallTiles,
  peerScreenSharing, // the peer who is screensharing
  smallTilePeers,
  showSidebarInBottom,
}: SidePaneProps) => {

  // The main peer's screenshare is already being shown in center view
  const shouldShowScreenFn = useCallback((peer: HMSPeer) => {
    return peerScreenSharing && peer.id !== peerScreenSharing.id
  }, [peerScreenSharing]);

  return (
    <Fragment>

      {
        !isPresenterInSmallTiles && (
          <LargeTilePeerView peerScreenSharing={peerScreenSharing} />
        )
      }

      <SmallTilePeersView
        showSidebarInBottom={showSidebarInBottom}
        smallTilePeers={smallTilePeers}
        shouldShowScreenFn={shouldShowScreenFn}
      />

    </Fragment>
  );
};

export { SidePane };

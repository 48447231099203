import { Fragment, useCallback, useState } from 'react';
import { CrossIcon } from '@100mslive/react-icons';
import { useParticipants, useParticipantsParams } from '@100mslive/react-sdk';
import { Flex, Text } from '@100mslive/react-ui';

import { useSidepaneToggle } from '../../app-data/useSidepane';
import { HMS_SIDE_PANE_OPTIONS } from '../../constants';
import { IconButton } from '../common/IconButton';

import { ParticipantFilter } from './ParticipantFilter';
import { ParticipantSearch } from './ParticipantSearch';
import { VirtualizedParticipantList } from './VirtualizedParticipantList';

const ParticipantList = () => {

  const [filter, setFilter] = useState<useParticipantsParams>();
  const {
    participants, isConnected, peerCount, rolesWithParticipants
  } = useParticipants(filter);

  const toggleSidepane = useSidepaneToggle(HMS_SIDE_PANE_OPTIONS.PARTICIPANTS);

  const onSearch = useCallback((value: string) => {

    setFilter((filterValue?: useParticipantsParams) => {

      if (!filterValue) {
        filterValue = {} as useParticipantsParams;
      }
      filterValue.search = value;

      return { ...filterValue };
    });
  }, []);

  if (peerCount === 0) {
    return null;
  }

  return (
    <Fragment>
      <Flex direction='column' css={{ size: '100%' }}>

        <Flex align='center' css={{ w: '100%', mb: '$10' }}>

          <Text css={{ fontWeight: '$semiBold', mr: '$4' }}>
            Participants
          </Text>

          <ParticipantFilter
            selection={filter}
            onSelection={setFilter}
            isConnected={isConnected ?? false}
            roles={rolesWithParticipants as string[] ?? []}
          />

          <IconButton
            onClick={toggleSidepane}
            css={{ w: '$11', h: '$11', ml: 'auto' }}
          >
            <CrossIcon />
          </IconButton>

        </Flex>

        {
          !filter?.search && participants.length === 0
            ? null
            : <ParticipantSearch onSearch={onSearch} />
        }

        {
          participants.length === 0 && (
            <Flex align='center' justify='center' css={{ w: '100%', p: '$8 0' }}>
              <Text variant='sm'>
                {!filter ? 'No participants' : 'No matching participants'}
              </Text>
            </Flex>
          )
        }

        <VirtualizedParticipantList
          participants={participants}
          isConnected={isConnected ?? false}
        />

      </Flex>
    </Fragment>
  );
};

export { ParticipantList };

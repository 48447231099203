import { useEffect } from 'react';
import { PeopleIcon } from '@100mslive/react-icons';
import { selectPeerCount, useHMSStore } from '@100mslive/react-sdk';
import { Text } from '@100mslive/react-ui';

import { useIsSidepaneTypeOpen, useSidepaneToggle } from '../../app-data/useSidepane';
import { HMS_SIDE_PANE_OPTIONS } from '../../constants';
import { IconButton } from '../common/IconButton';

const ParticipantCount = () => {

  const peerCount = useHMSStore(selectPeerCount);

  const toggleSidepane = useSidepaneToggle(HMS_SIDE_PANE_OPTIONS.PARTICIPANTS);
  const isParticipantsOpen = useIsSidepaneTypeOpen(HMS_SIDE_PANE_OPTIONS.PARTICIPANTS);

  useEffect(() => {

    if (isParticipantsOpen && peerCount === 0) {
      toggleSidepane();
    }

  }, [isParticipantsOpen, peerCount, toggleSidepane]);

  if (peerCount === 0) {
    return null;
  }

  return (
    <IconButton
      css={{
        w: 'auto',
        p: '$4',
        h: 'auto',
      }}
      onClick={() => {
        if (peerCount > 0) {
          toggleSidepane();
        }
      }}
      active={!isParticipantsOpen}
      data-testid='participant_list'
    >
      <PeopleIcon />
      <Text variant='sm' css={{ mx: '$4', c: 'inherit' }}>
        {peerCount}
      </Text>
    </IconButton>
  );
};

export { ParticipantCount };

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Fragment } from 'react';
import { MicOnIcon, SpeakerIcon, VideoOnIcon } from '@100mslive/react-icons';
import {
  DeviceType,
  selectIsLocalVideoEnabled,
  selectLocalVideoTrackID,
  selectVideoTrackByID,
  useDevices,
  useHMSStore,
} from '@100mslive/react-sdk';
import { Box, StyledVideoTile, Video } from '@100mslive/react-ui';

import { useUISettingsByKey } from '../../../app-data/useUISettings';
import { HMS_UI_SETTINGS } from '../../../constants';
import { settingOverflow } from '../utils';

import { DeviceSelector } from './DeviceSelector';
import { TestAudio } from './TestAudio';

type SettingsProps = {
  setHide: (hide: boolean) => void;
};

/**
 * wrap the button on click of whom settings should open, this component will take care of the rest,
 * it'll give the user options to change input/output device as well as check speaker.
 * There is also another controlled way of using this by passing in open and onOpenChange.
 */
const DeviceSettings = ({ setHide }: SettingsProps) => {

  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
  const { videoInput, audioInput, audioOutput } = allDevices;

  const videoTrackId = useHMSStore(selectLocalVideoTrackID);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);

  // don't show speaker selector where the API is not supported, and use
  // a generic word('Audio') for Mic. In some cases(Chrome Android for e.g.) this changes both mic and speaker keeping them in sync.
  const shouldShowAudioOutput = 'setSinkId' in HTMLMediaElement.prototype;

  const mirrorLocalVideo = useUISettingsByKey(HMS_UI_SETTINGS.mirrorLocalVideo);

  const trackSelector = selectVideoTrackByID(videoTrackId);
  const track = useHMSStore(trackSelector);

  /**
   * Chromium browsers return an audioOutput with empty label when no permissions are given
   */
  const audioOutputFiltered = audioOutput?.filter(item => !!item.label) ?? [];

  if (!videoInput?.length
    && !audioInput?.length
    && !audioOutputFiltered?.length) {

    setHide(true);
  }

  return (
    <Box className={settingOverflow()}>

      {
        videoInput?.length
          ? (
            <Fragment>

              {isVideoOn && (
                <StyledVideoTile.Container
                  css={{
                    w: '90%',
                    px: '$10',
                    height: '$48',
                    bg: 'transparent',
                    m: '$10 auto',
                  }}
                >
                  <Video
                    trackId={videoTrackId}
                    mirror={track?.facingMode !== 'environment' && mirrorLocalVideo}
                  />
                </StyledVideoTile.Container>
              )}

              <DeviceSelector
                title='Video'
                devices={videoInput}
                icon={<VideoOnIcon />}
                selection={selectedDeviceIDs.videoInput}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={(deviceId: string) => updateDevice({ deviceId, deviceType: DeviceType.videoInput })}
              />

            </Fragment>
          )
          : null
      }

      {
        audioInput?.length
          ? (
            <DeviceSelector
              title={shouldShowAudioOutput ? 'Microphone' : 'Audio'}
              icon={<MicOnIcon />}
              devices={audioInput}
              selection={selectedDeviceIDs.audioInput}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChange={(deviceId: string) => updateDevice({ deviceId, deviceType: DeviceType.audioInput })}
            />
          )
          : null
      }

      {
        audioOutputFiltered?.length && shouldShowAudioOutput
          ? (
            <DeviceSelector
              title='Speaker'
              icon={<SpeakerIcon />}
              devices={audioOutput!}
              selection={selectedDeviceIDs.audioOutput}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChange={(deviceId: string) => updateDevice({ deviceId, deviceType: DeviceType.audioOutput })}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <TestAudio sinkId={selectedDeviceIDs.audioOutput!} />
            </DeviceSelector>
          )
          : null
      }
    </Box>
  );
};

export { DeviceSettings };

import {
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  selectVideoTrackByID,
  useHMSStore
} from '@100mslive/react-sdk';
import {
  Avatar,
  Loading,
  StyledVideoTile,
  Text,
  textEllipsis,
  useBorderAudioLevel,
  useTheme,
  Video,
} from '@100mslive/react-ui';

import { useUISettingsByKey } from '../../app-data/useUISettings';
import { HMS_UI_SETTINGS } from '../../constants';
import { TileConnection } from '../connection/TileConnection';

type PreviewTileProps = {
  name: string;
  error: boolean;
};

const PreviewTile = ({
  name, error
}: PreviewTileProps) => {

  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const mirrorLocalVideo = useUISettingsByKey(HMS_UI_SETTINGS.mirrorLocalVideo);
  const trackSelector = selectVideoTrackByID(localPeer?.videoTrack);
  const track = useHMSStore(trackSelector);

  const {
    aspectRatio: { width, height },
  } = useTheme();

  return (
    <StyledVideoTile.Container
      css={{
        bg: '$surfaceDefault',
        aspectRatio: width / height,
        width: 'unset',
        height: 'min(360px, 60vh)',
        mt: '$12',
        '@sm': {
          height: 'unset',
          width: 'min(360px, 100%)',
          maxWidth: '100%',
        },
      }}
      ref={borderAudioRef}
    >
      {
        localPeer
          ? (
              <>
                <TileConnection name={name} peerId={localPeer.id} hideLabel={true} width={0} />
                <Video
                  mirror={track?.facingMode !== 'environment' && mirrorLocalVideo}
                  trackId={localPeer.videoTrack}
                  data-testid='preview_tile'
                />
                {
                  !isVideoOn
                    ? (
                        <StyledVideoTile.AvatarContainer>
                          <Avatar name={name} data-testid='preview_avatar_tile' />
                          <Text css={{ ...textEllipsis('75%') }} variant='body2'>
                            {name}
                          </Text>
                        </StyledVideoTile.AvatarContainer>
                      )
                    : null
                }
              </>
            )
          : !error ? <Loading size={100} /> : null
      }
    </StyledVideoTile.Container>
  );
};

export { PreviewTile };

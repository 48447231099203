import {useState} from 'react';

import {SearchBar} from '../SearchBar';

import {EmptyList} from './EmptyList';
import {FlatList} from './FlatList';
import {ListItemProps} from './ListItem';
import {ListSection} from './ListSection';

type ListItemGroups = Record<string, ListItemProps[]>;

type ListDatasource = {
  flatItems?: ListItemProps[];
  groupedItems?: ListItemGroups;
};

type ListProps = {
  multiselect?: boolean;
  datasource: ListDatasource;
  stickyHeaders?: boolean;
  stickySearchBar?: boolean;
  searchable?: boolean;
  searchPlaceholder?: string;
  emptyPlaceholder?: string;
  errorPlaceholder?: string;
  onItemAction?: (item: ListItemProps) => void
};

function filterItems(items: ListItemProps[], text: string) {

  if (text.split(' ').length > 1) {
    return items.filter(
      (item) => item.title.toLowerCase().startsWith(text.toLowerCase())
    );
  }
  return items.filter(
    (item) => item.title.split(' ').some((word) => word.toLowerCase().startsWith(text.toLowerCase()))
  );
}

function filterGroupedItems(groupedItems: ListItemGroups, text: string) {

  const filteredItems: ListItemGroups = {};
  Object.keys(groupedItems).forEach((section) => {
    filteredItems[section] = filterItems(groupedItems[section], text);
  });

  return filteredItems;
}

function List({
  datasource,
  stickyHeaders = false,
  stickySearchBar = false,
  searchable = true,
  searchPlaceholder = 'Search',
  emptyPlaceholder,
  onItemAction
}: ListProps) {

  const [searchText, setSearchText] = useState('');

  const { flatItems, groupedItems } = datasource;
  const flatItemsFiltered = flatItems && filterItems(flatItems, searchText);
  const groupedItemsFiltered = groupedItems && filterGroupedItems(groupedItems, searchText);

  return (<div className='min-w-[75%] h-full self-center overflow-y-hidden'>

      {
        searchable
            ? <SearchBar
                sticky={true}
                placeholder={searchPlaceholder}
                onInput={(text) => { setSearchText(text) }}
            />
            : <></>
      }

    <nav className='self-center h-full overflow-y-auto' aria-label='List'>

      {(() => {

        if (flatItemsFiltered) {

          return (
            <FlatList
              items={flatItemsFiltered}
              emptyPlaceholder={emptyPlaceholder}
              onItemAction={(item) => {
                if (onItemAction) {
                  onItemAction(item);
                }
              }}
            />
          )
        }

        if (!groupedItemsFiltered) {
          throw new Error('One of "flatItems" or "groupedItems" is mandatory for List.datasource');
        }

        let emptySectionCount = 0;
        const elements = Object.keys(groupedItemsFiltered).map((title) => {

          if (!groupedItemsFiltered[title].length) {
            return <div key={++emptySectionCount}></div>;
          }

          return (
            <ListSection key={title} title={title} stickyHeader={stickyHeaders}>
              <FlatList
                items={groupedItemsFiltered[title]}
                onItemAction={(item) => {
                  if (onItemAction) {
                    onItemAction(item);
                  }
                }}
              />
            </ListSection>
          );
        });

        if (emptySectionCount === Object.keys(groupedItemsFiltered).length) {
          return <EmptyList placeholder={emptyPlaceholder} />;
        }

        return elements;
      })()}

    </nav>
    </div>

  )
}

export { List };
export type { ListDatasource, ListItemGroups, ListProps };


import { Fragment, useState } from 'react';
import { MusicIcon } from '@100mslive/react-icons';
import { selectIsAllowedToPublish, useHMSStore, useScreenShare } from '@100mslive/react-sdk';
import { Tooltip } from '@100mslive/react-ui';

import { IconButton } from '../../common/IconButton';

import { ScreenshareHintModal } from './ScreenshareHintModal';

const ScreenshareAudio = () => {

  const {
    amIScreenSharing,
    screenShareVideoTrackId: video,
    screenShareAudioTrackId: audio,
    toggleScreenShare,
  } = useScreenShare();

  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  const isAudioScreenshare = amIScreenSharing && !video && !!audio;

  const [showModal, setShowModal] = useState(false);

  if (!isAllowedToPublish.screen) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip
        title={`${!isAudioScreenshare ? 'Start' : 'Stop'} audio sharing`}
        key='shareAudio'
      >
        <IconButton
          active={!isAudioScreenshare}
          onClick={() => {
            if (amIScreenSharing) {
              toggleScreenShare!().catch(console.error);
            } else {
              setShowModal(true);
            }
          }}
          data-testid='screenshare_audio'
        >
          <MusicIcon />
        </IconButton>
      </Tooltip>
      {showModal && (
        <ScreenshareHintModal onClose={() => setShowModal(false)} />
      )}
    </Fragment>
  );
};

export { ScreenshareAudio };

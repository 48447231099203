import { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {selectIsConnectedToRoom, useHMSActions, useHMSStore} from "@100mslive/react-sdk";
import { useAuth0 } from '@auth0/auth0-react';

import { AppRoute, CALL_NOTIFICATION_TIME } from '../../constants';
import {selectNotification} from "../../models/call-invitations/callInvitationsSlice";
import { CallInvitation } from '../../models/call-invitations/types';
import { useAppDispatch, useAppSelector } from '../../models/hooks';
import { createRoomTokenAsync } from '../../models/rooms/roomsSlice';
import {DecoratedUser} from "../../models/users/types";
import { selectAllUsers } from '../../models/users/usersSlice';

import { CallNotificationModal } from './call-notifications/CallNotificationModal';
import { CallNotificationToast } from './call-notifications/CallNotificationToast';

type CallNotificationProps = {
  invitation?: CallInvitation;
  acceptRoute: AppRoute;
  onCallRoute?: boolean;
};

function CallNotification({
  acceptRoute,
  onCallRoute
}: CallNotificationProps) {

  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const callNotification = useAppSelector(selectNotification);

  const { getAccessTokenSilently } = useAuth0();
  const users = useAppSelector(selectAllUsers);

  const sender = users.filter((user) => user.id === callNotification?.sender)[0];
  const totalPeers =
    (callNotification?.recipients?.length ?? 0) +
    (callNotification?.participants?.length ?? 0);

  const message = `invites you to join a ${totalPeers > 1 ? 'group' : ''} call.`;

  const joinCall = (isToast: boolean) => {
    setShow(false);
    if (isConnected) {
      hmsActions.leave().then(() => {
        if (callNotification) {
          // ensures the page refreshes, and the new call can be joined
          window.location.assign(acceptRoute + "/" + callNotification.roomId);
        }
      }).catch(console.error);
    }
    if (callNotification) {
      if (isToast) {
        window.location.assign(acceptRoute + "/" + callNotification.roomId);
      }
      else {
        navigate(acceptRoute + "/" + callNotification.roomId);
      }
    }
  };

  const createNotification = (caller: string) => {
    const notification = new Notification("Incoming call", {body: `${caller} ${message}`});
    notification.onclick = () => {
      navigate(AppRoute.CALL_INVITATIONS)
    }
  }

  const popNotification = (sender?: DecoratedUser) => {
    if (!sender) return;
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      createNotification(sender?.name);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          createNotification(sender?.name);
        }
      }).catch(console.error);
    }
  }

  const cancel = () => {
    setShow(false);
  };

  useEffect(() => {

    setShow(!!callNotification);
    setTimeout(() => {
      cancel()
      popNotification(sender)
    }, CALL_NOTIFICATION_TIME);

    (async () => {

      if (callNotification) {
        const accessToken = await getAccessTokenSilently();
        await dispatch(
          createRoomTokenAsync({
            accessToken,
            roomId: callNotification.roomId,
          })
        ).unwrap();
      }
    })().catch(console.error);

  }, [dispatch, getAccessTokenSilently, callNotification]);

  if (onCallRoute) {
    return (
      <CallNotificationToast
        show={show}
        joinCall={() => joinCall(true)}
        cancel={cancel}
        sender={sender}
        message={message}
      />
    );
  }

  return (
    <CallNotificationModal
      show={show}
      joinCall={() => joinCall(false)}
      cancel={cancel}
      sender={sender}
      message={message}
    />
  );
}

export { CallNotification };

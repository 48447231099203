import { PlusIcon } from '@heroicons/react/24/solid';

type SelectButtonProps = {
    onClick: () => void;
};

function AddButton({ onClick }: SelectButtonProps) {

    return (
        <button
            className='text-green-500 hover:text-green-700 active:text-green-900'
            onClick={() => onClick()}
        >
            <PlusIcon className='h-6 w-6' aria-hidden='true' />
        </button>
    );
}

export { AddButton };
export type { SelectButtonProps };

import { VideoCameraIcon } from '@heroicons/react/24/outline';

type JoinButtonProps = {
  onClick: () => void;
};

function JoinButton({ onClick }: JoinButtonProps) {

  return (
    <button
      className='text-indigo-500 hover:text-indigo-700 active:text-indigo-900'
      onClick={() => onClick()}
    >
      <VideoCameraIcon className='h-6 w-6' aria-hidden='true' />
    </button>
  );
}

export { JoinButton };
export type { JoinButtonProps };

import { InformationCircleIcon } from '@heroicons/react/24/outline';

type InfoButtonProps = {
  onClick: () => void;
};

function InfoButton({ onClick }: InfoButtonProps) {

  return (
    <button
      className='text-indigo-500 hover:text-indigo-700 active:text-indigo-900'
      onClick={() => onClick()}
    >
      <InformationCircleIcon className='h-6 w-6' aria-hidden='true' />
    </button>
  );
}

export { InfoButton };
export type { InfoButtonProps };

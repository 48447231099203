import {Device, Status} from "../models/users/constants";

import {RoleIcon} from "./common/list/ListItem";

function textForStatus(status: Status) {

    switch (status) {
        case Status.AVAILABLE: return 'Available';
        case Status.IN_CALL: return 'In Call';
        case Status.OFFLINE: return 'Offline';
    }
}

function textForDevice(device?: Device) {

    if (!device) {
        return undefined;
    }

    switch (device) {
        case Device.DESKTOP: return 'Browser';
        case Device.GLASSES: return 'Glasses';
        case Device.MOBILE: return 'Mobile';
    }
}

function iconForDevice(device?: Device) {
    switch (device) {
        case Device.DESKTOP:
            return RoleIcon.DESKTOP_DEVICE;
        case Device.GLASSES:
            return RoleIcon.GLASSES_DEVICE;
        case Device.MOBILE:
            return RoleIcon.MOBILE_DEVICE;
    }}

function iconForStatus(status: Status) {
    return undefined; // TODO: FIXME
}

export {iconForDevice, iconForStatus, textForDevice, textForStatus};
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

type SearchBarProps = {
  placeholder?: string;
  onInput: (text: string) => void;
  sticky: boolean;
};

function SearchBar({
  placeholder = 'Search', onInput, sticky = false
}: SearchBarProps) {

  return (
    <div className={`w-full my-4 px-6 ${sticky ? 'sticky z-10' : ''}`}>
      <label htmlFor='search' className='sr-only'>
        {placeholder}
      </label>
      <div className='relative'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </div>
        <input
          id='search'
          name='search'
          className='block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
          placeholder={placeholder}
          type='search'
          onInput={(event) => onInput((event.target as HTMLInputElement).value)}
        />
      </div>
    </div>
  );
}

export { SearchBar };
export type { SearchBarProps };

import {
    ENDPOINT, PagedResponse, PagingFilter, RestApiError, User
} from '@almer/almer-beam-api';

import { REST_API_BASE_URL } from '../../constants';
import { buildRestApiHeaders } from '../../utils';

async function listUsers(token: string, paging: PagingFilter): Promise<PagedResponse<User>> {

    const url = new URL(ENDPOINT.USERS, REST_API_BASE_URL);
    Object.entries(paging).forEach(([name, value]) => url.searchParams.append(name, value.toString()));

    const response = await fetch(url, {
        method: 'GET',
        headers: buildRestApiHeaders({ token })
    });

    if (!response.ok) {

        const error = await response.json() as RestApiError;
        throw new Error('Could not list users', { cause: error });
    }
    const users = await response.json() as PagedResponse<User>;

    return users;
}

export { listUsers };

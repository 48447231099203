import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthScope } from '@almer/almer-beam-api';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

import {AppRoute, ENV, OpenIDConnectScopes} from '../../constants';

function toFullPath(pathname: string): string {
    const url = new URL(window.location.href);

    url.pathname = pathname;

    return url.href
}

function Auth0ProviderWithNavigate({ children }: { children: ReactElement }) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const scopes = [
    ...(Object.values(AuthScope) as string[]),
    ...(Object.values(OpenIDConnectScopes) as string[])
  ];

  const redirectUri = ENV.AUTH0_CALLBACK_URL ?? toFullPath(AppRoute.AUTH_CALLBACK)

  return (
    <Auth0Provider
      domain={ENV.AUTH0_DOMAIN}
      clientId={ENV.AUTH0_CLIENT_ID}
      authorizationParams={{
        scope: scopes.join(' '),
        audience: ENV.AUTH0_AUDIENCE,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation='localstorage'
    >
      {children}
    </Auth0Provider>
  );
}

export { Auth0ProviderWithNavigate };

import { useState } from 'react';
import { useLocalStorage } from 'react-use';

enum UserPreferencesKeys {
    PREVIEW = 'preview',
    NOTIFICATIONS = 'notifications',
    UI_SETTINGS = 'uiSettings'
}

const defaultPreviewPreference = {
    name: '',
    isAudioMuted: false,
    isVideoMuted: false,
};

const useUserPreferences = <T,>(
    key: UserPreferencesKeys,
    defaultPreference?: T
): [
    T | undefined,
    (value: T) => void
] => {

    const [localStorageValue, setStorageValue] = useLocalStorage(key, defaultPreference);
    const [preference, setPreference] = useState(localStorageValue || defaultPreference);

    const changePreference = (value: T) => {
        setPreference(value);
        setStorageValue(value);
    };

    return [preference, changePreference];
};

export { defaultPreviewPreference, UserPreferencesKeys, useUserPreferences };

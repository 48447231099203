import { NavBarItemProps } from './types';

const SELECTED_CLASS = 'inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900';
const DEFAULT_CLASS = 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700';

function NavBarItem({ isSelected, title, onClick }: NavBarItemProps) {

  return (
    <button
      className={ isSelected ? SELECTED_CLASS : DEFAULT_CLASS }
      onClick={onClick}
    >
      { title }
    </button>
  );
}

export { NavBarItem };

enum PointType {
    NORMAL = 0,
    END = 1,
}

/**
 * A Double between 0 and 1
 */
export type Weight = number

export interface NormalizedPoint {
    x: Weight;
    y: Weight;
    t: PointType;
    ts: number;
}

export interface Point {
    x: number;
    y: number;
    ts: number;
}

interface FadingLineDrawerOptions {
    /**
     * How often the lines will have their opacity recomputed
     */
    refreshFrequency?: number;
    /**
     * How long will a line in the drawing
     */
    strokeTime?: number;
    /**
     * How thick a lines is in pixels
     */
    strokeSize?: number;
    /**
     * Color of the Line
     */
    strokeColor?: RGB;
    /**
     * Define the speed of the opacity transformation.
     *
     * Alpha = (1 - ((NOW - CREATION_TS) / strokeTime)) ** fadePower
     */
    fadePower?: number;
}

function clamp(min: number, max: number, number: number) {
    return Math.min(Math.max(number, min), max)
}

const clampWeight = clamp.bind(null, 0, 1)

function pointFromCanvas(mouseX: number, mouseY: number, canvasHeight: number, canvasWidth: number, t: PointType): NormalizedPoint {

    const x = clampWeight(mouseX / canvasWidth)
    const y = clampWeight(mouseY / canvasHeight)

    return {
        x,
        y,
        t,
        ts: Date.now(),
    }
}

export type Hook = (point: NormalizedPoint) => void | Promise<void>

class RGB {
    static Red = new RGB(255, 0, 0)
    private readonly _rgbaCacheString: string;
    private readonly _rgbString: string;

    constructor(
        public r: number,
        public g: number,
        public b: number,
    ) {
        this._rgbaCacheString = `rgb(${this.r},${this.g},${this.b},`
        this._rgbString = `rgb(${this.r},${this.g},${this.b})`
    }

    get rgbString() {
        return this._rgbString
    }

    rgbaString(a: number) {
        return `${this._rgbaCacheString}${a})`
    }
}

export class FadingLineDrawer {
    private painting = true;
    private mouseX = 0;
    private mouseY = 0;
    private lastX = 0;
    private lastY = 0;
    readonly ctx: CanvasRenderingContext2D;
    readonly fadeOutPeriod: number;
    readonly strokeSize: number;
    readonly strokeColor: RGB;
    private cleanUp: (() => void)[] = [];
    private hook: Hook | undefined = undefined
    private points: Point[][] = []
    readonly strokeTime: number;
    readonly fadePower: number;

    constructor(
        private canvas: HTMLCanvasElement, {
            refreshFrequency = 100,
            strokeTime = 4500,
            strokeSize = 5,
            strokeColor = RGB.Red,
            fadePower = 4,
        }: FadingLineDrawerOptions = {}) {
        this.strokeSize = strokeSize;
        this.ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        this.fadeOutPeriod = 1000 / refreshFrequency;
        this.strokeSize = strokeSize;
        this.strokeColor = strokeColor;
        this.strokeTime = strokeTime;
        this.fadePower = fadePower
    }

    private mountListener<K extends keyof HTMLElementEventMap, EventType extends Event | MouseEvent>(target: HTMLElement, event: K, handler: (e: EventType) => void) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.cleanUp.push(() => target.removeEventListener(event, handler as any))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        target.addEventListener(event, handler as any);
    }

    handleMouseMove(e: MouseEvent) {
        this.mouseX = e.offsetX;
        this.mouseY = e.offsetY

        this.points[this.points.length - 1].push({x: this.mouseX, y: this.mouseY, ts: Date.now()})

        this.ctx.beginPath();
        // line width setting
        this.ctx.lineWidth = this.strokeSize;
        // line color setting
        this.ctx.strokeStyle = this.strokeColor.rgbString;

        this.ctx.moveTo(this.lastX, this.lastY);
        this.ctx.lineTo(this.mouseX, this.mouseY);
        this.ctx.stroke();
        this.ctx.closePath();

        this.lastX = this.mouseX;
        this.lastY = this.mouseY;

        if (this.hook) {
            void this.hook(pointFromCanvas(this.mouseX, this.mouseY, this.canvas.height, this.canvas.width, PointType.END))
        }
    }

    handleMouseEventBound = this.handleMouseMove.bind(this)

    listen(): void {
        const noDraw = () => {
            this.painting = false;
            this.canvas.removeEventListener('mousemove', this.handleMouseEventBound);
        };
        this.mountListener(this.canvas, 'mousedown', (e: MouseEvent) => {
            this.painting = true;

            this.lastX = e.offsetX;
            this.lastY = e.offsetY;

            this.points.push([{x: this.lastX, y: this.lastY, ts: Date.now()}])

            if (this.hook) {
                void this.hook(pointFromCanvas(this.lastX, this.lastY, this.canvas.height, this.canvas.width, PointType.NORMAL))
            }

            this.canvas.addEventListener('mousemove', this.handleMouseEventBound);
        });
        this.mountListener(this.canvas, 'mouseup', noDraw);
        this.mountListener(this.canvas, 'mouseleave', noDraw);
        this.mountListener(document.body, 'mouseleave', noDraw);
        this.fadeOut()
    }

    fadeOutBound = this.fadeOut.bind(this);

    private redrawPoints() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        const now = Date.now()
        for (let i2 = 0; i2 < this.points.length; i2++) {
            let points = this.points[i2];
            let i = 0;
            while (i < points.length && now - points[i].ts > this.strokeTime)
                i++

            points = this.points[i2] = points.slice(i)

            if (!points.length) {
                continue
            }


            let lastX = points[0].x
            let lastY = points[0].y
            for (let i1 = 1; i1 < points.length; i1++) {
                const point = points[i1];

                const mouseX = point.x
                const mouseY = point.y

                const delta = now - point.ts
                const alpha = (1 - delta / this.strokeTime) ** this.fadePower


                this.ctx.beginPath()
                this.ctx.strokeStyle = this.strokeColor.rgbaString(alpha)
                this.ctx.moveTo(lastX, lastY);
                this.ctx.lineTo(mouseX, mouseY);
                this.ctx.stroke();
                this.ctx.closePath()

                lastX = mouseX;
                lastY = mouseY;
            }
        }
    }

    fadeOut() {
        if (this.points.length) {
            this.redrawPoints();
        }
        let i = 0;
        while (i < this.points.length && this.points[i].length === 0)
            i++

        if (i !== 0)
            this.points = this.points.slice(i)

        setTimeout(this.fadeOutBound, this.fadeOutPeriod);
    }

    stop(): void {
        this.painting = false
        for (const cleanUpElement of this.cleanUp) {
            cleanUpElement()
        }
        this.painting = false
    }

    registerHook(hook: Hook) {
        this.hook = hook
    }
}



import { useState } from 'react';
import { useDebounce } from 'react-use';
import { SearchIcon } from '@100mslive/react-icons';
import { Box, Input } from '@100mslive/react-ui';

type ParticipantSearchProps = {
  onSearch: (value: string) => void;
  placeholder?: string;
};

const ParticipantSearch = ({
  onSearch, placeholder
}: ParticipantSearchProps) => {

  const [value, setValue] = useState('');
  useDebounce(
    () => onSearch(value),
    300,
    [value, onSearch]
  );

  return (
    <Box css={{ p: '$4 0', my: '$8', position: 'relative' }}>
      <Box
        css={{
          position: 'absolute',
          left: '$4',
          top: '$2',
          transform: 'translateY(50%)',
          color: '$textMedEmp',
        }}
      >
        <SearchIcon />
      </Box>
      <Input
        type='text'
        placeholder={placeholder || 'Search participants'}
        css={{ w: '100%', pl: '$14' }}
        value={value}
        onKeyDown={event => {
          event.stopPropagation();
        }}
        onChange={event => {
          setValue(event.currentTarget.value);
        }}
        autoComplete='off'
        aria-autocomplete='none'
      />
    </Box>
  );
};

export { ParticipantSearch };

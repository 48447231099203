
import type { CSSProperties } from 'react';
import { CheckIcon } from '@100mslive/react-icons';
import { Checkbox, Label } from '@100mslive/react-ui';

import { DialogRow } from './DialogRow';

type DialogCheckboxProps = {
  title: string;
  value: boolean | 'indeterminate';
  onChange: (value: boolean) => void;
  disabled?: boolean;
  css?: CSSProperties;
  id: string;
};

const DialogCheckbox = ({
  title,
  value,
  onChange,
  disabled = false,
  css,
  id,
}: DialogCheckboxProps) => {

  return (
    <DialogRow css={css}>
      <Label htmlFor={id} css={{ cursor: 'pointer' }}>
        {title}
      </Label>
      <Checkbox.Root
        checked={value}
        onCheckedChange={value => onChange(value as boolean)}
        disabled={disabled}
        id={id}
      >
        <Checkbox.Indicator>
          <CheckIcon width={16} height={16} />
        </Checkbox.Indicator>
      </Checkbox.Root>
    </DialogRow>
  );
};

export { DialogCheckbox };

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HMSRoomProvider } from '@100mslive/react-sdk';
import { HMSThemeProvider, Theme, ThemeTypes } from '@100mslive/react-ui';
import { createStitches } from '@stitches/react';

import { Auth0ProviderWithNavigate } from './components/auth/Auth0Provider';
import { AppData } from './hms/components/AppData';
import { shadeColor } from './hms/utils';
import { store } from './models/store';
import { App } from './App';
import { Color } from './constants';
import reportWebVitals from './reportWebVitals';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AlmerStitches = createStitches({
  prefix: 'hms-ui',
  theme: {
    colors: {
      brandDefault: Color.ALMER_GREEN,
      brandDark: shadeColor(Color.ALMER_GREEN, -30),
      brandLight: shadeColor(Color.ALMER_GREEN, 30),
      brandDisabled: shadeColor(Color.ALMER_GREEN, 10),
    },
  },
});


interface SessionStore {
  pinnedTrackId?: string;
  pinnedUserId?: string;
  usefulLinks: string[];
}

const TypedHMSRoomProvider = HMSRoomProvider<{sessionStore: SessionStore}>;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Auth0ProviderWithNavigate>
          <HMSThemeProvider
            themeType={ThemeTypes.light}
            theme={{
              colors: {
                ...AlmerStitches.theme.colors
              },
            } as Theme}
          >
            <TypedHMSRoomProvider>
              <AppData />
              <App />
            </TypedHMSRoomProvider>
          </HMSThemeProvider>
        </Auth0ProviderWithNavigate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  memo, MouseEvent, useCallback, useEffect,
useRef, useState} from 'react';
import { MicOffIcon } from '@100mslive/react-icons';
import {
  selectAudioTrackByPeerID,
  selectIsPeerAudioEnabled,
  selectLocalPeerID,
  selectPeerNameByID,
  selectVideoTrackByID,
  selectVideoTrackByPeerID,
  useHMSStore,
} from '@100mslive/react-sdk';
import {
  Avatar,
  StyledVideoTile,
  useBorderAudioLevel,
  Video,
  VideoTileStats,
} from '@100mslive/react-ui';

import { useUISettingsByKey } from '../../app-data/useUISettings';
import { HMS_UI_SETTINGS } from '../../constants';
import { TileConnection } from '../connection/TileConnection';

import { FadingLineDrawer, Hook } from "./fadingLineDrawer";
import { PeerMetadataTile } from './PeerMetadataTile';
import { TileMenu } from './TileMenu';
import { VideoTileLabel } from './VideoTileLabel';

type VideoTileProps = {
  peerId: string;
  trackId?: string;
  width?: number | string;
  height?: number | string;
  visible?: boolean;
  pointSender?: Hook | null;
};

function videoDimensions(video: HTMLVideoElement): { width: number; height: number } {
  const videoRatio = video.videoWidth / video.videoHeight
  let width = video.offsetWidth
  let height = video.offsetHeight
  const elementRatio = width/height

  if (elementRatio > videoRatio) {
    width = height * videoRatio
  } else {
    height = width / videoRatio
  }

  return {
    width: width,
    height: height
  }
}

function maybeExtractVideo(holder: HTMLDivElement | null): HTMLVideoElement | undefined {
  if (holder == null) {
    return;
  }
  const videoElements = holder.getElementsByTagName("video");

  if (!videoElements.length) {
    console.warn("Could not find video elements in the holder")
    return;
  }

  const video = videoElements.item(0)

  if (!(video instanceof HTMLVideoElement)) {
    console.warn("First child of holder is not video")
    return;
  }

  return video;
}

const Tile = ({
  peerId,
  trackId,
  width = '100%',
  height = '100%',
  visible = true,
  pointSender,
}: VideoTileProps) => {

  const trackSelector = trackId ? selectVideoTrackByID(trackId) : selectVideoTrackByPeerID(peerId);
  const videoTrack = useHMSStore(trackSelector);

  const peerName = useHMSStore(selectPeerNameByID(peerId));
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));
  const localPeerID = useHMSStore(selectLocalPeerID);

  const isAudioOnly = useUISettingsByKey(HMS_UI_SETTINGS.isAudioOnly);
  const mirrorLocalVideo = useUISettingsByKey(HMS_UI_SETTINGS.mirrorLocalVideo);
  const showStatsOnTiles = useUISettingsByKey(HMS_UI_SETTINGS.showStatsOnTiles);

  const isAudioMuted = !useHMSStore(selectIsPeerAudioEnabled(peerId));
  const isVideoMuted = !videoTrack?.enabled;

  const [isMouseHovered, setIsMouseHovered] = useState(false);

  const borderAudioRef = useBorderAudioLevel(audioTrack?.id);

  const isVideoDegraded = videoTrack?.degraded;
  const isLocal = localPeerID === peerId;

  const holderRef = useRef<HTMLDivElement>(null)
  const canvasElement = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const holder = holderRef.current;
    const video = maybeExtractVideo(holder)
    if (video == null) {
      return;
    }

    const canvas = canvasElement.current;

    if (video && canvas && pointSender) {
      const drawer = new FadingLineDrawer(canvas)

      const resize = (e: Event) => {
          const {width, height} = videoDimensions(video)
          canvas.width = width
          canvas.height = height
      }

      window.addEventListener('resize', resize)
      video.addEventListener('resize', resize)

      const {width, height} = videoDimensions(video)
      canvas.width = width
      canvas.height = height

      drawer.registerHook(pointSender)

      drawer.listen()

      return () => {
        video.removeEventListener('resize', resize)
        window.removeEventListener('resize', resize)
        drawer.stop()
      }
    }

  }, [holderRef.current, canvasElement.current, pointSender])

  const label = VideoTileLabel({
    peerName,
    videoTrack,
    audioTrack,
    isLocal,
  });

  const onHoverHandler = useCallback((event: MouseEvent<object>) => {
    setIsMouseHovered(event.type === 'mouseenter');
  }, []);

  return (
    <StyledVideoTile.Root
      css={{
        width,
        height,
        visibility: visible ? 'visible' : 'hidden',
      }}
      data-testid={`participant_tile_${peerName!}`}
      className="relative flex items-center justify-center"
    >
      {
        peerName !== undefined
          ? (
              <>
                <div className="pointer-box relative flex items-center justify-center" ref={holderRef}>
                  <StyledVideoTile.Container
                    onMouseEnter={onHoverHandler}
                    onMouseLeave={onHoverHandler}
                    ref={borderAudioRef}
                  >

                    {
                      showStatsOnTiles
                        ? (
                            <VideoTileStats
                              audioTrackID={audioTrack?.id}
                              videoTrackID={videoTrack?.id}
                              peerID={peerId}
                              isLocal={isLocal}
                            />
                          )
                        : null
                    }

                    {
                      videoTrack
                        ? (
                              <Video
                                trackId={videoTrack?.id}
                                attach={isLocal ? undefined : !isAudioOnly}
                                mirror={
                                  mirrorLocalVideo &&
                                  peerId === localPeerID &&
                                  videoTrack?.source === 'regular' &&
                                  videoTrack?.facingMode !== 'environment'
                                }
                                degraded={isVideoDegraded}
                                data-testid='participant_video_tile'
                              />
                          )
                        : null
                    }

                    {
                      isVideoMuted || isVideoDegraded || (!isLocal && isAudioOnly)
                        ? (
                            <StyledVideoTile.AvatarContainer>
                              <Avatar
                                name={peerName ?? ''}
                                data-testid='participant_avatar_icon'
                              />
                            </StyledVideoTile.AvatarContainer>
                          )
                        : null
                    }

                    {
                      isAudioMuted
                        ? (
                            <StyledVideoTile.AudioIndicator data-testid='participant_audio_mute_icon'>
                              <MicOffIcon />
                            </StyledVideoTile.AudioIndicator>
                          )
                        : null
                    }

                    {
                      isMouseHovered && audioTrack && videoTrack
                        ? (
                            <TileMenu
                              peerID={peerId}
                              audioTrackID={audioTrack.id}
                              videoTrackID={videoTrack.id}
                            />
                          )
                        : null
                    }

                    <PeerMetadataTile peerId={peerId} />

                    <TileConnection
                      hideLabel={false}
                      name={label}
                      peerId={peerId}
                      width={width}
                    />

                  </StyledVideoTile.Container>
                  {pointSender && <canvas
                      width={1}
                      height={1}
                      className="absolute cursor-pointer" ref={canvasElement}
                      onMouseEnter={onHoverHandler}
                      onMouseLeave={onHoverHandler}
                  />}
                </div>
              </>
            )
          : null
      }
    </StyledVideoTile.Root>
  );
};

const VideoTile = memo(Tile);

export { VideoTile };

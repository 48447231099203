import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {RealtimeService} from "../../services/realtime";
import { AsyncThunkStatus } from '../constants';
import type { RootState } from '../store';

import type { CallInvitation, CallInvitationsState } from './types';

const initialState: CallInvitationsState = {
    all: [],
    fetchAllStatus: AsyncThunkStatus.IDLE
};

// thunks
const fetchAllAsync = createAsyncThunk('callInvitations/fetchAllAsync', async () => {
    return await RealtimeService.sharedInstance.readCallInvitations();
});

// store slice
const callInvitationsSlice = createSlice({
    name: 'callInvitations',
    initialState,
    reducers: {
        appendCallInvitation: (state, {payload}: PayloadAction<CallInvitation>) => {

            state.notification = payload;
            state.all.push(payload);
        },
        clearNotification: (state) => {
            state.notification = undefined;
        }
    },
    extraReducers(builder) {

        builder.addCase(fetchAllAsync.pending, (state) => {
            state.fetchAllStatus = AsyncThunkStatus.STARTED;
        });

        builder.addCase(fetchAllAsync.fulfilled, (state, {payload}) => {

            state.all = payload.sort((a, b) => b.timestamp - a.timestamp);
            state.fetchAllStatus = AsyncThunkStatus.FULFILLED;
        });

        builder.addCase(fetchAllAsync.rejected, (state, {error}) => {

            console.error(error);
            state.fetchAllStatus = AsyncThunkStatus.REJECTED;
        });
    },
});

// actions
const {
    appendCallInvitation,
    clearNotification
} = callInvitationsSlice.actions;

// selects
const selectAllCallInvitations = (state: RootState) => state.callInvitations.all;
const selectNotification = (state: RootState) => state.callInvitations.notification;
const selectFetchAllStatus = (state: RootState) => state.callInvitations.fetchAllStatus;
const selectError = (state: RootState) => state.callInvitations.error;

// reducer
const reducer = callInvitationsSlice.reducer;

export {
    appendCallInvitation,
    callInvitationsSlice,
    clearNotification,
    reducer as default,
    fetchAllAsync,
    selectAllCallInvitations,
    selectError,
    selectFetchAllStatus,
    selectNotification
};

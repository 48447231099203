enum AsyncThunkStatus {
    IDLE = 'IDLE',
    STARTED = 'STARTED',
    FULFILLED = 'FULFILLED',
    REJECTED = 'REJECTED'
}

enum CallDirection {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING'
}

export { AsyncThunkStatus, CallDirection };

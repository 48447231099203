import { useCallback } from 'react';
import {
  selectIsLocalScreenShared,
  selectIsLocalVideoEnabled,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk';
import {
    Box, Flex, Slider, Text
} from '@100mslive/react-ui';

import { useSetUiSettingsByKey } from '../../app-data/useUISettings';
import {
  HMS_UI_MODE_ACTIVE_SPEAKER,
  HMS_UI_MODE_GRID,
  HMS_UI_SETTINGS
} from '../../constants';

import { SwitchWithLabel } from './SwitchWithLabel';
import { settingOverflow } from './utils';

const LayoutSettings = () => {

  const hmsActions = useHMSActions();

  const isLocalVideoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const isLocalScreenShared = useHMSStore(selectIsLocalScreenShared);

  const [isAudioOnly = false, setIsAudioOnly] = useSetUiSettingsByKey(HMS_UI_SETTINGS.isAudioOnly);
  const toggleIsAudioOnly = useCallback(async (isAudioOnlyModeOn: boolean) => {

    if (isAudioOnlyModeOn) {

      // turn off video and screen share if user switches to audio only mode
      isLocalVideoEnabled && (await hmsActions.setLocalVideoEnabled(false));
      isLocalScreenShared && (await hmsActions.setScreenShareEnabled(false));
    }
    setIsAudioOnly(isAudioOnlyModeOn);

  }, [hmsActions, isLocalVideoEnabled, isLocalScreenShared, setIsAudioOnly]);

  const [uiViewMode = HMS_UI_MODE_GRID, setUiViewMode] = useSetUiSettingsByKey(HMS_UI_SETTINGS.uiViewMode);
  const [mirrorLocalVideo = true, setMirrorLocalVideo] = useSetUiSettingsByKey(HMS_UI_SETTINGS.mirrorLocalVideo);
  const [maxTileCount = 9, setMaxTileCount] = useSetUiSettingsByKey(HMS_UI_SETTINGS.maxTileCount);

  return (
    <Box className={settingOverflow()}>

      <SwitchWithLabel
        checked={uiViewMode === HMS_UI_MODE_ACTIVE_SPEAKER}
        onChange={(value) => {
          setUiViewMode(value ? HMS_UI_MODE_ACTIVE_SPEAKER : HMS_UI_MODE_GRID);
        }}
        id='activeSpeakerMode'
        label='Active Speaker Mode'
      />

      <SwitchWithLabel
        label='Audio Only Mode'
        id='audioOnlyMode'
        checked={isAudioOnly}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={toggleIsAudioOnly}
      />

      <SwitchWithLabel
        label='Mirror Local Video'
        id='mirrorMode'
        checked={mirrorLocalVideo}
        onChange={setMirrorLocalVideo}
      />

      <Flex
        align='center'
        css={{ w: '100%', my: '$2', py: '$8', '@md': { display: 'none' } }}
      >

        <Text variant='md' css={{ fontWeight: '$semiBold' }}>
          Tiles In View({maxTileCount})
        </Text>

        <Flex justify='end' css={{ flex: '1 1 0' }}>
          <Slider
            step={1}
            value={[maxTileCount]}
            min={1}
            max={49}
            onValueChange={e => {
              setMaxTileCount(e[0]);
            }}
            css={{ w: '70%' }}
          />
        </Flex>

      </Flex>
    </Box>
  );
};

export { LayoutSettings };

import { HandRaiseIcon } from '@100mslive/react-icons';
import { selectPeerMetadata, useHMSStore } from '@100mslive/react-sdk';
import { Flex } from '@100mslive/react-ui';

import { PeerMetadata } from '../../types';
import { ConnectionIndicator } from '../connection/ConnectionIndicator';

type ParticipantActionsProps = {
  peerId: string;
  role?: string;
  onAction?: () => void;
};

const ParticipantActions = ({ peerId }: ParticipantActionsProps) => {

  const isHandRaised = useHMSStore<PeerMetadata>(selectPeerMetadata(peerId))?.isHandRaised;

  return (
    <Flex align='center' css={{ flexShrink: 0 }}>
      <ConnectionIndicator peerId={peerId} />
      { isHandRaised && <HandRaiseIcon /> }
    </Flex>
  );
};

export { ParticipantActions };

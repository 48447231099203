import { Fragment } from 'react';
import type { HMSPeer } from '@100mslive/react-sdk';
import {
  Avatar,
  Flex,
  Text,
  textEllipsis,
} from '@100mslive/react-ui';

import { ParticipantActions } from './ParticipantActions';


type ParticipantProps = {
  peer: HMSPeer;
  isConnected: boolean;
}

const Participant = ({
  peer, isConnected
}: ParticipantProps) => {

  return (
    <Fragment>
      <Flex
        key={peer.id}
        css={{ w: '100%', py: '$4', pr: '$10' }}
        align='center'
        data-testid={'participant_' + peer.name}
      >

        <Avatar
          name={peer.name}
          css={{
            position: 'unset',
            transform: 'unset',
            mr: '$8',
            fontSize: '$sm',
            size: '$12',
            p: '$4',
          }}
        />

        <Flex direction='column' css={{ flex: '1 1 0' }}>
          <Text
            variant='md'
            css={{ ...textEllipsis(150), fontWeight: '$semiBold' }}
          >
            {peer.name}
          </Text>
          <Text variant='sub2'>
            {peer.roleName}
          </Text>
        </Flex>

        { isConnected && (
          <ParticipantActions
            peerId={peer.id}
            role={peer.roleName}
          />
        ) }

      </Flex>
    </Fragment>
  );
};

export { Participant };

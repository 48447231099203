import {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { BellIcon } from '@heroicons/react/24/outline';
import { BellAlertIcon } from '@heroicons/react/24/solid';

import { DropDownMenu } from './DropDownMenu';
import NoNewNotificationsToast from "./NoNewNotificationsToast";
import { NavBarRightContainerProps } from './types';

function NavBarRightContainer({
  groupCallButtonTitle,
  dropDownMenu,
  hasNotification,
  notificationRoute,
  onGroupCallButtonAction
}: NavBarRightContainerProps) {

  const navigate = useNavigate();
  const [showNavToast, setShowNavToast] = useState(false);

  useEffect(() => {
    const hideToast = () => {
      setShowNavToast(false);
    }
    const timeout = showNavToast ? setTimeout(hideToast, 3000) : null;

    if (showNavToast) {
      window.addEventListener('click', hideToast);
    }

    return () => {
      window.removeEventListener('click', hideToast);
      if (timeout) clearTimeout(timeout);
    }
  }, [showNavToast])

  return (
    <div className='flex items-center'>

      <NoNewNotificationsToast show={showNavToast}/>

      <div className='flex-shrink-0'>
        <button
          type='button'
          className='relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          onClick={onGroupCallButtonAction}>
          <PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
          <span>{groupCallButtonTitle}</span>
        </button>
      </div>

      <div className='hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center'>
        <button
          type='button'
          className='rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        >
          <span className='sr-only'>View notifications</span>
          {
            hasNotification
              ? <BellAlertIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' onClick={() => navigate(notificationRoute)} />
              : <BellIcon className='h-6 w-6' aria-hidden='true' onClick={(event) => {
                  setShowNavToast(!showNavToast);
                  event.stopPropagation();
                }} />
          }
        </button>

        <DropDownMenu
          {...dropDownMenu}
        />
      </div>

    </div>
  );
}

export { NavBarRightContainer };

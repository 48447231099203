import type { CSSProperties, ReactElement } from 'react';
import { Flex } from '@100mslive/react-ui';

type DialogRowProps = {
  children: ReactElement | ReactElement[];
  breakSm?: boolean;
  css?: CSSProperties;
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
};

/**
 * a row of items which breaks into column on small screen. For e.g. title on left and options to select
 * from on right for select component.
 */
const DialogRow = ({
  children,
  breakSm = false,
  css,
  justify = 'between',
}: DialogRowProps) => {

  let finalCSS = {
    margin: '$10 0',
    w: '100%',
  };

  if (breakSm) {

    (finalCSS as Record<string, any>)['@sm'] = {
      flexDirection: 'column',
      alignItems: 'flex-start',
    };
  }

  if (css) {
    finalCSS = Object.assign(finalCSS, css);
  }

  return (
    <Flex align='center' justify={justify} css={finalCSS}>
      {children}
    </Flex>
  );
};

export { DialogRow };

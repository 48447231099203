import { Flex, VerticalDivider } from '@100mslive/react-ui';

import { ParticipantCount } from '../participants/ParticipantCount';

import { Logo, SpeakerTag } from './HeaderComponents';
import { StreamActions } from './StreamActions';

type ConferencingHeaderProps = {
  isPreview?: boolean;
};

const ConferencingHeader = ({
  isPreview = false
}: ConferencingHeaderProps) => {

  return (
    <Flex
      justify='between'
      align='center'
      css={{ position: 'relative', height: '100%' }}
    >

      <Flex align='center' css={{ position: 'absolute', left: '$10' }}>
        <Logo />
        <VerticalDivider css={{ ml: '$8' }} />
        { !isPreview ? <SpeakerTag /> : null }
      </Flex>

      <Flex
        align='center'
        css={{
          position: 'absolute',
          right: '$10',
          gap: '$4',
        }}
      >
        {/*FIXME: StreamActions only gets enabled when audio only mode is set, and then presents no options;
        if we find out why it's used or how to make it work for us, we can reintroduce it;
        until then it serves no purpose*/}
        {/*<StreamActions />*/}
        <ParticipantCount />
      </Flex>

    </Flex>
  );
};

export { ConferencingHeader };

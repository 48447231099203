import { useHMSActions } from '@100mslive/react-sdk';
import { Button, Dialog } from '@100mslive/react-ui';

import { DialogContent } from '../../common/dialog/DialogContent';
import { DialogRow } from '../../common/dialog/DialogRow';

type ScreenshareHintModalProps = {
  onClose: () => void;
};

const ScreenshareHintModal = ({
  onClose
}: ScreenshareHintModalProps) => {

  const hmsActions = useHMSActions();

  return (
    <Dialog.Root defaultOpen onOpenChange={value => !value && onClose()}>
      <DialogContent title='AudioOnly Screenshare'>
        <DialogRow justify='end'>
          <Button
            variant='primary'
            onClick={() => {
              hmsActions
                .setScreenShareEnabled(true, {
                  audioOnly: true,
                  displaySurface: 'browser',
                })
                .catch(console.error);
              onClose();
            }}
            data-testid='audio_screenshare_continue'
          >
            Continue
          </Button>
        </DialogRow>
      </DialogContent>
    </Dialog.Root>
  );
};

export { ScreenshareHintModal };

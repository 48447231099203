import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoute } from '../constants';
import { PostLeaveScreen } from '../hms/components/PostLeaveScreen';
import { useAppDispatch } from '../models/hooks';
import { leaveRoom } from '../models/rooms/roomsSlice';

function CallLeavePage() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {

    dispatch(leaveRoom());
    setTimeout(() => navigate(AppRoute.ROOT), 3000);

  }, [dispatch, navigate]);

  return <PostLeaveScreen />;
}

export { CallLeavePage };

import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import {AppRoute} from "../constants";

function NotFoundPage() {
    const navigate = useNavigate();

    const [remainingTime, setRemainingTime] = useState(3);

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(prevRemainingTime => prevRemainingTime - 1);
        }, 1000);

        if (remainingTime === 0) {
            navigate(AppRoute.ROOT)
        }

        return () => clearInterval(timer);
    }, [remainingTime]);

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">Page Not Found</h1>
            <p className="text-lg font-medium text-gray-700 mb-2">Sorry, the page you are looking for could not be found.</p>
            <p className="text-lg font-medium text-gray-700 mb-4">You will be redirected to the home page in {remainingTime} seconds.</p>
        </div>
    );
}

export { NotFoundPage };

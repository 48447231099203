import { useCallback, useState } from 'react';
import {
  selectLocalPeerID,
  selectPeerMetadata,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk';

import { PeerMetadata } from '../types';

const useMetadata = () => {

    const hmsActions = useHMSActions();
    const localPeerId = useHMSStore(selectLocalPeerID);
    const metadata = useHMSStore<PeerMetadata>(selectPeerMetadata(localPeerId));

    const [isHandRaised, setHandRaised] = useState(metadata?.isHandRaised ?? false);
    const [isBRBOn, setBRBOn] = useState(metadata?.isBRBOn ?? false);

    const update = useCallback(async (updatedFields: Partial<PeerMetadata>) => {

        try {

            await hmsActions.changeMetadata(Object.assign(metadata, updatedFields));
            return true;

        } catch (error) {
            console.error('failed to update metadata ', metadata, updatedFields);
        }

    }, [hmsActions, metadata]);

    const toggleHandRaise = useCallback(async () => {

        const brbUpdate = !isHandRaised ? false : isBRBOn;
        const success = await update({
            isHandRaised: !isHandRaised,
            isBRBOn: brbUpdate,
        });
        if (success) {

            setBRBOn(brbUpdate);
            setHandRaised(!isHandRaised);
        }

    }, [isHandRaised, isBRBOn, update]);

    const toggleBRB = useCallback(async () => {

        const handRaiseUpdate = !isBRBOn ? false : isHandRaised;
        const success = await update({
            isHandRaised: handRaiseUpdate,
            isBRBOn: !isBRBOn,
        });
        if (success) {

            setBRBOn(!isBRBOn);
            setHandRaised(handRaiseUpdate);
        }

    }, [isBRBOn, isHandRaised, update]);

  return {
    isHandRaised,
    isBRBOn,
    metadata,
    updateMetadata: update,
    toggleHandRaise,
    toggleBRB,
  };
};

export { useMetadata };

import { IconButton, styled } from '@100mslive/react-ui';

const LeaveIconButton = styled(IconButton, {
    color: '$white',
    h: '$14',
    px: '$8',
    r: '$1',
    bg: '$error',
    '&:not([disabled]):hover': {
        bg: '$errorTint',
    },
    '&:not([disabled]):active': {
        bg: '$errorTint',
    },
    '@md': {
        px: '$4',
        mx: 0,
    },
});

const MenuTriggerButton = styled(LeaveIconButton, {
    borderLeft: '1px solid $errorDark',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    px: '$3',
    '@md': {
        px: '$2',
    },
});

export { LeaveIconButton, MenuTriggerButton };

import Ably from "ably/callbacks";
import {Types} from "ably/promises";

import {NormalizedPoint} from "../hms/components/conference/fadingLineDrawer";
import {CallInvitation} from "../models/call-invitations/types";
import {Device, PresenceInfo, Status} from "../models/users/constants";

import {InboxMessageT, MessageType, PubNubPresenceState} from "./realtime/types";
import {RealtimeRoom, RealtimeService} from "./realtime";

class AblyRealtimeRoom extends RealtimeRoom {
    private channel: Types.RealtimeChannelPromise;
    constructor(ably: Types.RealtimePromise,roomId: string, orgId: string) {
        super(roomId, orgId);

        this.channel = ably.channels.get(this.channelForRoom,{modes: ['PUBLISH','SUBSCRIBE']})
    }
    protected sendPoints(points: NormalizedPoint[]): void {
        this.channel.publish('points', points).catch((e: Error) => {
            console.error("point fail", e);
        });
    }
}

class AblyService extends RealtimeService {
    realtimeRoom(roomId: string): RealtimeRoom {
        return new AblyRealtimeRoom(this.ably, roomId, this.orgId);
    }

    private presence: Types.RealtimePresencePromise;
    private inbox: Types.RealtimeChannelPromise;
    private ably: Types.RealtimePromise;
    private pointsChannel: Types.RealtimeChannelPromise;

    constructor(userId: string, orgId: string, name: string, email: string) {
        super(userId, orgId, name, email);
        this.ably = new Ably.Realtime.Promise({
            key: 'obRwHA.zEeEow:ax2GfHG1p1S6X9OwM_RRq5HJKjVbbwBpth71mjC5X0I',
            clientId: userId,
        });

        this.inbox = this.ably.channels.get(this.inboxChannel, {modes: ['SUBSCRIBE']})
        this.presence = this.ably.channels.get(this.presenceChannel, {modes: ['PRESENCE_SUBSCRIBE', 'PRESENCE']}).presence

        this.pointsChannel = this.ably.channels.get('points');


        // todo add proper handlers
        void this.presence.enter()

        void this.presence.subscribe((message) => {
            let status: Status;
            const device = (message.data as PubNubPresenceState | undefined)?.device ?? Device.DESKTOP
            switch (message.action) {
                case "present":
                case "enter":
                    status = Status.AVAILABLE
                    break;
                case "leave":
                case "absent":
                    status = Status.OFFLINE
                    break;
                case "update":
                    status = (message.data as PubNubPresenceState | undefined)?.inCall ? Status.IN_CALL : Status.AVAILABLE
                    break;
            }
            this.onPresence({hashedId: message.clientId, status, device})
        });

        void this.inbox.subscribe(message => {
            switch (message.name as MessageType) {
                case MessageType.CALL_INVITATION:
                    this.onCallInvitation(message.data as CallInvitation);
                    break;
                default:
                    console.error("Unkown type", message);
            }
        })

        void this.setState(Status.AVAILABLE)
    }

    cleanup(): void {
        this.ably.close()
    }

    sendTo(userId: string, message: InboxMessageT): Promise<void> {
        return this.ably.channels
            .get(this.inboxChannelForRecipient(userId), {modes: ['PUBLISH']})
            .publish(message.type, message.payload)
    }

    async readInbox(): Promise<InboxMessageT[]> {
        const history = await this.inbox.history({limit: 50});

        return history.items.map(e => {
            return {
                type: e.name,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                payload: e.data
            } as InboxMessageT
        })
    }

    async readPresence(): Promise<Map<string, PresenceInfo>> {
        const p = await this.presence.get();

        return new Map(p.map(e => [e.clientId, {
            status: Status.AVAILABLE,
            device: (e.data as PubNubPresenceState | undefined)?.device ?? Device.DESKTOP
        }]));
    }

    protected actuallySetState(state: PubNubPresenceState): Promise<void> {
        return this.presence.update(state)
    }
}

export {
    AblyService
}

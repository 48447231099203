import {PresenceEvent} from "pubnub";

import {CallInvitation} from "../../models/call-invitations/types";
import {Device} from "../../models/users/constants";
import {DecoratedUser} from "../../models/users/types";

type UserPresence = Pick<DecoratedUser, 'hashedId' | 'status' | 'device'>;

interface RealtimeMessage<T, Type extends MessageType> {
    type: Type;
    payload: T
}


enum MessageType {
    CALL_INVITATION = 'call-invitation',
    // CALL_HISTORY = 'call-history',
    // CALL_REPLY = 'call-reply'
}


type CallInvitationMessage = RealtimeMessage<CallInvitation, MessageType.CALL_INVITATION>

type InboxMessageT = CallInvitationMessage

type PubNubPresenceState = {
    inCall: boolean;
    device: Device;
    name: string;
    email: string;
}

type PubNubPresenceResponse = {
    uuid: string;
    state?: PubNubPresenceState;
}

interface PubNubPresenceEvent extends PresenceEvent {
    state?: PubNubPresenceState;
}

export {MessageType}

export type {
    CallInvitationMessage,
    InboxMessageT,
    PubNubPresenceEvent,
    PubNubPresenceResponse,
    PubNubPresenceState,
    RealtimeMessage,
    UserPresence
};

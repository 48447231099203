import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import callInvitationsReducer from './call-invitations/callInvitationsSlice';
import roomsReducer from './rooms/roomsSlice';
import usersReducer from './users/usersSlice';

export const store = configureStore({
    reducer: {
        callInvitations: callInvitationsReducer,
        rooms: roomsReducer,
        users: usersReducer,
        // recentCalls: recentCallsReducer,
        // pendingInvitations: pendingInvitationsReducer
    },
// FIXME: fixes non-serializable errors in console; will need to be fixed differently
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

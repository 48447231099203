import { Menu } from '@headlessui/react';

import { MenuItemProps } from './types';

function joinClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function MenuItem({ title, onClick }: MenuItemProps) {

  return (
    <Menu.Item>
      {({ active }) => (
        <a
          href='#'
          className={joinClassNames(
            active ? 'bg-gray-100' : '',
            'block px-4 py-2 text-sm text-gray-700'
          )}
          onClick={onClick}
        >
          {title}
        </a>
      )}
    </Menu.Item>
  );
}

export { MenuItem };

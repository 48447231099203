import {useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';

import type {ListItemGroups} from '../components/common/list/List';
import {List} from '../components/common/list/List';
import {ListItemAction, ListItemProps} from '../components/common/list/ListItem';
import {Spinner} from '../components/common/Spinner';
import {iconForDevice, iconForStatus, textForDevice, textForStatus} from "../components/constants";
import {AppRoute, DEFAULT_ERROR_MESSAGE, EmptyPlaceholder, SearchPlaceholder} from '../constants';
import {AsyncThunkStatus} from '../models/constants';
import {useAppDispatch, useAppSelector} from '../models/hooks';
import {createRoomAsync, selectCreateRoomStatus} from '../models/rooms/roomsSlice';
import {Status} from '../models/users/constants';
import type {DecoratedUser} from '../models/users/types';
import {selectAllUsers, selectError, selectFetchAllStatus,} from '../models/users/usersSlice';

enum Section {
  ONLINE = 'Online',
  OFFLINE = 'Offline'
}

function buildViewModel(user: DecoratedUser, online: boolean): ListItemProps {

  return {
    id: user.id,
    avatar: user.avatar,
    title: user.name,
    subtitle: online ? textForDevice(user.device) : undefined,
    subtitleIcon: online ? iconForDevice(user.device) : undefined,
    secondColumnTitle: 'Status',
    secondColumnSubtitle: textForStatus(user.status),
    secondColumnSubtitleIcon: iconForStatus(user.status),
    action: ListItemAction.CALL
  };
}

function buildViewModels(users: DecoratedUser[]): ListItemGroups {

  const viewModels: ListItemGroups = {
    [Section.ONLINE]: [],
    [Section.OFFLINE]: []
  };

  users.forEach((user) => {

    switch (user.status) {

      case Status.AVAILABLE: 
      case Status.IN_CALL: {
        viewModels[Section.ONLINE].push(buildViewModel(user, true));
      } break;

      case Status.OFFLINE: {
        viewModels[Section.OFFLINE].push(buildViewModel(user, false));
      } break;
    }
  });

  return viewModels;
}

function TeamPage() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const allUsers = useAppSelector(selectAllUsers);
  const fetchAllStatus = useAppSelector(selectFetchAllStatus);
  const createRoomStatus = useAppSelector(selectCreateRoomStatus);

  const error = useAppSelector(selectError);

  const hasPendingFetch = fetchAllStatus === AsyncThunkStatus.IDLE || fetchAllStatus === AsyncThunkStatus.STARTED;
  if (hasPendingFetch && !allUsers.length) {
    return <Spinner />;
  }

  if (fetchAllStatus === AsyncThunkStatus.REJECTED) {
    return <p>{ error?.message || DEFAULT_ERROR_MESSAGE }</p>;
  }

  return (
    <List
      datasource={{ groupedItems: buildViewModels(allUsers) }}
      stickyHeaders={true}
      stickySearchBar={true}
      searchable={true}
      searchPlaceholder={SearchPlaceholder.TEAM}
      emptyPlaceholder={EmptyPlaceholder.TEAM}
      onItemAction={(item) => {
        (async () => {
          // clicking multiple times doesn't break the room anymore
          if (createRoomStatus !== AsyncThunkStatus.IDLE) return;
          const accessToken = await getAccessTokenSilently();
          await dispatch(createRoomAsync({
            accessToken,
            participants: [item.id]
          })).unwrap().then(res => {
            navigate(AppRoute.CALL + "/" + res[0].id);
          });

        })().catch(console.error);
      }}
    />
  );
}

export { TeamPage };

import { List } from '../components/common/list/List';
import {CallIcon, ListItemAction, ListItemProps} from '../components/common/list/ListItem';
import { EmptyPlaceholder, SearchPlaceholder } from '../constants';
import { getFormattedDuration } from '../utils';

const datasource: ListItemProps[] = [
  {
    id: String(1),
    title: 'Leslie Abbott',
    action: ListItemAction.INFO,
    subtitle: '(+ 11 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.now() - 10000).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(356),
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(2),
    title: 'Hector Adams',
    action: ListItemAction.INFO,
    subtitle: '(+ 4 others)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.now() - 1000).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(751),
    avatar:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(3),
    title: 'Blake Alexander',
    action: ListItemAction.INFO,
    subtitle: '(+ 7 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.now() + 1000).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(100),
    avatar:
      'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(4),
    title: 'Fabricio Andrews',
    action: ListItemAction.INFO,
    subtitle: '(+ 2 others)',
    subtitleIcon: CallIcon.REJECTED_CALL,
    secondColumnTitle: new Date(Date.now() + 10000).toLocaleString(),
    avatar:
      'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(5),
    title: 'Angela Beaver',
    action: ListItemAction.INFO,
    subtitle: '(+ 11 others)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.now() + 15000).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1001),
    avatar:
      'https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(6),
    title: 'Yvette Blanchard',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1021),
    avatar:
      'https://images.unsplash.com/photo-1506980595904-70325b7fdd90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(7),
    title: 'Lawrence Brooks',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.REJECTED_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    avatar:
      'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(8),
    title: 'Jeffrey Clark',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1152),
    avatar:
      'https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(9),
    title: 'Kathryn Cooper',
    action: ListItemAction.INFO,
    subtitle: '(+ 7 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(192),
    avatar:
      'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(10),
    title: 'Alicia Edwards',
    action: ListItemAction.INFO,
    subtitle: '(+ 7 others)',
    subtitleIcon: CallIcon.REJECTED_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    avatar:
      'https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(11),
    title: 'Benjamin Emerson',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(167),
    avatar:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(12),
    title: 'Jillian Erics',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1345),
    avatar:
      'https://images.unsplash.com/photo-1504703395950-b89145a5425b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(13),
    title: 'Chelsea Evans',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1234),
    avatar:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(14),
    title: 'Michael Gillard',
    action: ListItemAction.INFO,
    subtitle: '(+ 3 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1782),
    avatar:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(15),
    title: 'Dries Giuessepe',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(154),
    avatar:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(16),
    title: 'Jenny Harrison',
    action: ListItemAction.INFO,
    subtitle: '(+ 2 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1023),
    avatar:
      'https://images.unsplash.com/photo-1507101105822-7472b28e22ac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(17),
    title: 'Lindsay Hatley',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.REJECTED_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    avatar:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(18),
    title: 'Anna Hill',
    action: ListItemAction.INFO,
    subtitle: '(1 to 1)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(421),
    avatar:
      'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(19),
    title: 'Courtney Samuels',
    action: ListItemAction.INFO,
    subtitle: '(+ 2 others)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(450),
    avatar:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(20),
    title: 'Tom Simpson',
    action: ListItemAction.INFO,
    subtitle: '(+ 8 others)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(510),
    avatar:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(21),
    title: 'Floyd Thompson',
    action: ListItemAction.INFO,
    subtitle: '(+ 9 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(145),
    avatar:
      'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(22),
    title: 'Leonard Timmons',
    action: ListItemAction.INFO,
    subtitle: '(+ 5 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(125),
    avatar:
      'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(23),
    title: 'Whitney Trudeau',
    action: ListItemAction.INFO,
    subtitle: '(+ 2 others)',
    subtitleIcon: CallIcon.OUTGOING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(600),
    avatar:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(24),
    title: 'Kristin Watson',
    action: ListItemAction.INFO,
    subtitle: '(+ 4 others)',
    subtitleIcon: CallIcon.REJECTED_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    avatar:
      'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: String(25),
    title: 'Emily Wilson',
    action: ListItemAction.INFO,
    subtitle: '(+ 3 others)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(1250),
    avatar:
      'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },

  {
    id: String(26),
    title: 'Emma Young',
    action: ListItemAction.INFO,
    subtitle: '(+ 2 others)',
    subtitleIcon: CallIcon.INCOMING_CALL,
    secondColumnTitle: new Date(Date.UTC(2012, 11, 20, 3, 0, 0)).toLocaleString(),
    secondColumnSubtitle: getFormattedDuration(55),
    avatar:
      'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
]

function RecentCallsPage() {

  return (
    <List
      datasource={{ flatItems: datasource }}
      stickySearchBar={true}
      searchable={true}
      searchPlaceholder={SearchPlaceholder.RECENT_CALLS}
      emptyPlaceholder={EmptyPlaceholder.RECENT_CALLS}
      onItemAction={(item) => {
        // console.log(`Did tap INFO button for ${item.title}`);
      }}
    />
  );
}

export { RecentCallsPage };

import { Disclosure } from '@headlessui/react';

import { NavBarItemProps } from './types';

const SELECTED_CLASS = 'block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700 sm:pl-5 sm:pr-6';
const DEFAULT_CLASS = 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6';

function NavBarItemDisclosure({ isSelected, title, onClick }: NavBarItemProps) {

  return (
    <Disclosure.Button
      className={ isSelected ? SELECTED_CLASS : DEFAULT_CLASS }
      as='a'
      href='#'
      onClick={onClick}
    >
      { title }
    </Disclosure.Button>
  );
}

export { NavBarItemDisclosure };

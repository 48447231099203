import { PhoneIcon } from '@heroicons/react/24/solid';

type CallButtonProps = {
  onClick: () => void;
};

function CallButton({ onClick }: CallButtonProps) {

  return (
    <button
      className='text-green-500 hover:text-green-700 active:text-green-900'
      onClick={() => onClick()}
    >
      <PhoneIcon className='h-6 w-6' aria-hidden='true' />
    </button>
  );
}

export { CallButton };
export type { CallButtonProps };

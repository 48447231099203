import {
    Box, Flex, Text, textEllipsis
} from '@100mslive/react-ui';

import { useAppSelector } from '../../models/hooks';
import { selectWhoami } from '../../models/users/usersSlice';

import { ConferencingHeader } from './header/ConferencingHeader';

const PostLeaveScreen = () => {

  const whoami = useAppSelector(selectWhoami);

  return (
    <Flex direction='column' css={{ size: '100%' }}>
      <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid='header'>
        <ConferencingHeader isPreview />
      </Box>
      <Flex
        justify='center'
        direction='column'
        align='center'
        css={{ bg: '$mainBg', flex: '1 1 0', position: 'relative' }}
      >
        <Text variant='h2' css={{ fontWeight: '$semiBold' }}>
          👋
        </Text>
        <Text
          variant='h4'
          css={{ color: '$textHighEmp', fontWeight: '$semiBold', mt: '$12' }}
        >
          You left the room
        </Text>
        <Text
          variant='body1'
          css={{
            color: '$textMedEmp',
            mt: '$8',
            fontWeight: '$regular',
            textAlign: 'center',
          }}
        >
          Have a nice day
          {whoami?.name && (
            <Box as='span' css={{ ...textEllipsis(100) }}>
              , {whoami.name}
            </Box>
          )}
          !
        </Text>
      </Flex>
    </Flex>
  );
};

export { PostLeaveScreen };

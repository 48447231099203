import type { HMSPeer } from '@100mslive/react-sdk';
import { Box } from '@100mslive/react-ui';

import { VideoTile } from '../../components/conference/VideoTile';

type LargeTilePeerViewProps = {
  peerScreenSharing: HMSPeer;
};

const LargeTilePeerView = ({
  peerScreenSharing
}: LargeTilePeerViewProps) => {

  return peerScreenSharing ? (
    <Box
      css={{
        flex: '1 1 0',
        minHeight: '25%',
        py: '$4',
        '@lg': {
          mr: '$4',
          minHeight: 'unset',
          py: 0,
        },
        '@sm': {
          height: '100%',
          maxHeight: '75%',
          alignSelf: 'center',
        },
      }}
    >
      <VideoTile
        width='100%'
        height='100%'
        peerId={peerScreenSharing.id}
      />
    </Box>
  ) : null;
};

export { LargeTilePeerView };

import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Box} from "@100mslive/react-ui";
import {useAuth0} from "@auth0/auth0-react";

import {Spinner} from "../components/common/Spinner";
import {AppRoute} from "../constants";
import {ConferenceScreen} from '../hms/components/ConferenceScreen';
import {KeyboardHandler} from '../hms/components/input/KeyboardHandler'
import {PreviewScreen} from "../hms/components/PreviewScreen";
import {CallDirection} from "../models/constants";
import {useAppDispatch, useAppSelector} from "../models/hooks";
import {
    createRoomTokenAsync,
    selectActiveRoom,
    selectActiveRoomToken,
    selectCallDirection
} from "../models/rooms/roomsSlice";
import {Status as UserStatus} from '../models/users/constants';
import {selectWhoami} from "../models/users/usersSlice";
import {RealtimeService} from "../services/realtime";

function CallPage() {

  const navigate = useNavigate();
  const callDirection = useAppSelector(selectCallDirection);
  const activeRoom = useAppSelector(selectActiveRoom);
  const activeRoomToken = useAppSelector(selectActiveRoomToken);
  const whoami = useAppSelector(selectWhoami);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { roomId } = useParams();

  useEffect(() => {
    if (!roomId) {
        navigate(AppRoute.CATCH_ALL);
        return;
    }

    (async () => {
      if (!activeRoom && !activeRoomToken) {
        const accessToken = await getAccessTokenSilently();
        await dispatch(
          createRoomTokenAsync({
              accessToken,
              roomId: roomId,
          })
        ).unwrap();
      }
    })().catch(console.error);

  }, [activeRoomToken, activeRoom, getAccessTokenSilently, roomId]);

  const [joined, setJoined] = useState(false);

  useEffect(() => {
    void RealtimeService.sharedInstance.setState(UserStatus.IN_CALL);

    return() => {
      void RealtimeService.sharedInstance?.setState(UserStatus.AVAILABLE);
    }
  }, []);

  if (!whoami) {
    return <Spinner />
  }

  return joined ? <>
      <KeyboardHandler/>
      <ConferenceScreen onReconnect={() => setJoined(false)} />
  </> : <Box
          css={{
              bg: '$mainBg',
              w: '100%',
              h: '100%'
          }}
      >
          <KeyboardHandler/>
          <PreviewScreen
              token={activeRoomToken!}
              onJoin={() => {

                  if (callDirection === CallDirection.INCOMING) {
                      setJoined(true);
                      return;
                  }

                  (async () => {

                      await RealtimeService.sharedInstance.publishCallInvitation({
                          roomId: activeRoom!.id,
                          sender: whoami.id,
                          recipients: activeRoom!.participants,
                          timestamp: Date.now()
                      });
                      setJoined(true);

                  })().catch(console.error);
              }}
              skipPreview={false}
              initialName={whoami.name}
          />
      </Box>
}

export { CallPage };

import { Room } from '@almer/almer-beam-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {RealtimeService} from "../../services/realtime";
import { createRoom } from '../../services/rooms/create';
import { createRoomToken } from '../../services/rooms/token';
import { AsyncThunkStatus, CallDirection } from '../constants';
import type { RootState } from '../store';

import type { RoomsState } from './types';

const initialState: RoomsState = {
    createRoomStatus: AsyncThunkStatus.IDLE,
    createRoomTokenStatus: AsyncThunkStatus.IDLE,
};

type CreateRoomPayload = {
    accessToken: string;
    participants: string[];
};

type CreateRoomTokenPayload = {
    accessToken: string;
    roomId: string;
};

// thunks
const createRoomAsync = createAsyncThunk(
    'rooms/createAsync',
    async ({
        accessToken, participants
    }: CreateRoomPayload): Promise<[Room, string]> => {

        const room = await createRoom(accessToken);
        room.participants = participants;

        const roomToken = await createRoomToken(accessToken, room.id);

        return [room, roomToken];
    }
);

const createRoomTokenAsync = createAsyncThunk(
    'rooms/createTokenAsync',
    async ({
        accessToken, roomId
    }: CreateRoomTokenPayload): Promise<string> => {

        const roomToken = await createRoomToken(accessToken, roomId);
        return roomToken;
    }
);

// store slice
const roomsSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        leaveRoom(state) {

            state.callDirection = undefined;
            state.activeRoom = undefined;
            state.activeRoomToken = undefined;
            state.createRoomStatus = AsyncThunkStatus.IDLE;
            state.createRoomTokenStatus = AsyncThunkStatus.IDLE;
        }
    },
    extraReducers(builder) {

        builder.addCase(createRoomAsync.pending, (state) => {
            state.createRoomStatus = AsyncThunkStatus.STARTED;
        });

        builder.addCase(createRoomAsync.fulfilled, (state, { payload }) => {

            const [room, roomToken] = payload;

            state.callDirection = CallDirection.OUTGOING;
            state.activeRoom = room;
            state.activeRoomToken = roomToken;
            state.createRoomStatus = AsyncThunkStatus.FULFILLED;
            state.activeRoomService = RealtimeService.sharedInstance.realtimeRoom(room.id);
        });

        builder.addCase(createRoomAsync.rejected, (state, { error }) => {

            console.error(error);
            state.createRoomStatus = AsyncThunkStatus.REJECTED;
        });

        builder.addCase(createRoomTokenAsync.pending, (state) => {
            state.createRoomTokenStatus = AsyncThunkStatus.STARTED;
        });

        builder.addCase(createRoomTokenAsync.fulfilled, (state, { payload }) => {

            const roomToken = payload;

            state.callDirection = CallDirection.INCOMING;
            state.activeRoomToken = roomToken;
            state.createRoomTokenStatus = AsyncThunkStatus.FULFILLED;
        });

        builder.addCase(createRoomTokenAsync.rejected, (state, { error }) => {

            console.error(error);
            state.createRoomTokenStatus = AsyncThunkStatus.REJECTED;
        });
    }
});

// actions
const {
    leaveRoom
} = roomsSlice.actions;

// selects
const selectCallDirection = (state: RootState) => state.rooms.callDirection;
const selectActiveRoom = (state: RootState) => state.rooms.activeRoom;
const selectActiveRoomService = (state: RootState) => state.rooms.activeRoomService;
const selectActiveRoomToken = (state: RootState) => state.rooms.activeRoomToken;
const selectCreateRoomStatus = (state: RootState) => state.rooms.createRoomStatus;
const selectError = (state: RootState) => state.rooms.error;

// reducer
const reducer = roomsSlice.reducer;

export {
    createRoomAsync,
    createRoomTokenAsync,
    reducer as default,
    leaveRoom,
    roomsSlice,
    selectActiveRoom,
    selectActiveRoomService,
    selectActiveRoomToken,
    selectCallDirection,
    selectCreateRoomStatus,
    selectError
};

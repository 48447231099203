import { useEffect, useRef, useState } from 'react';
import { SpeakerIcon } from '@100mslive/react-icons';
import { Button, Text } from '@100mslive/react-ui';

const TEST_AUDIO_URL = 'https://100ms.live/test-audio.wav';

type TestAudioProps = {
  sinkId: string;
};

interface HTMLMediaElementWithSink extends HTMLMediaElement {
  setSinkId: (deviceId: string) => Promise<void>;
}

const TestAudio = ({ sinkId }: TestAudioProps) => {

  const audioRef = useRef<HTMLMediaElementWithSink>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
  
    if (audioRef.current && sinkId) {

      try {

        if (typeof audioRef.current.setSinkId !== 'undefined') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          audioRef.current.setSinkId(sinkId);
        }

      } catch(err) {
          console.error(err)
      }
    }
  }, [sinkId]);

  return (
    <>
      <Button
        variant='standard'
        css={{
          flexShrink: 0,
          p: '$6 $9',
          '@md': {
            w: '100%',
          },
        }}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => audioRef.current?.play()}
        disabled={playing}
      >
        <SpeakerIcon />
        &nbsp;Test{' '}
        <Text as='span' css={{ display: 'none', '@md': { display: 'inline' } }}>
          &nbsp; speaker
        </Text>
      </Button>
      <audio
        ref={audioRef}
        src={TEST_AUDIO_URL}
        onEnded={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
      />
    </>
  );
};

export { TestAudio };

import { useCallback } from 'react';
import {
  selectAppData,
  selectAppDataByPath,
  selectTrackByID,
  useHMSActions,
  useHMSStore,
  useHMSVanillaStore,
} from '@100mslive/react-sdk';

import { HMS_APP_DATA } from '../constants';
import { UserPreferencesKeys, useUserPreferences } from '../hooks/useUserPreferences';
import { AppData, UISettings } from '../types';

/**
 * Hook for accessing UI settings from App Data store.
 */
const useUISettingsByKey = <K extends keyof UISettings>(uiSettingKey: K) => {

    const uiSettings = useHMSStore(
        selectAppDataByPath(HMS_APP_DATA.uiSettings, uiSettingKey)
    ) as unknown as UISettings[K];

    return uiSettings;
};

const useUISettings = () => {

    const uiSettings = useHMSStore(selectAppDataByPath(HMS_APP_DATA.uiSettings)) as UISettings;
    return uiSettings;
};

/**
 * Hook for accessing and saving UI settings to App Data store.
 */
const useSetUiSettingsByKey = <K extends keyof UISettings>(
    uiSettingKey: K
): [
    UISettings[K],
    (value: UISettings[K]) => void
] => {

    const value = useUISettingsByKey(uiSettingKey);
    const setValue = useSetAppDataByKey<UISettings, K>({
        key1: HMS_APP_DATA.uiSettings,
        key2: uiSettingKey,
    });

    return [value, setValue];
};

const useSetUiSettings = (): [UISettings, (value: UISettings) => void] => {

    const value = useUISettings();
    const [, setValue] = useSetAppData<UISettings>(HMS_APP_DATA.uiSettings);

    return [value, setValue];
};

const useSetAppDataByKey = <T, K extends keyof T>({
    key1, key2
}: {
    key1: HMS_APP_DATA;
    key2: K;
}) => {

    const actions = useHMSActions();
    const store = useHMSVanillaStore();

    const [, setPreferences] = useUserPreferences<Record<string, any>>(UserPreferencesKeys.UI_SETTINGS);

    const setValue = useCallback((value: T[K]) => {

        if (!key1) {
            return;
        }
        actions.setAppData(key1, { [key2]: value }, true);

        const appData = store.getState<AppData>(selectAppData());
        setPreferences({
            ...appData.uiSettings,
        });

    }, [actions, key1, key2, store, setPreferences]);

    return setValue;
};

const useSetAppData = <T>(key: HMS_APP_DATA): [T | undefined, (value?: T) => void] => {

    const value = useHMSStore<T | undefined>(selectAppData(key));
    const actions = useHMSActions();

    const setValue = useCallback((value?: T) => {
        actions.setAppData(key, value);
    }, [actions, key]);

    return [value, setValue];
};

export {
    useSetAppData,
    useSetUiSettings,
    useSetUiSettingsByKey,
    useUISettings,
    useUISettingsByKey
};

export type {
    AppData,
    UISettings,
};

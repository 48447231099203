import { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { Spinner } from '../common/Spinner';

function AuthenticationGuard({ component }: { component: ComponentType }) {

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Spinner />
  });

  return <Component />;
}

export { AuthenticationGuard };

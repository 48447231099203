import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { MenuItem } from './MenuItem';
import { DropDownMenuProps } from './types';

function DropDownMenu({
  avatar, items
}: DropDownMenuProps) {

  return (
    <Menu as='div' className='relative ml-3'>

      <div>
        <Menu.Button className='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
          <span className='sr-only'>Open user menu</span>
          <img
            className='h-8 w-8 rounded-full'
            src={avatar}
            alt=''
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-200'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {
            items.map(({ title, onClick }) => (
              <MenuItem
                key={title}
                title={title}
                onClick={onClick}
              />
            ))
          }
        </Menu.Items>
      </Transition>

  </Menu>
  );
}

export { DropDownMenu };

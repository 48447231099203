import { useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import { BellAlertIcon } from '@heroicons/react/24/solid';

import type { NavBarItemTitle } from '../../constants';

import { MenuItemDisclosure } from './MenuItemDisclosure';
import { NavBarItemDisclosure } from './NavBarItemDisclosure';
import { NavBarDisclosurePanelProps } from './types';

function NavBarDisclosurePanel({
  navBarRoutes,
  dropDownMenu,
  hasNotification,
  notificationRoute
}: NavBarDisclosurePanelProps) {

  const navigate = useNavigate();

  return (
    <Disclosure.Panel className='md:hidden'>

      <div className='space-y-1 pt-2 pb-3'>
        {
          (Object.keys(navBarRoutes) as NavBarItemTitle[]).map((title: NavBarItemTitle) => (
            <NavBarItemDisclosure
              key={title}
              isSelected={navBarRoutes[title] === location.pathname}
              title={title}
              onClick={() => navigate(navBarRoutes[title])}
            />
          ))
        }
      </div>

      <div className='border-t border-gray-200 pt-4 pb-3'>

        <div className='flex items-center px-4 sm:px-6'>
          <div className='flex-shrink-0'>
            <img
              className='h-10 w-10 rounded-full'
              src={dropDownMenu.avatar}
              alt=''
            />
          </div>

          <div className='ml-3'>
            <div className='text-base font-medium text-gray-800'>{dropDownMenu.title}</div>
            <div className='text-sm font-medium text-gray-500'>{dropDownMenu.subtitle}</div>
          </div>

          <button
            type='button'
            className='ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          >
            <span className='sr-only'>View notifications</span>
            {
              hasNotification
                ? <BellAlertIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' onClick={() => navigate(notificationRoute)} />
                : <BellIcon className='h-6 w-6' aria-hidden='true' />
            }
          </button>

        </div>

        <div className='mt-3 space-y-1'>
          {
            dropDownMenu.items.map(({ title, onClick }) => (
              <MenuItemDisclosure
                key={title}
                title={title}
                onClick={onClick}
              />
            ))
          }
        </div>
  
      </div>
    </Disclosure.Panel>
  );
}

export { NavBarDisclosurePanel };

import type { ReactElement } from 'react';

import { ListSectionHeader } from './ListSectionHeader';

type ListSectionProps = {
  title: string;
  stickyHeader: boolean;
  children: ReactElement
};

function ListSection({ title, stickyHeader, children }: ListSectionProps) {
  return (
    <div className='relative'>
      <ListSectionHeader
        title={title}
        sticky={stickyHeader}
      />
      { children }
    </div>
  );
}

export { ListSection };
export type { ListSectionProps };

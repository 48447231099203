import { useNavigate } from 'react-router-dom';
import { HangUpIcon } from '@100mslive/react-icons';
import { useHMSActions } from '@100mslive/react-sdk';
import { Box, Tooltip } from '@100mslive/react-ui';

import { AppRoute } from '../../../../constants';

import { LeaveIconButton } from './utils';

const LeaveRoomButton = () => {

  const navigate = useNavigate();
  const hmsActions = useHMSActions();

  const leaveRoom = () => {
    hmsActions.leave().catch(console.error);
    navigate(AppRoute.CALL_LEAVE);
  };

  return (
    <LeaveIconButton
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    variant='danger'
    key='LeaveRoom'
    data-testid='leave_room_btn'
    css={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
    onClick={leaveRoom}
  >
    <Tooltip title='Leave Room'>
      <Box>
        <HangUpIcon key='hangUp' />
      </Box>
    </Tooltip>
  </LeaveIconButton>
  );
};

export { LeaveRoomButton };


import {CoolDownSender} from "../../components/network/CoolDownSender";
import {NormalizedPoint} from "../../hms/components/conference/fadingLineDrawer";
import {appendCallInvitation} from "../../models/call-invitations/callInvitationsSlice";
import {CallInvitation} from "../../models/call-invitations/types";
import {store} from "../../models/store";
import {Device, PresenceInfo, Status} from "../../models/users/constants";
import {updatePresence} from "../../models/users/usersSlice";

import {CallInvitationMessage, InboxMessageT, MessageType, PubNubPresenceState} from "./types";

export interface PresenceUpdate {
    hashedId: string;
    status: Status;

    device: Device;
}

function isCallInvitation(message: InboxMessageT): message is CallInvitationMessage {
    return message.type === MessageType.CALL_INVITATION;
}

abstract class RealtimeRoom {
    public readonly roomId: string;
    public readonly orgId: string;

    protected constructor(roomId: string, orgId: string) {
        this.roomId = roomId;
        this.orgId = orgId;
    }

    get channelForRoom() {
        return `room.${this.orgId}.${this.roomId}`;
    }

    protected coolDownSender = new CoolDownSender<NormalizedPoint>(
        (points) => {
            this.sendPoints(points);
        }
    )

    publishPoint(point: NormalizedPoint): void {
        this.coolDownSender.send(point)
    }


    protected abstract sendPoints(points: NormalizedPoint[]): void
}

abstract class RealtimeService {
    private static _sharedInstance: RealtimeService | undefined;
    static initialize(realtimeService: RealtimeService) {
        if (this._sharedInstance) {
            this._sharedInstance.cleanup();
        }

        this._sharedInstance = realtimeService;
    }

    static get sharedInstance(): RealtimeService {
        if (!this._sharedInstance) {
            throw new Error("There is no shared instance")
        }

        return this._sharedInstance;
    }

    static isInitialized(): boolean {
        return (this._sharedInstance !== undefined);
    }

    readonly orgId: string;
    readonly userId: string;
    readonly email: string;
    readonly name: string;
    protected constructor(userId: string, orgId: string, name: string,  email: string) {
        this.orgId = orgId
        this.userId = userId
        this.email = email
        this.name = name
    }
    get presenceChannel(): string {
        return `presence.${this.orgId}`;
    }


    get inboxChannel(): string {
        return `inbox.${this.orgId}.${this.userId}`;
    }

    public inboxChannelForRecipient(userId: string) {
        return `inbox.${this.orgId}.${userId}`;
    }

    abstract cleanup(): void;

    abstract readPresence(): Promise<Map<string, PresenceInfo>>;

    async readCallInvitations(): Promise<CallInvitation[]> {
        const latest = await this.readInbox();

        return latest.filter(isCallInvitation).map(e => e.payload)
    }

    async publishCallInvitation(invitation: CallInvitation): Promise<void> {
        const message: CallInvitationMessage = {
            type: MessageType.CALL_INVITATION,
            payload: invitation
        };

        const deliveryPromises = invitation.recipients
            // .map(this.inboxChannelForRecipient.bind(this))
            .map((userId) => this.sendTo(userId, message));

        await Promise.all(deliveryPromises)
    }

    abstract realtimeRoom(roomId: string): RealtimeRoom;

    protected onPresence(presenceUpdate: PresenceUpdate): void {
        store.dispatch(updatePresence(presenceUpdate));
    }

    protected onCallInvitation(callInvitation: CallInvitation): void {
        store.dispatch(appendCallInvitation(callInvitation));
    }

    public setState(userState: Status): Promise<void> {
        const state: PubNubPresenceState = {
            device: Device.DESKTOP,
            inCall: userState === Status.IN_CALL,
            name: this.name ?? "Unknown",
            email: this.email ?? "Unknown",
        }

        return this.actuallySetState(state)
    }

    protected abstract actuallySetState(state: PubNubPresenceState): Promise<void>;

    abstract readInbox(): Promise<InboxMessageT[]>

    abstract sendTo(userId: string, message: InboxMessageT): Promise<void>;
}

export {
    RealtimeRoom, RealtimeService
}
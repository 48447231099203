import { lazy, Suspense } from 'react';
import {
  selectLocalPeerRoleName,
  selectPeerScreenSharing,
  selectPeerSharingAudio, selectSessionStore, selectTrackByID, selectVideoTrackByPeerID,
  useHMSStore,
} from '@100mslive/react-sdk';
import { Flex } from '@100mslive/react-ui';

import { useUISettings } from '../app-data/useUISettings';
import { FullPageProgress } from '../components/common/FullPageProgress';
import { HMS_UI_MODE_ACTIVE_SPEAKER } from '../constants';
import { useRefreshSessionMetadata } from '../hooks/useRefreshSessionMetadata';

import { ScreenShareView } from './screenshare/ScreenShareView';
import { MainGridView } from './MainGridView';
import { SidePane } from './SidePane';

const ActiveSpeakerView = lazy(() => import('./ActiveSpeakerView'));
const PinnedTrackView = lazy(() => import('./PinnedTrackView'));

const ConferenceMainView = () => {

  const localPeerRole = useHMSStore(selectLocalPeerRoleName);

  const pinnedTrackId = useHMSStore(selectSessionStore('pinnedTrackId')) as string | undefined;
  const pinnedUserId = useHMSStore(selectSessionStore('pinnedUserId')) as string | undefined;

  const pinnedTrack = useHMSStore(selectTrackByID(pinnedTrackId));
  const pinnedUserVideoTrack = useHMSStore(selectVideoTrackByPeerID(pinnedUserId));

  const peerSharing = useHMSStore(selectPeerScreenSharing);
  const peerSharingAudio = useHMSStore(selectPeerSharingAudio);

  useRefreshSessionMetadata();

  const { uiViewMode, isAudioOnly } = useUISettings();

  if (!localPeerRole) {
    // we don't know the role yet to decide how to render UI
    return null;
  }

  let ViewComponent;
  if ((peerSharing && peerSharing.id !== peerSharingAudio?.id) && !isAudioOnly) {
    ViewComponent = ScreenShareView;
  } else if (pinnedTrack || pinnedUserVideoTrack) {
    ViewComponent = PinnedTrackView;
  } else if (
    uiViewMode === HMS_UI_MODE_ACTIVE_SPEAKER
  ) {
    ViewComponent = ActiveSpeakerView;
  } else {
    ViewComponent = MainGridView;
  }

  return (
    <Suspense fallback={<FullPageProgress />}>
      <Flex
        css={{
          size: '100%',
          position: 'relative',
        }}
      >
        <ViewComponent />
        <SidePane />
      </Flex>
    </Suspense>
  );
};

export { ConferenceMainView };

import { useEffect } from 'react';
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore
} from '@100mslive/react-sdk';
import { Role } from '@almer/almer-beam-api';

import { useSidepaneReset, useSidepaneState } from '../app-data/useSidepane';
import {
  HMS_APP_DATA,
  HMS_SIDE_PANE_OPTIONS,
  HMS_UI_MODE_GRID,
  HMS_UI_SETTINGS,
} from '../constants';

const INITIAL_APP_DATA = {
  [HMS_APP_DATA.uiSettings]: {
    [HMS_UI_SETTINGS.isAudioOnly]: false,
    [HMS_UI_SETTINGS.maxTileCount]: 9,
    [HMS_UI_SETTINGS.showStatsOnTiles]: false,
    [HMS_UI_SETTINGS.uiViewMode]: HMS_UI_MODE_GRID,
    [HMS_UI_SETTINGS.mirrorLocalVideo]: true,
  },
  [HMS_APP_DATA.sidePane]: '',
};

const AppData = () => {

  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const sidePane = useSidepaneState();
  const resetSidePane = useSidepaneReset();

  useEffect(() => {

    if (
      !isConnected
      && sidePane
      && sidePane !== HMS_SIDE_PANE_OPTIONS.PARTICIPANTS
    ) {
      resetSidePane();
    }

  }, [isConnected, sidePane, resetSidePane]);

  useEffect(() => {

    hmsActions.initAppData(INITIAL_APP_DATA);
    hmsActions.setAppData(HMS_APP_DATA.appLayout, {
      center: [Role.ALMER_GLASSES],
      sidepane: [Role.ALMER_BROWSER]
    });
  
  }, [hmsActions]);

  return null;
}

export { AppData };

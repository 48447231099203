export interface BulkSenderOptions {
    maxMessagesPerSecond?: number;
}

export type Sender<MessageType> = (messages: MessageType[]) => void

export class CoolDownSender<MessageType> {
    private readonly maxSendsPerSecond: number;

    private readonly sender: Sender<MessageType>;
    private readonly coolDownMs: number;

    private buffer: MessageType[] = [];

    private coolDown?: NodeJS.Timeout;


    constructor(
        sender: Sender<MessageType>, {
            maxMessagesPerSecond = 50,
        }: BulkSenderOptions = {}) {
        this.maxSendsPerSecond = maxMessagesPerSecond;
        this.sender = sender;
        this.coolDownMs = 1000 / this.maxSendsPerSecond;
    }

    send(message: MessageType): void {
        // add to the buffer
        this.buffer.push(message);

        // if we have an active cool-down, the loop will take care of it
        //   if not start the loop
        if (!this.coolDown) {
            this.actuallySend();
        }
    }

    private actuallySend(): void {
        // send the current buffer
        this.sender(this.buffer);

        // people might keep the reference to this, better to not drop the values but rather create  a new array
        this.buffer = [];

        // start the cool-down
        this.coolDown = setTimeout(() => {
            // check if somebody send a message while the cool-down was in effect
            if (this.buffer.length) {
                // if yes, loop back
                this.actuallySend();
            } else {
                // we are done, mark that no cool-down is in effect
                this.coolDown = undefined;
            }
        }, this.coolDownMs);
    }
}

type EmptyListProps = {
  placeholder?: string;
}

function EmptyList({ placeholder = 'No Data' }: EmptyListProps) {
  return (
    <div className='flex h-full justify-center items-center px-6'>
      <div className='flex w-full h-full justify-center items-center'>
        <h1 className='text-3xl font-bold tracking-tight text-gray-400 sm:text-5xl'>{placeholder}</h1>
      </div>
    </div>
  );
}

export { EmptyList };
export type { EmptyListProps };

import { Role } from '@almer/almer-beam-api';

import { EnvConfig } from './types';

function parseEnvConfig(): EnvConfig {

    const {
        REACT_APP_LOCAL_DEPLOYMENT,
        REACT_APP_API_SERVER_URL,
        REACT_APP_AUTH0_DOMAIN,
        REACT_APP_AUTH0_CLIENT_ID,
        REACT_APP_AUTH0_CALLBACK_URL,
        REACT_APP_AUTH0_AUDIENCE,
        REACT_APP_AUTH0_CUSTOM_CLAIM_PREFIX,
        REACT_APP_PUBNUB_PUBLISH_KEY,
        REACT_APP_PUBNUB_SUBSCRIBE_KEY
    } = process.env;

    return {
        LOCAL_DEPLOYMENT: Boolean(REACT_APP_LOCAL_DEPLOYMENT),
        API_SERVER_URL: REACT_APP_API_SERVER_URL!,
        AUTH0_DOMAIN: REACT_APP_AUTH0_DOMAIN!,
        AUTH0_CLIENT_ID: REACT_APP_AUTH0_CLIENT_ID!,
        AUTH0_CALLBACK_URL: REACT_APP_AUTH0_CALLBACK_URL!,
        AUTH0_AUDIENCE: REACT_APP_AUTH0_AUDIENCE!,
        AUTH0_CUSTOM_CLAIM_PREFIX: REACT_APP_AUTH0_CUSTOM_CLAIM_PREFIX!,
        PUBNUB_PUBLISH_KEY: REACT_APP_PUBNUB_PUBLISH_KEY!,
        PUBNUB_SUBSCRIBE_KEY: REACT_APP_PUBNUB_SUBSCRIBE_KEY!
    };
}

const REST_API_PATH = '/api';
const REST_API_VERSION = 'v1';

const ENV = parseEnvConfig();
const REST_API_BASE_URL = `${ENV.API_SERVER_URL}${REST_API_PATH}/${REST_API_VERSION}/`;
const ORG_ID_CLAIM = 'org_id';

enum OpenIDConnectScopes {
    openid = 'openid',
    profile = 'profile',
    email = 'email'
}

enum AppRoute {
    ROOT = '/',
    RECENT_CALLS = '/recent-calls',
    CALL_INVITATIONS = '/call-invitations',
    USER_PROFILE = '/profile',
    CALL = '/call',
    CALL_LEAVE = '/call/leave',
    AUTH_CALLBACK = '/callback',
    CATCH_ALL = '*'
}

enum NavBarItemTitle {
    TEAM = 'Team',
    // RECENT_CALLS = 'Recent calls',
    CALL_INVITATIONS = 'Call invitations'
}

enum DropDownItemTitle {
    LOGOUT = 'Sign out'
}

const NAV_BAR_ROUTES: Record<NavBarItemTitle, AppRoute> = {
    [NavBarItemTitle.TEAM]: AppRoute.ROOT,
    // [NavBarItemTitle.RECENT_CALLS]: AppRoute.RECENT_CALLS,
    [NavBarItemTitle.CALL_INVITATIONS]: AppRoute.CALL_INVITATIONS
};

enum SearchPlaceholder {
    TEAM = 'Search people',
    RECENT_CALLS = 'Search calls',
    CALL_INVITATIONS = 'Search invitations'
}

enum EmptyPlaceholder {
    TEAM = 'No people',
    RECENT_CALLS = 'No calls',
    CALL_INVITATIONS = 'No invitations'
}

const GROUP_CALL_TITLE = 'Group call';
const DEFAULT_ERROR_MESSAGE = 'Oops .. something went wrong!';
const DEFAULT_ROLE = Role.ALMER_BROWSER;
const CALL_NOTIFICATION_TIME = 30000;

enum Color {
    ALMER_GREEN = '#00aab9'
}

export {
    AppRoute,
    CALL_NOTIFICATION_TIME,
    Color,
    DEFAULT_ERROR_MESSAGE,
    DEFAULT_ROLE,
    DropDownItemTitle,
    EmptyPlaceholder,
    ENV,
    GROUP_CALL_TITLE,
    NAV_BAR_ROUTES,
    NavBarItemTitle,
    OpenIDConnectScopes,
    ORG_ID_CLAIM,
    REST_API_BASE_URL,
    SearchPlaceholder
};

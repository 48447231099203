import type { CSSProperties } from 'react';
import { useEffect } from "react";
import { ShareScreenIcon } from '@100mslive/react-icons';
import {
  selectIsAllowedToPublish, useHMSActions,
  useHMSStore,
  useScreenShare,
} from '@100mslive/react-sdk';
import { Tooltip } from '@100mslive/react-ui';

import { useUISettingsByKey } from '../../../app-data/useUISettings';
import { HMS_UI_SETTINGS } from '../../../constants';
import { isScreenshareSupported } from '../../../utils';
import { IconButton } from '../../common/IconButton';

type ScreenshareToggleProps = {
  css?: CSSProperties;
};

const ScreenshareToggle = ({
  css
}: ScreenshareToggleProps) => {

  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  const isAudioOnly = useUISettingsByKey(HMS_UI_SETTINGS.isAudioOnly);

  const {
    amIScreenSharing,
    screenShareVideoTrackId: video,
    toggleScreenShare,
  } = useScreenShare();

  const hmsActions = useHMSActions();
  const setPinnedTrackId = (trackId?: string) => {
    hmsActions.sessionStore.set('pinnedTrackId', trackId).catch(e => console.error("Failed setting pinned screenshare track id with error", e));
    hmsActions.sessionStore.set('pinnedUserId').catch(e => console.error("Failed clearing pinned user id with error", e));
  }

  const isVideoScreenshare = amIScreenSharing && !!video;

  useEffect( () => {
    setPinnedTrackId(video);
  }, [video]);

  if (!isAllowedToPublish.screen || !isScreenshareSupported()) {
    return null;
  }

  return (
    <Tooltip title={`${!isVideoScreenshare ? 'Start' : 'Stop'} screen sharing`}>
      <IconButton
        active={!isVideoScreenshare}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={css}
        disabled={isAudioOnly}
        onClick={() => {
          toggleScreenShare!().catch(console.error);
        }}
        data-testid='screen_share_btn'
      >
        <ShareScreenIcon />
      </IconButton>
    </Tooltip>
  );
};

export { ScreenshareToggle };

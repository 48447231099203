import { Fragment } from 'react';
import { BrbIcon, HandRaiseFilledIcon } from '@100mslive/react-icons';
import { selectPeerMetadata, useHMSStore } from '@100mslive/react-sdk';
import { StyledVideoTile } from '@100mslive/react-ui';

import { PeerMetadata } from '../../types';

const metaStyles = { top: '$4', left: '$4' };

type PeerMetadataProps = {
  peerId: string;
};

const PeerMetadataTile = ({
  peerId
}: PeerMetadataProps) => {

  const metadata = useHMSStore<PeerMetadata>(selectPeerMetadata(peerId));
  const isHandRaised = metadata?.isHandRaised || false;
  const isBRB = metadata?.isBRBOn || false;

  return (
    <Fragment>
      {
        isHandRaised
          ? (
              <StyledVideoTile.AttributeBox
                css={metaStyles}
                data-testid='raiseHand_icon_onTile'
              >
                <HandRaiseFilledIcon width={40} height={40} />
              </StyledVideoTile.AttributeBox>
            )
          : null
      }

      {
        isBRB
          ? (
              <StyledVideoTile.AttributeBox
                css={metaStyles}
                data-testid='brb_icon_onTile'
              >
                <BrbIcon width={40} height={40} />
              </StyledVideoTile.AttributeBox>
            )
          : null
      }
    </Fragment>
  );
};

export { PeerMetadataTile };

import { Box, Flex, Text } from '@100mslive/react-ui';

const FIRST_PERSON_BG_SRC = '/hms-first-person-bg.png';

const FirstPersonDisplay = () => {

  return (
    <Box
      css={{
        position: 'relative',
        overflow: 'hidden',
        w: '37.5rem',
        maxWidth: '80%',
        h: '100%',
        r: '$3',
        m: '0 auto',
        backgroundImage: `url(${FIRST_PERSON_BG_SRC})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      data-testid='first_person_img'
    >
      <Flex
        align='center'
        direction='column'
        css={{
          position: 'absolute',
          w: '100%',
          top: '45%',
          left: 0,
          textAlign: 'center',
        }}
      >
        <Text color='white' variant='h4' css={{ '@md': { fontSize: '$md' } }}>
          Hello!
        </Text>
        <Text
          color='white'
          variant='h6'
          css={{ mt: '$4', '@md': { fontSize: '$sm' } }}
        >
          Looks like you’re the only one here.
        </Text>
      </Flex>
    </Box>
  );
};

export { FirstPersonDisplay };

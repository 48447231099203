import { memo, useState } from 'react';
import {
  selectPeerByID,
  selectScreenShareAudioByPeerID,
  selectScreenShareByPeerID,
  useHMSStore,
} from '@100mslive/react-sdk';
import { StyledVideoTile, Video, VideoTileStats } from '@100mslive/react-ui';

import { useUISettingsByKey } from '../../../app-data/useUISettings';
import { HMS_UI_SETTINGS } from '../../../constants';
import { TileMenu } from '../TileMenu';
import { VideoTileLabel } from '../VideoTileLabel';

type ScreenshareTileProps = {
  peerId: string;
  width?: number | string;
  height?: number | string;
};

const labelStyles = {
  position: 'unset',
  width: '100%',
  textAlign: 'center',
  transform: 'none',
  mt: '$2',
  flexShrink: 0,
};

const Tile = ({
  peerId, width = '100%', height = '100%'
}: ScreenshareTileProps) => {

  const videoTrack = useHMSStore(selectScreenShareByPeerID(peerId));
  const peer = useHMSStore(selectPeerByID(peerId));
  const isAudioOnly = useUISettingsByKey(HMS_UI_SETTINGS.isAudioOnly);

  const [isMouseHovered, setIsMouseHovered] = useState(false);
  const showStatsOnTiles = useUISettingsByKey(HMS_UI_SETTINGS.showStatsOnTiles);

  const label = VideoTileLabel({
    peerName: peer!.name,
    isLocal: false,
    videoTrack
  });

  const audioTrack = useHMSStore(selectScreenShareAudioByPeerID(peer?.id));

  return (
    <StyledVideoTile.Root
      css={{ width, height }}
      data-testid='screenshare_tile'
    >
      {
        peer
          ? (
              <StyledVideoTile.Container
                transparentBg
                css={{ flexDirection: 'column' }}
                onMouseEnter={() => setIsMouseHovered(true)}
                onMouseLeave={() => {
                  setIsMouseHovered(false);
                }}
              >

                {
                  showStatsOnTiles
                    ? (
                        <VideoTileStats
                          audioTrackID={audioTrack?.id}
                          videoTrackID={videoTrack?.id}
                          isLocal={peer.isLocal}
                          peerID={peerId}
                        />
                      )
                    : null
                }

                {
                  videoTrack
                    ? (
                        <Video
                          screenShare={true}
                          mirror={false}
                          attach={!isAudioOnly}
                          trackId={videoTrack.id}
                        />
                      )
                    : null
                }

                <StyledVideoTile.Info css={labelStyles}>{label}</StyledVideoTile.Info>

                {
                  isMouseHovered && !peer?.isLocal
                  ? (
                      <TileMenu
                        peerID={peer?.id}
                        audioTrackID={audioTrack?.id}
                        videoTrackID={videoTrack?.id}
                      />
                    ) 
                  : null
                }

              </StyledVideoTile.Container>
            )
          : null
      }
    </StyledVideoTile.Root>
  );
};

const ScreenshareTile = memo(Tile);

export { ScreenshareTile };

import { useEffect } from 'react';
import { useHMSActions, useHMSVanillaStore } from '@100mslive/react-sdk';

import { KeyboardInputManager } from './KeyboardInputManager';

const KeyboardHandler = () => {

  const store = useHMSVanillaStore();
  const actions = useHMSActions();

  useEffect(() => {

    const keyboardManager = new KeyboardInputManager(store, actions);
    keyboardManager.bindAllShortcuts();

    return keyboardManager.unbindAllShortcuts.bind(keyboardManager);

  }, [actions, store]);

  return null;
};

export { KeyboardHandler };

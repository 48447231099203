import { Disclosure } from '@headlessui/react';

import {selectNotification} from "../../models/call-invitations/callInvitationsSlice";
import {useAppSelector} from "../../models/hooks";

import { NavBarDisclosurePanel } from './NavBarDisclosurePanel';
import { NavBarLeftContainer } from './NavBarLeftContainer';
import { NavBarRightContainer } from './NavBarRightContainer';
import { NavBarProps } from './types';

function NavBar({
  navBarRoutes,
  dropDownMenu,
  groupCallButtonTitle,
  onGroupCallButtonAction,
  notificationRoute
}: NavBarProps) {
  const callNotification = useAppSelector(selectNotification);

  return (
    <Disclosure as='nav' className='bg-white shadow'>
      {({ open }) => (
        <>

          {/* NavBar in its default layout, if the screen width is large enough */}
          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            <div className='flex h-16 justify-between'>

              <NavBarLeftContainer
                navBarRoutes={navBarRoutes}
                isOpen={open}
              />

              <NavBarRightContainer
                dropDownMenu={dropDownMenu}
                groupCallButtonTitle={groupCallButtonTitle}
                hasNotification={!!callNotification}
                notificationRoute={notificationRoute}
                onGroupCallButtonAction={onGroupCallButtonAction}
              />

            </div>
          </div>

          {/* Visible below NavBar only when it is collapsed due to screen width and left menu button is expanded */}
          <NavBarDisclosurePanel
            navBarRoutes={navBarRoutes}
            dropDownMenu={dropDownMenu}
            hasNotification={!!callNotification}
            notificationRoute={notificationRoute}
          />

        </>
      )}
    </Disclosure>
  )
}

export { NavBar };

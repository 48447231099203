import { Flex, Footer as AppFooter } from '@100mslive/react-ui';

import { AudioVideoToggle } from '../common/AudioVideoToggle';
import { LeaveRoom } from '../conference/leave/LeaveRoom';
import { MetaActions } from '../conference/MetaActions';
import { ScreenshareAudio } from '../conference/screenshare/ScreenshareAudio';
import { ScreenshareToggle } from '../conference/screenshare/ScreenshareToggle';
import { MoreSettings } from '../settings/MoreSettings';

const ConferencingFooter = () => (
  <AppFooter.Root>

    <AppFooter.Left>
      <ScreenshareAudio />
      <Flex
        align='center'
        css={{
          display: 'none',
          '@md': {
            display: 'flex',
          },
        }}
      >
        <MetaActions isMobile />
      </Flex>
    </AppFooter.Left>

    <AppFooter.Center>
      <AudioVideoToggle />
      <ScreenshareToggle />
      <MoreSettings />
      <LeaveRoom />
    </AppFooter.Center>

    <AppFooter.Right>
      <MetaActions />
    </AppFooter.Right>

  </AppFooter.Root>
);

export { ConferencingFooter };

import { Fragment } from 'react';
import {
  HMSVideoTrack, selectTrackByID, useHMSActions, useHMSStore
} from '@100mslive/react-sdk';
import { StyledMenuTile, Text } from '@100mslive/react-ui';

import { useDropdownSelection } from '../../hooks/useDropdownSelection';

type SimulcastLayersProps = {
  trackId: string;
};

const SimulcastLayers = ({ trackId }: SimulcastLayersProps) => {

  const track = useHMSStore(selectTrackByID(trackId)) as HMSVideoTrack | null;
  const actions = useHMSActions();
  const bg = useDropdownSelection();

  if (!track?.layerDefinitions?.length || track.degraded || !track.enabled) {
    return null;
  }

  const currentLayer = track.layerDefinitions.find(layer => layer.layer === track.layer);

  return (
    <Fragment>
  
      <StyledMenuTile.ItemButton
        css={{ color: '$textMedEmp', cursor: 'default' }}
      >
        Select maximum resolution
      </StyledMenuTile.ItemButton>

      {
        track.layerDefinitions.map((layer) => {

          return (
            <StyledMenuTile.ItemButton
              key={layer.layer}
              onClick={() => {
                actions.setPreferredLayer(trackId, layer.layer).catch(console.error);
              }}
              css={{
                justifyContent: 'space-between',
                bg: track.preferredLayer === layer.layer ? bg : undefined,
                '&:hover': {
                  bg: track.preferredLayer === layer.layer ? bg : undefined,
                },
              }}
            >

              <Text
                as='span'
                css={{
                  textTransform: 'capitalize',
                  mr: '$2',
                  fontWeight:
                    track.preferredLayer === layer.layer
                      ? '$semiBold'
                      : '$regular',
                }}
              >
                {layer.layer}
              </Text>

              <Text as='span' variant='xs' css={{ color: '$textMedEmp' }}>
                {layer.resolution.width}x{layer.resolution.height}
              </Text>

            </StyledMenuTile.ItemButton>
          );
        })
      }

      {
        currentLayer && (
          <StyledMenuTile.ItemButton>
            <Text as='span' variant='xs' css={{ color: '$textMedEmp' }}>
              Currently streaming:
              <Text
                as='span'
                variant='xs'
                css={{
                  fontWeight: '$semiBold',
                  textTransform: 'capitalize',
                  color: '$textMedEmp',
                  ml: '$2',
                }}
              >
                {track.layer} ({currentLayer.resolution.width}x{currentLayer.resolution.height})
              </Text>
            </Text>
          </StyledMenuTile.ItemButton>
        )
      }

    </Fragment>
  );
};

export { SimulcastLayers };

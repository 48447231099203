import { EmptyList } from './EmptyList';
import { ListItem, ListItemProps } from './ListItem';

type FlatListProps = {
  items: ListItemProps[];
  emptyPlaceholder?: string;
  onItemAction: (item: ListItemProps) => void;
};

function FlatList({
  items,
  emptyPlaceholder,
  onItemAction
}: FlatListProps) {

  if (!items.length && emptyPlaceholder) {
    return <EmptyList placeholder={emptyPlaceholder} />;
  }

  return (
    <ul role='list' className='relative z-0 divide-y divide-gray-200'>
      {
        items.map((item) => (
          <ListItem
            key={item.id}
            {...item}
            onAction={() => onItemAction(item)}
          />
        ))
      }
    </ul>
  );
}

export { FlatList };

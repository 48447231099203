import { Button, Input, styled } from '@100mslive/react-ui';

type PreviewNameProps = {
  name: string;
  onChange: (value: string) => void;
  onJoin: () => void;
  enableJoin: boolean;
};

const PreviewName = ({
  name, onChange, onJoin, enableJoin
}: PreviewNameProps) => {

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Input
        required
        id='name'
        css={{ w: '100%' }}
        value={name}
        onChange={e => onChange(e.target.value)}
        placeholder='Enter your name'
        autoFocus
        autoComplete='name'
      />
      <Button type='submit' disabled={!name || !enableJoin} onClick={onJoin} className='text-black'>
        {'Join Room'}
      </Button>
    </Form>
  );
};

const Form = styled('form', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  mt: '$10',
  mb: '$10',
});

export { PreviewName };

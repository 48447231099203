function ProfilePage() {

  return (
    <div>
      <p>ProfilePage in progress</p>
    </div>
  );
}

export { ProfilePage };

import {Fragment, useEffect} from 'react';
import { useDispatch } from "react-redux";
import { HangUpIcon } from '@100mslive/react-icons';
import { Dialog, Transition } from '@headlessui/react';
import useSound from "use-sound";

import { clearNotification } from "../../../models/call-invitations/callInvitationsSlice";
import { CallNotificationDialogProps } from '../types';

function CallNotificationModal({
  show,
  joinCall,
  cancel,
  sender,
  message,
}: CallNotificationDialogProps) {

  const [playSound, {stop: stopSound}] = useSound("/sounds/ring.wav", { id: 'ringtone', volume: 0.5, loop: true });
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      playSound();
    } else {
      stopSound();
    }
  }, [show]);

  const avatarDiv = sender?.avatar ?
    <div className='mx-auto m-10 items-center justify-center relative flex h-32 w-32'>
      <span className='absolute animate-ping mx-auto inline-flex h-32 w-32 rounded-full bg-green-100 opacity-75' />
      <img
        className='relative h-28 w-28 rounded-full content-center'
        src={sender?.avatar}
      />
    </div> : ''

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-100'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6'>

                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      {sender?.name}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>{message}</p>
                    </div>
                  </div>
                  { avatarDiv }
                </div>

                <div className='inline-flex items-center w-full mt-2'>
                  <button
                    type='button'
                    className='mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-green-700'
                    onClick={() => {
                      dispatch(clearNotification());
                      stopSound();
                      joinCall();
                    }}
                  >
                    <HangUpIcon
                      className='h-8 w-8 text-white -rotate-[140deg]'
                      aria-hidden='true'
                    />
                  </button>

                  <button
                    type='button'
                    className='mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-red-700'
                    onClick={() => {
                      dispatch(clearNotification());
                      cancel();
                    }}
                  >
                    <HangUpIcon
                      className='h-8 w-8 text-white'
                      aria-hidden='true'
                    />
                  </button>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { CallNotificationModal };

import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  ChevronLeftIcon,
  CrossIcon,
  GridFourIcon,
  SettingsIcon,
} from '@100mslive/react-icons';
import {
  Box,
  config as cssConfig,
  Dialog,
  Flex,
  IconButton,
  Tabs,
  Text,
} from '@100mslive/react-ui';

import { DeviceSettings } from './device/DeviceSettings';
import { LayoutSettings } from './LayoutSettings';
import { settingContent } from './utils';

type SettingsItemProps = {
  tabName: string;
  title: string;
  icon: React.FC<any>;
  content: React.FC<any>;
};

enum TabName {
  DEVICES = 'devices',
  NOTIFICATIONS = 'notifications',
  LAYOUT = 'layout'
}

type ShowSettingsItemProps = SettingsItemProps & Record<TabName, boolean>;

const settings: SettingsItemProps[] = [
  {
    tabName: 'devices',
    title: 'Device Settings',
    icon: SettingsIcon,
    content: DeviceSettings,
  },
  {
    tabName: 'layout',
    title: 'Layout',
    icon: GridFourIcon,
    content: LayoutSettings,
  },
];

type SettingsModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children?: ReactElement;
};

const SettingsModal = ({
  open, onOpenChange, children
}: SettingsModalProps) => {

  const mediaQueryLg = cssConfig.media.md;
  const isMobile = useMedia(mediaQueryLg);

  const [showSetting, setShowSetting] = useState<ShowSettingsItemProps>(() =>
    settings.reduce((obj, { tabName }) => ({ ...obj, [tabName]: true }), {}) as ShowSettingsItemProps
  );

  const hideSettingByTabName = useCallback(
    (key: string) => (hide: boolean) => setShowSetting(prev => ({ ...prev, [key]: !hide })),
    [setShowSetting]
  );

  const [selection, setSelection] = useState(
    () => (Object.keys(showSetting) as (keyof ShowSettingsItemProps)[]).find((key) => showSetting[key]) ?? ''
  );
  const resetSelection = useCallback(() => {
    setSelection('');
  }, []);

  useEffect(() => {

    if (isMobile) {
      setSelection('');
    } else {

      const firstNotHiddenTabName = (Object.keys(showSetting) as (keyof ShowSettingsItemProps)[])
        .find(key => showSetting[key]) ?? '';
      setSelection(firstNotHiddenTabName);
    }
  }, [isMobile, showSetting]);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay style={{'zIndex': 2}}/>
        <Dialog.Content
          css={{
            w: 'min(800px, 90%)',
            height: 'min(656px, 90%)',
            p: 0,
            r: '$4',
          }}
        >
          <Tabs.Root
            value={selection}
            activationMode={isMobile ? 'manual' : 'automatic'}
            onValueChange={setSelection}
            css={{ size: '100%', position: 'relative' }}
          >
            <Tabs.List
              css={{
                w: isMobile ? '100%' : '18.625rem',
                flexDirection: 'column',
                bg: '$backgroundDefault',
                p: '$14 $10',
                borderTopLeftRadius: '$4',
                borderBottomLeftRadius: '$4',
              }}
            >
              <Text variant='h5'>Settings </Text>
              <Flex
                direction='column'
                css={{ mx: isMobile ? '-$8' : 0, overflowY: 'auto', pt: '$10' }}
              >
                {settings
                  .filter(({ tabName }) => showSetting[tabName as TabName])
                  .map(({ icon: Icon, tabName, title }) => {
                    return (
                      <Tabs.Trigger
                        key={tabName}
                        value={tabName}
                        css={{ gap: '$8' }}
                      >
                        <Icon />
                        {title}
                      </Tabs.Trigger>
                    );
                  })}
              </Flex>
            </Tabs.List>
            {selection && (
              <Flex
                direction='column'
                css={{
                  flex: '1 1 0',
                  minWidth: 0,
                  mr: '$4',
                  ...(isMobile
                    ? {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bg: '$surfaceDefault',
                        width: '100%',
                        height: '100%',
                      }
                    : {}),
                }}
              >
                {settings
                  .filter(({ tabName }) => showSetting[tabName as TabName])
                  .map(({ content: Content, title, tabName }) => {
                    return (
                      <Tabs.Content
                        key={tabName}
                        value={tabName}
                        className={settingContent()}
                      >
                        <SettingsContentHeader
                          onBack={resetSelection}
                          isMobile={isMobile}
                        >
                          <span>{title}</span>
                        </SettingsContentHeader>
                        <Content setHide={hideSettingByTabName(tabName)} />
                      </Tabs.Content>
                    );
                  })}
              </Flex>
            )}
          </Tabs.Root>
          <Dialog.Close
            css={{ position: 'absolute', right: '$10', top: '$10' }}
          >
            <IconButton as='div' data-testid='dialog_cross_icon'>
              <CrossIcon />
            </IconButton>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

type SettingsContentHeaderProps = {
  isMobile: boolean;
  onBack: () => void;
  children: ReactElement;
};

const SettingsContentHeader = ({
  children, isMobile, onBack
}: SettingsContentHeaderProps) => {

  return (
    <Text
      variant='h6'
      css={{ mb: '$12', display: 'flex', alignItems: 'center' }}
    >
      {isMobile && (
        <Box
          as='span'
          css={{ bg: '$surfaceLight', mr: '$4', r: '$round', p: '$2' }}
          onClick={onBack}
        >
          <ChevronLeftIcon />
        </Box>
      )}
      {children}
    </Text>
  );
};

export { SettingsModal };

import type { HMSActions, HMSStore, IStoreReadOnly } from '@100mslive/react-sdk';
import { selectAppData, selectIsLocalAudioEnabled, selectIsLocalVideoEnabled } from '@100mslive/react-sdk';

import { HMS_APP_DATA } from '../../constants';
import { isMacOS } from '../../utils';

class KeyboardInputManager {

    private static hasEventListeners = false;

    private bindedKeyDownHandler: (e: KeyboardEvent) => Promise<void>;

    constructor(
        private readonly store: IStoreReadOnly<HMSStore>,
        private readonly actions: HMSActions
    ) {
        this.bindedKeyDownHandler = this.keyDownHandler.bind(this);
    }

    private async toggleAudio() {

        const enabled = this.store.getState(selectIsLocalAudioEnabled);
        await this.actions.setLocalAudioEnabled(!enabled);
    }

    private async toggleVideo() {

        const enabled = this.store.getState(selectIsLocalVideoEnabled);
        await this.actions.setLocalVideoEnabled(!enabled);
    }

    private hideSidepane() {

        if (this.store.getState(selectAppData(HMS_APP_DATA.sidePane))) {
            this.actions.setAppData(HMS_APP_DATA.sidePane, '');
        }
    }

    private async keyDownHandler(e: KeyboardEvent) {

        const CONTROL_KEY = isMacOS ? e.metaKey : e.ctrlKey;
        const D_KEY = e.key === 'd' || e.key === 'D';
        const E_KEY = e.key === 'e' || e.key === 'E';

        const SHORTCUT_TOGGLE_AUDIO = CONTROL_KEY && D_KEY;
        const SHORTCUT_TOGGLE_VIDEO = CONTROL_KEY && E_KEY;
        const SHORTCUT_SIDEPANE_CLOSE = e.key === 'Escape';

        if (SHORTCUT_TOGGLE_AUDIO) {

            e.preventDefault();
            await this.toggleAudio();
  
        } else if (SHORTCUT_TOGGLE_VIDEO) {

            e.preventDefault();
            await this.toggleVideo();

        } else if (SHORTCUT_SIDEPANE_CLOSE) {
            this.hideSidepane();
        }
    }

    private bind() {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        document.addEventListener('keydown', this.bindedKeyDownHandler, false);
    }

    private unbind() {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        document.removeEventListener('keydown', this.bindedKeyDownHandler, false);
    }

    public bindAllShortcuts() {

        if (!KeyboardInputManager.hasEventListeners) {

            this.bind();
            KeyboardInputManager.hasEventListeners = true;
        }
    }

    public unbindAllShortcuts() {

        if (KeyboardInputManager.hasEventListeners) {

            this.unbind();
            KeyboardInputManager.hasEventListeners = false;
        }
    }
}

export { KeyboardInputManager };

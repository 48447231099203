import type { HMSPeer } from '@100mslive/react-sdk';
import { Flex } from '@100mslive/react-ui';

import { VideoList } from '../../components/conference/VideoList';

type SmallTilePeersViewProps = {
  smallTilePeers: HMSPeer[];
  shouldShowScreenFn: (peer: HMSPeer) => boolean;
  showSidebarInBottom: boolean;
};

const SmallTilePeersView = ({
  smallTilePeers,
  shouldShowScreenFn,
  showSidebarInBottom,
}: SmallTilePeersViewProps) => {

  return (
    <Flex
      css={{
        flex: '2 1 0',
      }}
    >
      {
        smallTilePeers?.length > 0 && (
          <VideoList
            peers={smallTilePeers}
            maxColCount={showSidebarInBottom ? undefined : 2}
            maxRowCount={showSidebarInBottom ? 1 : undefined}
            includeScreenShareForPeer={shouldShowScreenFn}
          />
        )
      }
    </Flex>
  );
};

export { SmallTilePeersView };

import { PagedResponse, User } from '@almer/almer-beam-api';
import type { User as Auth0User } from '@auth0/auth0-react';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ORG_ID_CLAIM } from '../../constants';
import {RealtimeService} from "../../services/realtime";
import { UserPresence } from '../../services/realtime/types';
import { listUsers } from '../../services/users/list';
import { getCustomClaim } from '../../utils';
import { AsyncThunkStatus } from '../constants';
import type { RootState } from '../store';

import {Device, PresenceInfo, Status} from "./constants";
import { DecoratedUser, UsersState } from './types';

const initialState: UsersState = {
    all: [],
    fetchAllStatus: AsyncThunkStatus.IDLE
};

async function fetchAllUsers(token: string) {

    let users: User[] = [];

    let next: number | null = 0;
    while (next != null) {

        const usersPaged: PagedResponse<User> = await listUsers(token, { start: next, limit: 50 });

        users = users.concat(usersPaged.data);
        next = usersPaged.next as number | null;
    }

    return users;
}

// thunks
const fetchAllAsync = createAsyncThunk(
    'users/fetchAllAsync',
    async (token: string): Promise<[User[], Map<string, PresenceInfo>]> => {

        /**
         * WARNING: It's mandatory that both fetchAllUsers() and readPresence() finish before returning from action.
         *
         * The reason is that we are trying to decorate the users list received from the API Server with
         * presence information received from PubNub.
         * 
         * If the latter finishes before the former, we might have no users (yet) to decorate.
         */
        const [users, presenceMap] = await Promise.all([
            fetchAllUsers(token),
            RealtimeService.sharedInstance.readPresence()
        ]);

        return [users, presenceMap];
    }
);

// store slice
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setupIdentity: (state, { payload }: PayloadAction<Auth0User>) => {

            if (state.whoami) {
                return;
            }

            state.whoami = {
                id: payload.sub as string,
                hashedId: (payload.sub as string),
                orgId: getCustomClaim(payload, ORG_ID_CLAIM) as string,
                name: payload.name!,
                email: payload.email!,
                avatar: payload.picture!,
                device: Device.DESKTOP,
                status: Status.AVAILABLE
            };
        },
        cleanupIdentity: (state) => {
            state.whoami = undefined;
        },
        updatePresence: (state, { payload }: PayloadAction<UserPresence>) => {
            const user = state.all.filter((user) => user.hashedId === payload.hashedId)[0];
            if (!user) return

            if (payload.status) user.status = payload.status;
            if (payload.device) user.device = payload.device;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchAllAsync.pending, (state) => {
            state.fetchAllStatus = AsyncThunkStatus.STARTED;
        });

        builder.addCase(fetchAllAsync.fulfilled, (state, { payload }) => {

            if (!state.whoami) {
                throw new Error('state.whoami must not be undefined in users/fetchAllAsync result handler');
            }

            const [users, presenceMap] = payload;

            state.all = users
                .filter((user: User) => user.id !== state.whoami!.id)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map<DecoratedUser>((user) => {

                    const hashedId = user.id;
                    return {
                        ...user,
                        hashedId,
                        orgId: state.whoami!.orgId,
                        status: presenceMap.get(hashedId)?.status ?? Status.OFFLINE,
                        device: presenceMap.get(hashedId)?.device ?? Device.DESKTOP,
                    };
                });

            state.fetchAllStatus = AsyncThunkStatus.FULFILLED;
        });

        builder.addCase(fetchAllAsync.rejected, (state, { error }) => {

            console.error(error);
            state.fetchAllStatus = AsyncThunkStatus.REJECTED;
        });
    }
});

// actions
const {
    cleanupIdentity,
    setupIdentity,
    updatePresence
} = usersSlice.actions;

// selects
const selectAllUsers = (state: RootState) => state.users.all;
const selectFetchAllStatus = (state: RootState) => state.users.fetchAllStatus;
const selectWhoami = (state: RootState) => state.users.whoami;
const selectError = (state: RootState) => state.users.error;

// reducer
const reducer = usersSlice.reducer;

export {
    cleanupIdentity,
    reducer as default,
    fetchAllAsync,
    selectAllUsers,
    selectError,
    selectFetchAllStatus,
    selectWhoami,
    setupIdentity,
    updatePresence
};

import { useCallback } from 'react';
import {
  selectAppData,
  useHMSActions,
  useHMSStore,
  useHMSVanillaStore,
} from '@100mslive/react-sdk';

import { HMS_APP_DATA, HMS_SIDE_PANE_OPTIONS } from '../constants';

const useIsSidepaneTypeOpen = (sidepaneType: HMS_SIDE_PANE_OPTIONS) => {
    return useHMSStore<HMS_SIDE_PANE_OPTIONS>(selectAppData(HMS_APP_DATA.sidePane)) === sidepaneType;
};

const useSidepaneState = () => {
    return useHMSStore<HMS_SIDE_PANE_OPTIONS>(selectAppData(HMS_APP_DATA.sidePane));
};

const useSidepaneToggle = (sidepaneType: HMS_SIDE_PANE_OPTIONS) => {

    const hmsActions = useHMSActions();
    const vanillaStore = useHMSVanillaStore();

    const toggleSidepane = useCallback(() => {

        const isOpen = vanillaStore.getState(selectAppData(HMS_APP_DATA.sidePane)) === sidepaneType;
        hmsActions.setAppData(HMS_APP_DATA.sidePane, !isOpen ? sidepaneType : '');

    }, [vanillaStore, hmsActions, sidepaneType]);

    return toggleSidepane;
};

const useSidepaneReset = () => {

    const hmsActions = useHMSActions();
    const resetSidepane = useCallback(() => {
        hmsActions.setAppData(HMS_APP_DATA.sidePane, '');
    }, [hmsActions]);

    return resetSidepane;
};

export {
    useIsSidepaneTypeOpen,
    useSidepaneReset,
    useSidepaneState,
    useSidepaneToggle
};

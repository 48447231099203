import { forwardRef,ReactElement } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@100mslive/react-icons';
import {
  Dropdown, Flex, Text, textEllipsis
} from '@100mslive/react-ui';

type DialogDropdownTriggerProps = {
  title: string;
  css: Record<string, any>;
  open: boolean;
  icon: ReactElement;
  titleCSS?: Record<string, any>;
};

// eslint-disable-next-line react/display-name
const DialogDropdownTrigger = forwardRef((
  {
    title,
    css,
    open,
    icon,
    titleCSS = {}
  }: DialogDropdownTriggerProps,
  ref
) => {

  return (
    <Dropdown.Trigger
      asChild
      data-testid={`${title}_selector`}
      css={{
        border: '1px solid $borderLight',
        bg: '$surfaceLight',
        r: '$1',
        p: '$6 $9',
        zIndex: 10,
        ...css,
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
    >
      <Flex
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '$textHighEmp',
          w: '100%',
        }}
      >
        <>
          {icon}

          <Text
            css={{
              color: 'inherit',
              ...textEllipsis('90%'),
              flex: '1 1 0',
              mx: icon ? '$6' : '0',
              ...titleCSS,
            }}
          >
            {title}
          </Text>

          { open ? <ChevronUpIcon /> : <ChevronDownIcon /> }
        </>
      </Flex>
    </Dropdown.Trigger>
  );
});

export { DialogDropdownTrigger };

import { HMSPeer, selectScreenShareByPeerID, useHMSStore } from '@100mslive/react-sdk';
import { Box } from '@100mslive/react-ui';

import { ScreenshareDisplay } from '../../components/conference/screenshare/ScreenshareDisplay';
import { ScreenshareTile } from '../../components/conference/screenshare/ScreenshareTile';

type ScreenShareComponentProps = {
  amIPresenting: boolean;
  peerPresenting?: HMSPeer
};

const ScreenShareComponent = ({
  amIPresenting,
  peerPresenting,
}: ScreenShareComponentProps) => {

  const screenshareTrack = useHMSStore(
    selectScreenShareByPeerID(peerPresenting?.id)
  );

  return (
    <Box
      css={{
        flex: '3 1 0',
        mx: '$8',
        '@xl': { ml: '$4', maxHeight: '80%', minHeight: 0 },
      }}
    >
      {
        peerPresenting && (
          amIPresenting && !['browser', 'window', 'application'].includes(screenshareTrack?.displaySurface ?? '')
            ? (
                <Box css={{ objectFit: 'contain', h: '100%' }}>
                  <ScreenshareDisplay />
                </Box>
              )
            : <ScreenshareTile peerId={peerPresenting?.id} />
        )
      }
    </Box>
  );
};

export { ScreenShareComponent };

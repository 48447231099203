import { ContentType } from '@almer/almer-beam-api';
import type { User as Auth0User } from '@auth0/auth0-react';

import { ENV } from './constants';

type RestApiHeaders = {
    contentType?: ContentType;
    token?: string;
}

function buildRestApiHeaders({ contentType = ContentType.JSON, token }: RestApiHeaders = {}): Record<string, string> {

    return {
        'Content-Type': contentType,
        ...(token ? { 'Authorization': `Bearer ${token}` } : {})
    };
}

function simulateBadNetwork(waitMs: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, waitMs));
}

function getFormattedDuration(seconds: number) {

    const date = new Date(0);
    date.setSeconds(seconds);

    return date.toISOString().substring(11, 19);
}

function getCustomClaim(payload: Auth0User, claim: string, namespace: string = ENV.AUTH0_CUSTOM_CLAIM_PREFIX): unknown {
    return payload[`${namespace}/${claim}`];
}

export {
    buildRestApiHeaders, getCustomClaim, getFormattedDuration, simulateBadNetwork
};

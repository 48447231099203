import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AlertTriangleIcon,
  ExitIcon,
  HangUpIcon,
  VerticalMenuIcon,
} from '@100mslive/react-icons';
import {
  selectIsConnectedToRoom,
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk';
import {
  Box,
  Button,
  Dialog,
  Dropdown,
  Flex,
  Text,
} from '@100mslive/react-ui';

import { AppRoute } from '../../../../constants';
import { Status as UserStatus } from '../../../../models/users/constants';
import {RealtimeService} from "../../../../services/realtime";
import { DialogCheckbox } from '../../common/dialog/DialogCheckbox';
import { DialogContent } from '../../common/dialog/DialogContent';
import { DialogRow } from '../../common/dialog/DialogRow';

import { LeaveRoomButton } from './LeaveRoomButton';
import { MenuTriggerButton } from './utils';

const LeaveRoom = () => {

  const navigate = useNavigate();
  const [showEndRoomModal, setShowEndRoomModal] = useState(false);
  const [lockRoom, setLockRoom] = useState(false);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const permissions = useHMSStore(selectPermissions);
  const hmsActions = useHMSActions();

  const leaveRoom = () => {

    hmsActions.leave().catch(console.error);
    navigate(AppRoute.CALL_LEAVE);
  };

  const endRoom = () => {

    hmsActions.endRoom(lockRoom, 'End Room').catch(console.error);

    void RealtimeService.sharedInstance.setState(UserStatus.AVAILABLE);
    navigate(AppRoute.CALL_LEAVE);
  };

  if (!permissions || !isConnected) {
    return null;
  }

  return (
    <Fragment>
      {
        permissions.endRoom
          ? (
              <Flex>

                <LeaveRoomButton />

                <Dropdown.Root>

                  <Dropdown.Trigger
                    asChild
                    css={{
                      '&[data-state=\'open\']': {
                        bg: '$errorDark',
                      },
                    }}
                  >
                    <MenuTriggerButton
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      data-testid='leave_end_dropdown_trigger'
                      className='bg-[#CC525F]'
                    >
                      <VerticalMenuIcon />
                    </MenuTriggerButton>
                  </Dropdown.Trigger>

                  <Dropdown.Content css={{ p: 0 }} alignOffset={-50} sideOffset={10}>

                    <Dropdown.Item
                      css={{ w: '100%', bg: 'rgba(178, 71, 81, 0.1)' }}
                      onClick={() => {
                        setShowEndRoomModal(true);
                      }}
                      data-testid='end_room_btn'
                    >
                      <Flex gap={4}>
                        <Box css={{ color: '$error' }}>
                          <AlertTriangleIcon />
                        </Box>
                        <Flex direction='column' align='start'>
                          <Text variant='lg' css={{ c: '$error' }}>
                            End Room for All
                          </Text>
                          <Text variant='sm' css={{ c: '$textMedEmp', mt: '$2' }}>
                            Warning: You can’t undo this action
                          </Text>
                        </Flex>
                      </Flex>
                    </Dropdown.Item>

                    <Dropdown.Item
                      css={{ bg: '$surfaceDefault' }}
                      onClick={leaveRoom}
                      data-testid='just_leave_btn'
                    >
                      <Flex gap={4}>
                        <Box>
                          <ExitIcon />
                        </Box>
                        <Flex direction='column' align='start'>
                          <Text variant='lg'>
                            Leave Room
                          </Text>
                          <Text variant='sm' css={{ c: '$textMedEmp', mt: '$2' }}>
                            You can always rejoin later
                          </Text>
                        </Flex>
                      </Flex>
                    </Dropdown.Item>

                  </Dropdown.Content>
                </Dropdown.Root>

              </Flex>
            )
          : <LeaveRoomButton />
      }

      <Dialog.Root
        open={showEndRoomModal}
        onOpenChange={value => {
          if (!value) {
            setLockRoom(false);
          }
          setShowEndRoomModal(value);
        }}
      >
        <DialogContent title='End Room' Icon={HangUpIcon}>
          <DialogCheckbox
            id='lockRoom'
            title='Disable future joins'
            value={lockRoom}
            onChange={setLockRoom}
          />
          <DialogRow justify='end'>
            <Button
              variant='danger'
              onClick={endRoom}
              data-testid='lock_end_room'
            >
              End Room
            </Button>
          </DialogRow>
        </DialogContent>
      </Dialog.Root>
    </Fragment>
  );
};

export { LeaveRoom };

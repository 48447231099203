import {
  HorizontalMenuIcon,
  MicOffIcon,
  MicOnIcon,
  PinIcon,
  SpeakerIcon,
  VideoOffIcon,
  VideoOnIcon
} from '@100mslive/react-icons';
import {
  HMSVideoTrack,
  selectLocalPeerID, selectSessionStore,
  selectTrackByID,
  selectVideoTrackByPeerID, useHMSActions,
  useHMSStore,
  useRemoteAVToggle,
} from '@100mslive/react-sdk';
import {Box, Flex, Slider, StyledMenuTile} from '@100mslive/react-ui';

import {SimulcastLayers} from './SimulcastLayers';

type TileMenuProps = {
  audioTrackID: string;
  videoTrackID: string;
  peerID: string;
};

const TileMenu = ({
  audioTrackID,
  videoTrackID,
  peerID
}: TileMenuProps) => {

  const localPeerID = useHMSStore(selectLocalPeerID);
  const isLocal = localPeerID === peerID;

  const {
    isAudioEnabled,
    isVideoEnabled,
    setVolume,
    toggleAudio,
    toggleVideo,
    volume,
  } = useRemoteAVToggle(audioTrackID, videoTrackID);

  const hmsActions = useHMSActions();
  const setPinnedTrackId = (trackId?: string, userId?: string) => {
      hmsActions.sessionStore.set('pinnedTrackId', trackId).catch(e => console.error("Failed setting pinned track id with error", e));
      hmsActions.sessionStore.set('pinnedUserId', userId).catch(e => console.error("Failed setting pinned user id with error", e));
  }

  const pinnedTrackId = useHMSStore(selectSessionStore('pinnedTrackId')) as string | undefined;

  const isTilePinned = pinnedTrackId
    && (
      (videoTrackID && videoTrackID === pinnedTrackId)
      || (audioTrackID && audioTrackID === pinnedTrackId)
    );

  const isPrimaryVideoTrack = useHMSStore(selectVideoTrackByPeerID(peerID))?.id === videoTrackID;
  const showPinAction = audioTrackID || (videoTrackID && isPrimaryVideoTrack);

  const track = useHMSStore(selectTrackByID(videoTrackID)) as HMSVideoTrack | null;
  const hideSimulcastLayers = !track?.layerDefinitions?.length || track?.degraded || !track?.enabled;

  if (!(toggleAudio || toggleVideo || setVolume || showPinAction) && hideSimulcastLayers) {
    return null;
  }

  return (
    <StyledMenuTile.Root>

      <StyledMenuTile.Trigger data-testid='participant_menu_btn' className='bg-white opacity-50 round-full'>
        <HorizontalMenuIcon />
      </StyledMenuTile.Trigger>

      <StyledMenuTile.Content side='top' align='end'>
        {
          isLocal && showPinAction
          ? (
              <StyledMenuTile.ItemButton
                onClick={() =>
                  isTilePinned
                    ? setPinnedTrackId()
                    : setPinnedTrackId(videoTrackID || audioTrackID, peerID)
                }
              >
                <PinIcon />
                <span>{`${isTilePinned ? 'Unpin' : 'Pin'}`} Tile</span>
              </StyledMenuTile.ItemButton>
            )
          : (
              <>

                {
                  toggleVideo
                    ? (
                        <StyledMenuTile.ItemButton
                          onClick={toggleVideo}
                          data-testid={
                            isVideoEnabled
                              ? 'mute_video_participant_btn'
                              : 'unmute_video_participant_btn'
                          }
                        >
                          { isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon /> }
                          <span>{`${isVideoEnabled ? 'Mute' : 'Request Unmute'}`}</span>
                        </StyledMenuTile.ItemButton>
                      )
                    : null
                }

                {
                  toggleAudio
                    ? (
                        <StyledMenuTile.ItemButton
                          onClick={toggleAudio}
                          data-testid={
                            isVideoEnabled
                              ? 'mute_audio_participant_btn'
                              : 'unmute_audio_participant_btn'
                          }
                        >
                          { isAudioEnabled ? <MicOnIcon /> : <MicOffIcon /> }
                          <span>{`${isAudioEnabled ? 'Mute' : 'Request Unmute'}`}</span>
                        </StyledMenuTile.ItemButton>
                      )
                    : null
                }

                {
                  audioTrackID
                    ? (
                        <StyledMenuTile.VolumeItem data-testid='participant_volume_slider'>
                          <Flex align='center' gap={1}>
                            <SpeakerIcon />
                            <Box as='span' css={{ ml: '$4' }}>
                              Volume ({volume})
                            </Box>
                          </Flex>
                          <Slider
                            css={{ my: '0.5rem' }}
                            step={5}
                            value={[volume ?? 0]}
                            onValueChange={e => setVolume!(e[0])}
                          />
                        </StyledMenuTile.VolumeItem>
                      )
                    : null
                }

                {
                  showPinAction && (
                    <StyledMenuTile.ItemButton
                      onClick={() =>
                        isTilePinned
                          ? setPinnedTrackId()
                          : setPinnedTrackId(videoTrackID || audioTrackID, peerID)
                      }
                    >
                      <PinIcon />
                      <span>{`${isTilePinned ? 'Unpin' : 'Pin'}`} Tile</span>
                    </StyledMenuTile.ItemButton>
                  )
                }

                <SimulcastLayers trackId={videoTrackID} />

              </>
            )
        }
      </StyledMenuTile.Content>

    </StyledMenuTile.Root>
  );
};

export { TileMenu };

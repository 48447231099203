import { useMemo } from 'react';
import { useMedia } from 'react-use';
import {
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectPeers,
  selectPeerScreenSharing,
  useHMSStore,
} from '@100mslive/react-sdk';
import { config as cssConfig, Flex } from '@100mslive/react-ui';

import { ScreenShareComponent } from './ScreenShareComponent';
import { SidePane } from './SidePane';

const ScreenShareView = () => {

  // for smaller screen we will show sidebar in bottom
  const mediaQueryLg = cssConfig.media.xl;
  const showSidebarInBottom = useMedia(mediaQueryLg);
  const peers = useHMSStore(selectPeers);
  const localPeerID = useHMSStore(selectLocalPeerID);
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const peerPresenting = useHMSStore(selectPeerScreenSharing);

  const isPresenterFromMyRole = peerPresenting?.roleName?.toLowerCase() === localPeerRole?.toLowerCase();
  const amIPresenting = localPeerID === peerPresenting?.id;
  const showPresenterInSmallTile = showSidebarInBottom || amIPresenting || isPresenterFromMyRole;

  const smallTilePeers = useMemo(() => {

    const smallTilePeers = peers.filter(peer => peer.id !== peerPresenting?.id);
    if (showPresenterInSmallTile && peerPresenting) {
      smallTilePeers.unshift(peerPresenting); // put presenter on first page
    }
    return smallTilePeers;

  }, [peers, peerPresenting, showPresenterInSmallTile]);

  return (
    <Flex
      css={{
        size: '100%',
      }}
      direction={showSidebarInBottom ? 'column' : 'row'}
    >

      <ScreenShareComponent
        amIPresenting={amIPresenting}
        peerPresenting={peerPresenting}
      />

      <Flex
        direction={{ '@initial': 'column', '@lg': 'row' }}
        css={{
          overflow: 'hidden',
          p: '$4 $8',
          flex: '0 0 20%',
          '@xl': {
            flex: '1 1 0',
          },
        }}
      >
        <SidePane
          showSidebarInBottom={showSidebarInBottom}
          peerScreenSharing={peerPresenting!}
          isPresenterInSmallTiles={showPresenterInSmallTile}
          smallTilePeers={smallTilePeers}
        />
      </Flex>

    </Flex>
  );
};

export { ScreenShareView };

import { Box, Flex } from '@100mslive/react-ui';

import { SidePane } from '../layouts/SidePane';

import { ConferencingHeader } from './header/ConferencingHeader';
import { PreviewContainer } from './preview/PreviewContainer';
import { PreviewProps } from './preview/types';

const PreviewScreen = ({
  token,
  onJoin,
  skipPreview,
  initialName,
  asRole,
}: PreviewProps) => {

  return (
    <Flex direction='column' css={{ size: '100%' }}>
      <Box
        css={{ h: '$18', '@md': { h: '$17', flexShrink: 0 } }}
        data-testid='header'
      >
        <ConferencingHeader isPreview={true} />
      </Box>
      <Flex
        css={{ flex: '1 1 0', position: 'relative', overflowY: 'auto' }}
        justify='center'
        align='center'
      >

        <PreviewContainer
          initialName={initialName}
          skipPreview={skipPreview}
          onJoin={onJoin}
          token={token}
          asRole={asRole}
        />

        <SidePane
          css={{
            position: 'unset',
            mr: '$10',
            '@lg': { position: 'fixed', mr: '$0' },
          }}
        />

      </Flex>
    </Flex>
  );
};

export { PreviewScreen };
